import React from 'react'; 
import Header from '../Dashboard/Header';
import SideBar from '../../components/SideBar';

function Paycart() 
{ 
return(
 <>
   <Header />     
   <SideBar />

      <div class="page-wrapper">
         <div class="content">    
            <div class="row">
               <div class="col-lg-5 col-sm-6 col-12">
                  <div class="cb-business-header">
                     <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg" alt='Logo'/>
                     <h6>Plant Science</h6>
                  </div>
               </div> 
               <div class="col-lg-7 col-sm-6 col-12">
                  <div class="dash-widget">
                     <div class="dash-widget-content">
                        <div class="dash-widgetimg">
                        <span class="material-icons-outlined">discount</span>
                        </div>
                        <div class="dash-widgetcontent">
                           <h6>Total Discount</h6>
                           <h5>618</h5>
                        </div>
                     </div>
                     <div class="dash-widget-content">
                        <div class="dash-widgetimg">
                           <span class="material-icons-outlined">local_offer</span>
                        </div>
                        <div class="dash-widgetcontent">
                           <h6>Active Discount</h6>
                           <h5>572</h5>
                        </div>
                     </div>
                     <div class="dash-widget-content">
                        <div class="dash-widgetimg">
                           <span class="material-icons-outlined">cancel_presentation</span>
                        </div>
                        <div class="dash-widgetcontent">
                           <h6>Expired Discount</h6>
                           <h5>46</h5>
                        </div>
                     </div>
                     
                  </div>
               </div>
            </div>
            <div class="row">               
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="card-p-10">
                     <div class="row product-header">                   
                        <div class="col-md-12">     
                           <div class="card-header content-header">
                              <div class="col-md-6 col-8 hd-coupons">                    
                                 <h4>Paycart Orders</h4>
                                 <div class="legend-1">
                                    <a href="#" class="bars-notification-1"> <div class="bars-1 bars-all"></div> <h6>ALL</h6></a>
                                    <a href="#" class="bars-notification-1"> <div class="bars-1 bars-active"></div> <h6>Paid</h6></a>
                                    <a href="#" class="bars-notification-1"> <div class="bars-1 bars-inactive"></div> <h6>Not Paid</h6></a>
                                 </div> 
                              </div>
                              <div class="col-md-6 col-4 hd-coupons-1">
                                 <a href="#" class="btn btn-add-business">
                                 <span class="material-icons-outlined">add</span> <div class="text-mb">Create Paycart</div></a>
                              </div>
                           </div>                             
                        </div>
                     </div>                     
                  </div>  
               </div>
            </div>
            <div class="row">               
               <div class="col-lg-12 col-sm-12 col-12">     
                  <div class="card">        
                     <div class="paycart-box">
                        <div class="cb-paycart-box-list cb-paycart-paid-bdr">
                           <div class="col-md-3 cb-col-order-3">
                              <div class="cb-paycart-user-detail">
                                 <div class="paycart-profile">
                                    <img src="https://storage.googleapis.com/bo3151920215/account/4d60cacd94144abb91782ce7fbf37ace/profilephoto.jpeg" class="cbbd_logo"/>
                                 </div>
                                 <div class="paycart-profile-dt">
                                    <h5>Vishnupriya</h5>
                                    <p>+919845570864</p>
                                    <p>anseetha@gmail.com</p>
                                 </div>
                              </div>
                           </div>
                           <div class="col-md-3 cb-col-order-3 cb-col-order-3-1">
                              <div class="cb-paycart-user-detail cb-paycart-user-detail-1">
                                 <h5>Order Id: 165271664423</h5>
                                 <p>B1 4063, ASSETZ east point, gear school road, Bengaluru, Karnataka, 560103</p>
                              </div>
                           </div>
                           <div class="col-md-2 cb-col-order-2 cb-col-order-1-3">
                              <div class="cb-manage-order-info cb-paycart-info">
                                 <h5><span class="material-icons">credit_card</span>Rs 14682</h5>
                                 <h6><span class="material-icons">shopping_cart</span>14 </h6>
                              </div>
                           </div>  
                           <div class="col-md-2 cb-col-order-1 cb-col-order-1-3 dp-none">
                              <div class="cb-manage-order-info cb-paycart-info">
                                 <h5>Cart Date</h5>
                                 <h6>Apr 21, 2023</h6>
                              </div>
                           </div>
                           <div class="col-md-2 cb-col-order-1 cb-col-order-1-3 dp-none">
                              <div class="cb-manage-order-info cb-paycart-info">
                                 <h5>Order Date</h5>
                                 <h6>Apr 25, 2023</h6>
                              </div>
                           </div>
                           <div class="col-lg-4 cb-col-order-4 cb-col-order-3-2">
                              <div class="cb-manage-order-btn cb-paycart-btn">
                                 <span class="cb-paycart-status bars-paid-txt"><div class="cb-paycart-status-box bars-paid"></div>Paid</span>
                                 <a href="#" class="btn btn-more">View</a>
                                 <a href="#" class="btn btn-track">Track</a>
                              </div>
                           </div>
                        </div>
                           <div class="cb-paycart-bottom-box">
                              <div class="cb-paycart-bottom-box-link">
                                 <div class="cb-paycart-bottom-box-link-list">
                                    <span class="material-icons-outlined">currency_rupee</span>
                                    <div class="cb-paycart-bottom-box-link-title">
                                       <h5>Payment Link:</h5>
                                       <a>https://rzp.io/i/p3c4phsV1</a>  
                                    </div> 
                                    
                                    <a href='#' class="copy-btn"><span class="material-icons-outlined">content_copy</span></a>
                                 </div>    
                                 <div class="cb-paycart-bottom-box-link-list">
                                    <span class="material-icons-outlined">shopping_cart</span>
                                    <div class="cb-paycart-bottom-box-link-title">
                                       <h5>Order Details:</h5>
                                       <a href='#'>https://costbo.com/paycart/1e6568abf2df4ceab2ea637f521be3df</a>   
                                    </div> 
                                    <a href='#' class="copy-btn"><span class="material-icons-outlined">content_copy</span></a>
                                 </div>    
                              </div>
                           </div>
                     </div>
                     <div class="paycart-box">
                        <div class="cb-paycart-box-list cb-paycart-not-paid-bdr">
                           <div class="col-md-3 cb-col-order-3">
                              <div class="cb-paycart-user-detail">
                                 <div class="paycart-profile">
                                    <img src="https://storage.googleapis.com/bo3151920215/account/4bbce13937c348e7ad8c9083f53473b5/profilephoto.jpeg" class="cbbd_logo"/>
                                 </div>
                                 <div class="paycart-profile-dt">
                                    <h5>Eswar Angusamy</h5>
                                    <p>+919845570864</p>
                                    <p>anseetha@gmail.com</p>
                                 </div>
                              </div>
                           </div>
                           <div class="col-md-3 cb-col-order-3 cb-col-order-3-1">
                              <div class="cb-paycart-user-detail cb-paycart-user-detail-1">
                                 <h5>Order Id: 165271664423</h5>
                                 <p>B1 4063, ASSETZ east point, gear school road, Bengaluru, Karnataka, 560103</p>
                              </div>
                           </div>
                           <div class="col-md-2 cb-col-order-2 cb-col-order-1-3">
                              <div class="cb-manage-order-info cb-paycart-info">
                                 <h5><span class="material-icons">credit_card</span>Rs 14682</h5>
                                 <h6><span class="material-icons">shopping_cart</span>14 </h6>
                              </div>
                           </div>  
                           <div class="col-md-2 cb-col-order-1 cb-col-order-1-3 dp-none">
                              <div class="cb-manage-order-info cb-paycart-info">
                                 <h5>Cart Date</h5>
                                 <h6>Apr 21, 2023</h6>
                              </div>
                           </div>
                           <div class="col-md-2 cb-col-order-1 cb-col-order-1-3 dp-none">
                              <div class="cb-manage-order-info cb-paycart-info">
                                 <h5>Order Date</h5>
                                 <h6>Apr 25, 2023</h6>
                              </div>
                           </div>
                           <div class="col-lg-4 cb-col-order-4 cb-col-order-3-2">
                              <div class="cb-manage-order-btn cb-paycart-btn">
                                 <span class="cb-paycart-status bars-not-paid-txt"><div class="cb-paycart-status-box bars-not-paid"></div>Not Paid</span>
                                 <a href="#" class="btn btn-more">View</a>
                                 <a href="#" class="btn btn-track">Track</a>
                              </div>
                           </div>
                        </div>
                           <div class="cb-paycart-bottom-box">
                              <div class="cb-paycart-bottom-box-link">
                                 <div class="cb-paycart-bottom-box-link-list">
                                    <span class="material-icons-outlined">currency_rupee</span>
                                    <div class="cb-paycart-bottom-box-link-title">
                                       <h5>Payment Link:</h5>
                                       <a>No Payment Link Generated</a>  
                                    </div> 
                                 </div>    
                                 <div class="cb-paycart-bottom-box-link-list">
                                    <span class="material-icons-outlined">shopping_cart</span>
                                    <div class="cb-paycart-bottom-box-link-title">
                                       <h5>Order Details:</h5>
                                       <a href='#'>https://costbo.com/paycart/1e6568abf2df4ceab2ea637f521be3df</a>   
                                    </div> 
                                    <a href='#' class="copy-btn"><span class="material-icons-outlined">content_copy</span></a>
                                 </div>    
                              </div>
                           </div>
                        </div>
                        <div class="paycart-box">
                     <div class="cb-paycart-box-list cb-paycart-paid-bdr">
                        <div class="col-md-3 cb-col-order-3">
                           <div class="cb-paycart-user-detail">
                              <div class="paycart-profile">
                                 <img src="https://storage.googleapis.com/bo3151920215/account/d2a9524d80794f4ba8441581c548fdd8/profilephoto.jpeg" class="cbbd_logo"/>
                              </div>
                              <div class="paycart-profile-dt">
                                 <h5>Priya Ramachandran</h5>
                                 <p>+919845570864</p>
                                 <p>anseetha@gmail.com</p>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-3 cb-col-order-3 cb-col-order-3-1">
                           <div class="cb-paycart-user-detail cb-paycart-user-detail-1">
                              <h5>Order Id: 165271664423</h5>
                              <p>B1 4063, ASSETZ east point, gear school road, Bengaluru, Karnataka, 560103</p>
                           </div>
                        </div>
                        <div class="col-md-2 cb-col-order-2 cb-col-order-1-3">
                           <div class="cb-manage-order-info cb-paycart-info">
                              <h5><span class="material-icons">credit_card</span>Rs 14682</h5>
                              <h6><span class="material-icons">shopping_cart</span>14 </h6>
                           </div>
                        </div>  
                        <div class="col-md-2 cb-col-order-1 cb-col-order-1-3 dp-none">
                           <div class="cb-manage-order-info cb-paycart-info">
                              <h5>Cart Date</h5>
                              <h6>Apr 21, 2023</h6>
                           </div>
                        </div>
                        <div class="col-md-2 cb-col-order-1 cb-col-order-1-3 dp-none">
                           <div class="cb-manage-order-info cb-paycart-info">
                              <h5>Order Date</h5>
                              <h6>Apr 25, 2023</h6>
                           </div>
                        </div>
                        <div class="col-lg-4 cb-col-order-4 cb-col-order-3-2">
                           <div class="cb-manage-order-btn cb-paycart-btn">
                              <span class="cb-paycart-status bars-paid-txt"><div class="cb-paycart-status-box bars-paid"></div>Paid</span>
                              <a href="#" class="btn btn-more">View</a>
                              <a href="#" class="btn btn-track">Track</a>
                           </div>
                        </div>
                     </div>
                        <div class="cb-paycart-bottom-box">
                           <div class="cb-paycart-bottom-box-link">
                              <div class="cb-paycart-bottom-box-link-list">
                                 <span class="material-icons-outlined">currency_rupee</span>
                                 <div class="cb-paycart-bottom-box-link-title">
                                    <h5>Payment Link:</h5>
                                    <a>No Payment Link Generated</a>  
                                 </div> 
                              </div>    
                              <div class="cb-paycart-bottom-box-link-list">
                                 <span class="material-icons-outlined">shopping_cart</span>
                                 <div class="cb-paycart-bottom-box-link-title">
                                    <h5>Order Details:</h5>
                                    <a href='#'>https://costbo.com/paycart/1e6568abf2df4ceab2ea637f521be3df</a>   
                                 </div> 
                                 <a href='#' class="copy-btn"><span class="material-icons-outlined">content_copy</span></a>
                              </div>    
                           </div>
                        </div>
                  </div>
                  <div class="paycart-box">
                     <div class="cb-paycart-box-list cb-paycart-not-paid-bdr">
                        <div class="col-md-3 cb-col-order-3">
                           <div class="cb-paycart-user-detail">
                              <div class="paycart-profile">
                                 <img src="https://storage.googleapis.com/bo3151920215/account/e1ce7d3e29e64fea8bfb05c5d5ce822f/profilephoto.jpeg" class="cbbd_logo"/>
                              </div>
                              <div class="paycart-profile-dt">
                                 <h5>Anantha Narayanan</h5>
                                 <p>+919845570864</p>
                                 <p>anantha@gmail.com</p>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-3 cb-col-order-3 cb-col-order-3-1">
                           <div class="cb-paycart-user-detail cb-paycart-user-detail-1">
                              <h5>Order Id: 165271664423</h5>
                              <p>B1 4063, ASSETZ east point, gear school road, Bengaluru, Karnataka, 560103</p>
                           </div>
                        </div>
                        <div class="col-md-2 cb-col-order-2 cb-col-order-1-3">
                           <div class="cb-manage-order-info cb-paycart-info">
                              <h5><span class="material-icons">credit_card</span>Rs 14682</h5>
                              <h6><span class="material-icons">shopping_cart</span>14 </h6>
                           </div>
                        </div>  
                        <div class="col-md-2 cb-col-order-1 cb-col-order-1-3 dp-none">
                           <div class="cb-manage-order-info cb-paycart-info">
                              <h5>Cart Date</h5>
                              <h6>Apr 21, 2023</h6>
                           </div>
                        </div>
                        <div class="col-md-2 cb-col-order-1 cb-col-order-1-3 dp-none">
                           <div class="cb-manage-order-info cb-paycart-info">
                              <h5>Order Date</h5>
                              <h6>Apr 25, 2023</h6>
                           </div>
                        </div>
                        <div class="col-lg-4 cb-col-order-4 cb-col-order-3-2">
                           <div class="cb-manage-order-btn cb-paycart-btn">
                              <span class="cb-paycart-status bars-not-paid-txt"><div class="cb-paycart-status-box bars-not-paid"></div>Not Paid</span>
                              <a href="#" class="btn btn-more">View</a>
                              <a href="#" class="btn btn-track">Track</a>
                           </div>
                        </div>
                     </div>
                        <div class="cb-paycart-bottom-box">
                           <div class="cb-paycart-bottom-box-link">
                              <div class="cb-paycart-bottom-box-link-list">
                                 <span class="material-icons-outlined">currency_rupee</span>
                                 <div class="cb-paycart-bottom-box-link-title">
                                    <h5>Payment Link:</h5>
                                    <a>No Payment Link Generated</a>  
                                 </div> 
                              </div>    
                              <div class="cb-paycart-bottom-box-link-list">
                                 <span class="material-icons-outlined">shopping_cart</span>
                                 <div class="cb-paycart-bottom-box-link-title">
                                    <h5>Order Details:</h5>
                                    <a href='#'>https://costbo.com/paycart/1e6568abf2df4ceab2ea637f521be3df</a>   
                                 </div> 
                                 <a href='#' class="copy-btn"><span class="material-icons-outlined">content_copy</span></a>
                              </div>    
                           </div>
                        </div>
                  </div>
                  </div>
               </div>            
            </div>

            {/* <div class="col-md-12 text-center">
                  <ul class="pagination">
                     <li><a href="#"><span class="material-icons-outlined">arrow_back</span></a></li>
                     <li class="active"><a href="#">1</a></li>
                     <li><a href="#">2</a></li>
                     <li><a href="#">3</a></li>
                     <li><a href="#">4</a></li>
                     <li><a href="#">5</a></li>
                     <li><a href="#"><span class="material-icons-outlined">arrow_forward</span></a></li>
                  </ul>
               </div> */}
         </div>
      </div>

 </>
  ); 
} 
export default Paycart;