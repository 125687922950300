import React from 'react';
function StoreDetail() {
return( 
<>
<div class="account-content">
   <div class="login-wrapper">
      <div class="login-left-sidebar">
         <div class="login-sidebar-logo">
            <img src="./assets/images/logo.png" alt="img"/>        
            <h3>Take your online business to next level!</h3>
            <h4>Increased B2C + B2B Sales, Faster to market, Integrated Fintech</h4>
         </div>
         <div class="login-sidebar-tab">
            <div class="business-onboarding-stage">   
               <div class="business-onboarding-stage-list">
                  <img src="./assets/images/grow-your-business.png" alt="img"/> 
                  <h6>Grow your business by 10x</h6>
                  <p>Direct to Customers Online sales! Own your Brand, Own Your Customers</p>
               </div>  
               <div class="business-onboarding-stage-list">
                  <img src="./assets/images/reach-millions-of-customers.png" alt="img"/> 
                  <h6>No IT Dept ? No Problem!</h6>
                  <p>Plug & Play with Future Ready E-comm Ecosystem</p>
               </div>    
            </div>   
         </div>
      </div>
      <div class="business-onboarding-content">
         <div class="business-onboarding-content-inner">
            <div class="business-onboarding-content-heading">
               <h3>Register your Business</h3>
               <p>You are just a few steps away</p>
            </div>            
            <ul class="business-onboarding-tabs">
               <li>
                  <a href='/StoreDetail'  class="active"><span class="material-icons-outlined">storefront</span>Business Info</a>
               </li>
               <li>
                  <a href='/GSTDetail'><span class="material-icons-outlined">receipt_long</span>GST Info</a>
               </li>
               <li>
                  <a href='/AddressDetail' ><span class="material-icons-outlined">map</span>Address Info</a>
               </li>
            </ul>   
            <div class="business-onboarding-tabs-content">
               <h5>Business Detail</h5>
               <div class="business-onboarding-tabs-form">
                  <div class="form-group">                     
                     <input type="text" name=""  required=""/>
                     <label for="input" class="input-label">Brand Name/Business Name</label>
                     <div class="business-sample">
                        <h6 class="name-corr">Ex: Bhuira Jams <span class="material-icons">check_circle</span>,</h6>
                        <h6>Bhuira Jame Private Ltd <span class="material-icons">cancel</span></h6>
                     </div>
                  </div>
                  <div class="form-group">
                     <select class="select" >
                        <option>Manufacturers/Industries</option>
                        <option>Brand</option>
                        <option>Retailers</option>
                        <option>Wholesalers/Distributors</option>
                        <option>Others</option>
                     </select>
                     <label for="input" class="input-label">Business Type</label>
                  </div>
                  <div class="business-onboarding-buttons ">
                        <div class="row">
                           <div class="col-lg-4 col-sm-4 col-4">
                              {/* <a href="/AddressDetail" class="btn btn-back-ft"><span class="material-icons">skip_previous</span></a> */}
                           </div>
                           <div class="col-lg-8 col-sm-8 col-8 cb-txet-right">
                              {/* <a href="#" class="btn btn-submit">Save & Exit</a> */}
                              <a href="/GSTDetail" class="btn btn-next cb-ml-4">Next</a>
                           </div>                     
                        </div>                     
                     </div>
               </div>
               
            </div>

         </div>
      </div>
      
   </div>
</div>
</>
);
}
export default StoreDetail;