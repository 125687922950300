import React from 'react'; 
import Header from './Header';
import SideBar from '../../components/SideBar'; 

function ProductPreview() 
{ 
return(
 <>
   <Header />     
   <SideBar />

   <div class="page-wrapper">
      <div class="content">            
      <div class="row">
            <div class="col-lg-5 col-sm-6 col-12">
               <div class="cb-business-header">
                  <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg" alt='Logo'/>
                  <h6>Plant Science</h6>
               </div>
            </div> 
            <div class="col-lg-7 col-sm-6 col-12">
               <div class="dash-widget">
                  <div class="dash-widget-content">
                     <div class="dash-widgetimg">
                        <img src="./assets/images/dash1.svg" alt="img" />
                     </div>
                     <div class="dash-widgetcontent">
                        <h5>145</h5>
                        <h6>Total Order</h6>
                     </div>
                  </div>
                  <div class="dash-widget-content">
                     <div class="dash-widgetimg">
                        <img src="./assets/images/dash2.svg" alt="img" />
                     </div>
                     <div class="dash-widgetcontent">
                        <h5>Rs 254385</h5>
                        <h6>Total Sales</h6>
                     </div>
                  </div>
                  <div class="dash-widget-content wdth-100">
                     <div class="dash-widgetimg dash-widgetimg1">
                        <img src="./assets/images/store.svg" alt="img" />
                     </div>
                     <div class="dash-widgetcontent">
                        <h5>Ecommerce enabled</h5>
                        <div class="toggle-btn toggle-btn1">
                           <input type="checkbox" id="toggle-btn-5"/>
                           <label for="toggle-btn-5"></label>
                        </div>
                     </div>
                  </div>
                  
               </div>
            </div>
         </div>
            <div class="row">
               <div class="col-lg-12">
                  <div class="card">
                     <div class="card-body store-data-view">
                        <div class="row">
                           <div class="col-lg-12 store-tab-title store-tab-title-pre">
                              <h5>Product Detail</h5>
                           </div> 
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Product Name</label>
                                 <input type="text" value='Olive Green Kora Benarasi tissue saree'/>
                              </div>
                           </div> 
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Brand</label>
                                 <input type="text" value='Women Clothing'/>
                              </div>
                           </div> 
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Country of Origin</label>
                                 <input type="text" value='India'/>
                              </div>
                           </div> 
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>SKU ID</label>
                                 <input type="text" value='DSS2542HGFB56'/>
                              </div>
                           </div>
                           <div class="col-lg-12 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Product Description</label>
                                 <input type="text" value='An exquisite Olive Green Kora Benarasi tissue saree gleams with chirping 🦜 parrots. The vivid dark pink zari border with criss cross brocade accentuates it’s beauty.'/>
                              </div>
                           </div> 

                           <div class="col-lg-12 col-sm-12 col-12 store-tab-title store-tab-title-pre">
                              <h6>Upload Product Image</h6>
                           </div>

                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="product_bn_img">
                                 <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/bpic2-623184.jpeg" alt="Product Image"/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="product_bn_img">
                                 <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/bpic3-922188.jpeg" alt="Product Image"/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="product_bn_img">
                                 <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/bpic1-843885.jpeg" alt="Product Image"/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="product_bn_img">
                                 <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/bpic4-233446.jpeg" alt="Product Image"/>
                              </div>
                           </div>
                           
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-12">
                  <div class="card">
                     <div class="card-body store-data-view">
                        <div class="row">
                           <div class="col-lg-12 store-tab-title">
                              <h5>Category Detail</h5>
                           </div>                           
                           <div class="col-lg-4 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Business Category</label>
                                 <input type="text" value='Clothing & Accessories'/>
                              </div>
                           </div>
                           <div class="col-lg-4 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Product Category</label>
                                 <input type="text" value='Women Clothing'/>
                              </div>
                           </div>
                           <div class="col-lg-4 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Product Sub-Category</label>
                                 <input type="text" value='Printed Border Sarees'/>
                              </div>
                           </div>
                           
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-lg-12">
                  <div class="card">
                     <div class="card-body store-data-view">
                        <div class="row">
                           <div class="col-lg-12 store-tab-title">
                              <h5>Price Details</h5>
                           </div>                           
                           <div class="col-lg-4 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Currency</label>
                                 <input type="text" value='IND'/>
                              </div>
                           </div>
                           <div class="col-lg-4 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Original Price</label>
                                 <input type="text" value='Rs 1299'/>
                              </div>
                           </div>
                           <div class="col-lg-4 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Offer Price</label>
                                 <input type="text" value='Rs 999'/>
                              </div>
                           </div>
                           
                           <div class="col-lg-12 col-sm-12 col-12 store-tab-title store-tab-title-pre">
                              <h6>Tax and GST Information</h6>
                           </div>
                           <div class="col-lg-6 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Taxes Included</label>
                                 <input type="text" value='Yes'/>
                              </div>
                           </div>
                           <div class="col-lg-6 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>GST/HSN code</label>
                                 <input type="text" value='33AABCU9603R1ZU'/>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-12">
                  <div class="card">
                     <div class="card-body store-data-view">
                        <div class="row">
                           <div class="col-lg-12 store-tab-title">
                              <h5>Product Availablity</h5>
                           </div>                           
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Product Available for selling onlinee</label>
                                 <input type="text" value='Yes'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Available Quantity</label>
                                 <input type="text" value='257'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Alert Quantity</label>
                                 <input type="text" value='10'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Min Quantity Per Order</label>
                                 <input type="text" value='1'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Max Quantity Per Order</label>
                                 <input type="text" value='200'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Start Date</label>
                                 <input type="text" value='01-05-2022'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>End Date</label>
                                 <input type="text" value='26-08-2023'/>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-12">
                  <div class="card">
                     <div class="card-body store-data-view">
                        <div class="row">
                           <div class="col-lg-12 store-tab-title">
                              <h5>Product Measurement</h5>
                           </div>                           
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Product Weight (in gms)</label>
                                 <input type="text" value='277 g'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Product Length (in mm)</label>
                                 <input type="text" value='605.7 mm'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Product Width (in mm)</label>
                                 <input type="text" value='1037 mm'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Product Height (in mm)</label>
                                 <input type="text" value='855.7 mm'/>
                              </div>
                           </div>
                           <div class="col-lg-12 col-sm-12 col-12 store-tab-title store-tab-title-pre">
                              <h6>Delivery Method</h6>
                           </div>
                           <div class="col-lg-4 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Delivery Method</label>
                                 <input type="text" value='Delivery Fulfillment'/>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

              
               <div class="col-lg-12">
                  <div class="card">
                     <div class="card-body store-data-view">
                        <div class="row"> 
                           <div class="col-lg-12 col-sm-12 col-12 store-tab-title store-tab-title-pre">
                              <h6>Product Specification</h6>
                           </div> 
                           <div class="col-lg-6 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Label</label>
                                 <input type="text" value='Cotton Silk Saree'/>
                              </div>
                           </div>
                           <div class="col-lg-6 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Value</label>
                                 <input type="text" value='Rs 700'/>
                              </div>
                           </div>
                           <div class="col-lg-12 col-sm-12 col-12 store-tab-title store-tab-title-pre">
                              <h6>Reference Link</h6>
                           </div> 
                           <div class="col-lg-6 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Link Name</label>
                                 <input type="text" value='Affordable Designer Saree'/>
                              </div>
                           </div>
                           <div class="col-lg-6 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Link</label>
                                 <input type="text" value='https://youtu.be/5GVvqnoZliw'/>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-12">
                  <div class="card">
                     <div class="card-body store-data-view">
                        <div class="row">
                           <div class="col-lg-12 store-tab-title">
                              <h5>Subscription Information</h5>
                           </div>                           
                           <div class="col-lg-4 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Product can be subscribed on regular intervals</label>
                                 <input type="text" value='Alternate Days'/>
                              </div>
                           </div>
                           <div class="col-lg-4 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Original Value</label>
                                 <input type="text" value='Rs 1299'/>
                              </div>
                           </div>
                           <div class="col-lg-4 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Offer Value</label>
                                 <input type="text" value='Rs 999'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Cancel Time(24 hr Format)</label>
                                 <input type="text" value='5 hr'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Cancel Days (Weekly Subscription)</label>
                                 <input type="text" value='Sunday'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Service Fees</label>
                                 <input type="text" value='Rs 75'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Packing Fee</label>
                                 <input type="text" value='Rs 25'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Start Date</label>
                                 <input type="text" value='26-08-2023'/>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-lg-12">
                  <div class="row">
                        <div class="col-lg-4 col-sm-4 col-5">
                           <a href="/SubscriptionInformation" class="btn btn-back"><span class="material-icons-outlined">keyboard_double_arrow_left</span> Previous</a>
                        </div>
                        <div class="col-lg-8 col-sm-12 col-7 cb-txet-right">
                           <a href="/ProductPendingApproval" class="btn btn-submit">submit</a>
                        </div>
                  </div>
               </div>
                         
            </div>
      </div>
   </div>

 </>
  ); 
} 
export default ProductPreview;