import { Box } from '@mui/system';
import React from 'react';
function Webstore () {
   return( 

<Box>

            <Box class="cb-webstore cb_pd_20">
                <Box class="container">
                    <Box class="row align-items-center">
                        <Box class="col-lg-6">
                            <div class="about-content lt-view wow fadeInLeft animated" data-wow-duration="1s" data-wow-delay="0.2s">
                               <img src="https://storage.googleapis.com/bodefaults/businessweb/webstore.png" alt="A future ready FREE Webstore powered by Social Recommendation Engine" />
                            </div>
                        </Box> 
                        <div class="col-lg-6 wow fadeInRight animated" data-wow-duration="1s" data-wow-delay="0.2s" >
                            <div class="sec-title mb-20 mt-20">      
                                <span>WebStore</span>                  
                                <h4>A future ready FREE Webstore powered by Social Recommendation Engine</h4>    
                            </div> 
                            <div class="about-content mb-view">
                               <img src="https://storage.googleapis.com/bodefaults/businessweb/webstore.png" alt="A future ready FREE Webstore powered by Social Recommendation Engine" />
                            </div>
                            <div class="webstore_content">                                       
                                <p><span></span>Always up to date technology</p>        
                                <p><span></span>Brand visibility and reach through customers' friends and community network</p>         
                                <p><span></span>Optimized for Business & Product SEO</p>     
                                <p><span></span>Responsive web design for Mobile and PC</p>    
                            </div>                           
                        </div>
                    </Box>
                </Box>              
            </Box>
      
            </Box>
);
}

export default Webstore;