import React, { Component, useEffect } from 'react';
import Header from '../Dashboard/Header';
import SideBar from '../../components/SideBar';
function AddDiscount () {
return( 
<>

<div class="cb-discount-modal-dialog">
   <a href="/PreferredDiscounts" class="cb-discount-close"><span class="material-icons-round">close</span></a>
   <div class="cb-discount-model-content">
      <div class="cb-discount-model-content-header">
         <h3>Preferred Discounts</h3>
         <p>Assign Discount to Specific customer</p>
      </div>
      <div class="cb-discount-model-content-body">
         <form>
            <div class="row">
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group form-group-option">
                    {/* <label for="input" class="input-label-1">Discount value will change to or rs based on this</label> */}
                     <input id="Amount" type="radio" name="user" value="Amount"/>
                     <label for="Amount" class="mr-2"><span></span>Amount </label>

                     <input id="No" type="radio" name="user" value="No"/>
                     <label for="No"><span></span>Percentage</label>
                  </div>
               </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label for="input" class="input-label">Customer Phone Number<span>*</span></label>
                    <input type="text" name="" value="+91 9025042838"/>
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label for="input" class="input-label">Discount Value<span>*</span></label>
                    <input type="text" name="" value="₹ 170"/>
                  </div>
              </div>
              <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label for="input" class="input-label">Minimum Purchase Amount<span>*</span></label>
                    <input type="text" name="" value="₹ 50  "/>
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-sm-6 col-12">
                  <div class="form-group">
                    <label for="input" class="input-label">Valid Start Date<span>*</span></label>
                    <input type="text" name="" value="Feb 01, 2022"/>
                  </div>
              </div>
              <div class="col-lg-6 col-sm-6 col-12">
                  <div class="form-group">
                    <label for="input" class="input-label">Valid End Date<span>*</span></label>
                    <input type="text" name="" value="Nov 21, 2024"/>
                  </div>
              </div>
            </div>
            <div class="cb-discount-model-btn"><a href="/PreferredDiscounts" class="login-btn">Submit</a></div>
         </form>
      </div>
   </div>
</div>
  <a href="#" class="cb-discount-overlay"></a>
  <Header />     
   <SideBar />

      <div class="page-wrapper">
         <div class="content">    
            <div class="row">
               <div class="col-lg-5 col-sm-6 col-12">
                  <div class="cb-business-header">
                     <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg" alt='Logo'/>
                     <h6>Plant Science</h6>
                  </div>
               </div> 
               <div class="col-lg-7 col-sm-6 col-12">
                  <div class="dash-widget">
                     <div class="dash-widget-content">
                        <div class="dash-widgetimg">
                        <span class="material-icons-outlined">discount</span>
                        </div>
                        <div class="dash-widgetcontent">
                           <h6>Total Discount</h6>
                           <h5>618</h5>
                        </div>
                     </div>
                     <div class="dash-widget-content">
                        <div class="dash-widgetimg">
                           <span class="material-icons-outlined">local_offer</span>
                        </div>
                        <div class="dash-widgetcontent">
                           <h6>Active Discount</h6>
                           <h5>572</h5>
                        </div>
                     </div>
                     <div class="dash-widget-content">
                        <div class="dash-widgetimg">
                           <span class="material-icons-outlined">cancel_presentation</span>
                        </div>
                        <div class="dash-widgetcontent">
                           <h6>Expired Discount</h6>
                           <h5>46</h5>
                        </div>
                     </div>
                     
                  </div>
               </div>
            </div>
            <div class="row">               
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="card-p-10">
                     <div class="row product-header">                   
                        <div class="col-md-12">     
                           <div class="card-header content-header">
                              <div class="col-md-6 col-8 hd-coupons">                    
                                 <h4>Coupons & Discount</h4>
                                 <div class="legend-1">
                                    <div class="bars-notification-1"> <div class="bars-1 bars-active"></div> <h6>Active</h6></div>
                                    <div class="bars-notification-1"> <div class="bars-1 bars-inactive"></div> <h6>Expired</h6></div>
                                 </div> 
                              </div>
                              <div class="col-md-6 col-4 hd-coupons-1">
                                 <div class="form-group form-group-inventory cb-mb-none">
                                    <input type="text" placeholder="Customer Phone Number" class="search-mt-20" id="inputFname"/>
                                    <i class="fas fa-search inventory-search-icon"></i>
                                 </div>
                                 <a href="/AddDiscount" class="btn btn-add-business">
                                 <span class="material-icons-outlined">add</span> <div class="text-mb">New Discount</div></a>
                              </div>
                           </div>                             
                        </div>
                     </div>                     
                  </div>  
               </div>
            </div>
            <div class="row">
               <div class="col-md-3 p-r-0">
                  <div class="cb-discount-cart cb-discount-cart-1">
                     <div  class="cb-discount-cart-log cb-discount-cart-log-1">                  
                        <div class="discount-profile">      
                           <img src="https://storage.googleapis.com/bo3151920215/account/4bbce13937c348e7ad8c9083f53473b5/profilephoto.jpeg"  class="cbbd_logo"/>
                           <sub class="badge-status badge-status-1 badge-status-active"></sub>
                        </div> 
                        <div class="cb-discount-cart-shopping cb-discount-cart-shopping-1">
                           <h5>Eswar Angusamy</h5>
                           <div class="valid-date-1">
                              <h6>Valid From :<span>Feb 01, 2022</span></h6>
                              <h6>Valid End : <span>Nov 21, 2024</span></h6>
                           </div>
                        </div>
                     </div>
                     <div class="cb-discount-cart-code-box-1">
                        <div class="cb-discount-cart-offers-1">
                           <h6>Discount Value:<span>₹ 170</span></h6>    
                           <h6>Percentage:<span>12%</span></h6>    
                           <h6>Min Purchase Amount:<span>₹ 3450</span></h6>    
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-3 p-r-0">
                  <div class="cb-discount-cart cb-discount-cart-1">
                     <div  class="cb-discount-cart-log cb-discount-cart-log-1 cb-discount-cart-log-expired-1">                  
                        <div class="discount-profile">      
                           <img src="https://storage.googleapis.com/bo3151920215/account/4d60cacd94144abb91782ce7fbf37ace/profilephoto.jpeg"  class="cbbd_logo"/>
                           <sub class="badge-status badge-status-1 badge-status-inactive"></sub>
                        </div> 
                        <div class="cb-discount-cart-shopping cb-discount-cart-shopping-1">
                           <h5>Vishnupriya</h5>
                           <div class="valid-date-1">
                              <h6>Valid From :<span>Jun 21, 2022</span></h6>
                              <h6>Valid End : <span>Sep 21, 2024</span></h6>
                           </div>
                        </div>
                     </div>
                     <div class="cb-discount-cart-code-box-1">
                        <div class="cb-discount-cart-offers-1">
                           <h6>Discount Value:<span>₹ 250</span></h6>    
                           <h6>Percentage:<span>10%</span></h6>    
                           <h6>Min Purchase Amount:<span>₹ 6500</span></h6>    
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-3 p-r-0">
                  <div class="cb-discount-cart cb-discount-cart-1">
                     <div  class="cb-discount-cart-log cb-discount-cart-log-1">                  
                        <div class="discount-profile">      
                           <img src="https://storage.googleapis.com/bo3151920215/account/e1ce7d3e29e64fea8bfb05c5d5ce822f/profilephoto.jpeg"  class="cbbd_logo"/>
                           <sub class="badge-status badge-status-1 badge-status-active"></sub>
                        </div> 
                        <div class="cb-discount-cart-shopping cb-discount-cart-shopping-1">
                           <h5>Anantha Narayanan</h5>
                           <div class="valid-date-1">
                              <h6>Valid From :<span>Jun 21, 2022</span></h6>
                              <h6>Valid End : <span>Sep 21, 2024</span></h6>
                           </div>
                        </div>
                     </div>
                     <div class="cb-discount-cart-code-box-1">
                        <div class="cb-discount-cart-offers-1">
                           <h6>Discount Value:<span>₹ 250</span></h6>    
                           <h6>Percentage:<span>10%</span></h6>    
                           <h6>Min Purchase Amount:<span>₹ 6500</span></h6>    
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-3 p-r-0">
                  <div class="cb-discount-cart cb-discount-cart-1">
                     <div  class="cb-discount-cart-log cb-discount-cart-log-1 cb-discount-cart-log-expired-1">                  
                        <div class="discount-profile">      
                           <img src="https://storage.googleapis.com/bo3151920215/account/4d60cacd94144abb91782ce7fbf37ace/profilephoto.jpeg"  class="cbbd_logo"/>
                           <sub class="badge-status badge-status-1 badge-status-inactive"></sub>
                        </div> 
                        <div class="cb-discount-cart-shopping cb-discount-cart-shopping-1">
                           <h5>Priya Ramachandran</h5>
                           <div class="valid-date-1">
                              <h6>Valid From :<span>Jun 21, 2022</span></h6>
                              <h6>Valid End : <span>Sep 21, 2024</span></h6>
                           </div>
                        </div>
                     </div>
                     <div class="cb-discount-cart-code-box-1">
                        <div class="cb-discount-cart-offers-1">
                           <h6>Discount Value:<span>₹ 250</span></h6>    
                           <h6>Percentage:<span>10%</span></h6>    
                           <h6>Min Purchase Amount:<span>₹ 6500</span></h6>    
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-3 p-r-0">
                  <div class="cb-discount-cart cb-discount-cart-1">
                     <div  class="cb-discount-cart-log cb-discount-cart-log-1 cb-discount-cart-log-expired-1">                  
                        <div class="discount-profile">      
                           <img src="https://storage.googleapis.com/bo3151920215/account/5a90629f079b4b38a68d428d938b5c1d/profilephoto.jpeg"  class="cbbd_logo"/>
                           <sub class="badge-status badge-status-1 badge-status-inactive"></sub>
                        </div> 
                        <div class="cb-discount-cart-shopping cb-discount-cart-shopping-1">
                           <h5>Kanagudurai</h5>
                           <div class="valid-date-1">
                              <h6>Valid From :<span>Jun 21, 2022</span></h6>
                              <h6>Valid End : <span>Sep 21, 2024</span></h6>
                           </div>
                        </div>
                     </div>
                     <div class="cb-discount-cart-code-box-1">
                        <div class="cb-discount-cart-offers-1">
                           <h6>Discount Value:<span>₹ 250</span></h6>    
                           <h6>Percentage:<span>10%</span></h6>    
                           <h6>Min Purchase Amount:<span>₹ 6500</span></h6>    
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-3 p-r-0">
                  <div class="cb-discount-cart cb-discount-cart-1">
                     <div  class="cb-discount-cart-log cb-discount-cart-log-1">                  
                        <div class="discount-profile">      
                           <img src="https://storage.googleapis.com/bo3151920215/account/d2a9524d80794f4ba8441581c548fdd8/profilephoto.jpeg"  class="cbbd_logo"/>
                           <sub class="badge-status badge-status-1 badge-status-active"></sub>
                        </div> 
                        <div class="cb-discount-cart-shopping cb-discount-cart-shopping-1">
                           <h5>Raymond</h5>
                           <div class="valid-date-1">
                              <h6>Valid From :<span>Jun 21, 2022</span></h6>
                              <h6>Valid End : <span>Sep 21, 2024</span></h6>
                           </div>
                        </div>
                     </div>
                     <div class="cb-discount-cart-code-box-1">
                        <div class="cb-discount-cart-offers-1">
                           <h6>Discount Value:<span>₹ 250</span></h6>    
                           <h6>Percentage:<span>10%</span></h6>    
                           <h6>Min Purchase Amount:<span>₹ 6500</span></h6>    
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-3 p-r-0">
                  <div class="cb-discount-cart cb-discount-cart-1">
                     <div  class="cb-discount-cart-log cb-discount-cart-log-1">                  
                        <div class="discount-profile">      
                           <img src="https://storage.googleapis.com/bo3151920215/account/5b81b8aa07ba461c8aab9d0800d918e6/profilephoto.jpeg"  class="cbbd_logo"/>
                           <sub class="badge-status badge-status-1 badge-status-active"></sub>
                        </div> 
                        <div class="cb-discount-cart-shopping cb-discount-cart-shopping-1">
                           <h5>Denny Gadri</h5>
                           <div class="valid-date-1">
                              <h6>Valid From :<span>Jun 21, 2022</span></h6>
                              <h6>Valid End : <span>Sep 21, 2024</span></h6>
                           </div>
                        </div>
                     </div>
                     <div class="cb-discount-cart-code-box-1">
                        <div class="cb-discount-cart-offers-1">
                           <h6>Discount Value:<span>₹ 250</span></h6>    
                           <h6>Percentage:<span>10%</span></h6>    
                           <h6>Min Purchase Amount:<span>₹ 6500</span></h6>    
                        </div>
                     </div>
                  </div>
               </div>               

            </div>
            <div class="col-md-12 text-center">
                  <ul class="pagination">
                     <li><a href="#"><span class="material-icons-outlined">arrow_back</span></a></li>
                     <li class="active"><a href="#">1</a></li>
                     <li><a href="#">2</a></li>
                     <li><a href="#">3</a></li>
                     <li><a href="#">4</a></li>
                     <li><a href="#">5</a></li>
                     <li><a href="#"><span class="material-icons-outlined">arrow_forward</span></a></li>
                  </ul>
               </div>
         </div>
      </div>
  
</>
);
}
export default AddDiscount;