import React from 'react'; 
import Header from '../Dashboard/Header';
import SideBar from '../../components/SideBar';

function CouponsDiscount() 
{ 
return(
 <>
   <Header />     
   <SideBar />

      <div class="page-wrapper">
         <div class="content">    
            <div class="row">
               <div class="col-lg-5 col-sm-6 col-12">
                  <div class="cb-business-header">
                     <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg" alt='Logo'/>
                     <h6>Plant Science</h6>
                  </div>
               </div> 
               <div class="col-lg-7 col-sm-6 col-12">
                  <div class="dash-widget">
                     <div class="dash-widget-content">
                        <div class="dash-widgetimg">
                        <span class="material-icons-outlined">discount</span>
                        </div>
                        <div class="dash-widgetcontent">
                           <h6>Total Discount</h6>
                           <h5>618</h5>
                        </div>
                     </div>
                     <div class="dash-widget-content">
                        <div class="dash-widgetimg">
                           <span class="material-icons-outlined">local_offer</span>
                        </div>
                        <div class="dash-widgetcontent">
                           <h6>Active Discount</h6>
                           <h5>572</h5>
                        </div>
                     </div>
                     <div class="dash-widget-content">
                        <div class="dash-widgetimg">
                           <span class="material-icons-outlined">cancel_presentation</span>
                        </div>
                        <div class="dash-widgetcontent">
                           <h6>Expired Discount</h6>
                           <h5>46</h5>
                        </div>
                     </div>
                     
                  </div>
               </div>
            </div>
            <div class="row">               
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="card-p-10">
                     <div class="row product-header">                   
                        <div class="col-md-12">     
                           <div class="card-header content-header">
                              <div class="col-md-6 col-8 hd-coupons">                  
                                 <h4>Coupons/Promo Code</h4>
                                 <div class="legend-1">
                                    <a href="#" class="bars-notification-1"> <div class="bars-1 bars-all"></div> <h6>ALL</h6></a>
                                    <a href="#" class="bars-notification-1"> <div class="bars-1 bars-active"></div> <h6>Active</h6></a>
                                    <a href="#" class="bars-notification-1"> <div class="bars-1 bars-inactive"></div> <h6>Expired</h6></a>
                                 </div> 
                              </div>
                              <div class="col-md-6 col-4 hd-coupons-1 hd-coupons-clr">
                                 <h6 class="hd-coupons-head">Total Orders Placed: <span>42</span></h6>
                                 {/* <div class="form-group form-group-inventory cb-mb-none">
                                    <input type="text" placeholder="Customer Phone Number" class="search-mt-20" id="inputFname"/>
                                    <i class="fas fa-search inventory-search-icon"></i>
                                 </div> */}
                                 <a href="/AddCoupon" class="btn btn-add-business">
                                 <span class="material-icons-outlined">add</span> <div class="text-mb">New Coupon</div></a>
                              </div>
                           </div>                             
                        </div>
                     </div>                     
                  </div>  
               </div>
            </div>
            <div class="row">
               <div class="col-lg-3 p-r-0">
						<div class="cb-coupons-promo-dicount-box cb-promo-active">
							<div class="cb-coupons-promo-dicount-head">
								<div class="cb-coupons-promo-dicount-offer">
									<h5> 25% off</h5>
									<p>Exp : Mar 12 2023</p>
								</div>
								<div class="cb-coupons-promo-dicount-code">
									<p>Coupon code:</p>
									<h5>AKVHEALTHY</h5>
								</div>
							</div>	
							<div class="cb-coupons-promo-bottom-category">
								<a href="#">Used by 12 customers</a>
							</div>
						</div>
					</div>
               <div class="col-lg-3 p-r-0">
						<div class="cb-coupons-promo-dicount-box cb-promo-expired">
							<div class="cb-coupons-promo-dicount-head">
								<div class="cb-coupons-promo-dicount-offer">
									<h5> 16% off</h5>
									<p>Exp : Mar 12 2023</p>
								</div>
								<div class="cb-coupons-promo-dicount-code">
									<p>Coupon code:</p>
									<h5>16HEALTHY</h5>
								</div>
							</div>	
							<div class="cb-coupons-promo-bottom-category">
								<a href="#">Used by 25 customers</a>
							</div>
						</div>
					</div>
               <div class="col-lg-3 p-r-0">
						<div class="cb-coupons-promo-dicount-box cb-promo-active">
							<div class="cb-coupons-promo-dicount-head">
								<div class="cb-coupons-promo-dicount-offer">
									<h5> 5% off</h5>
									<p>Exp : Apr 12 2023</p>
								</div>
								<div class="cb-coupons-promo-dicount-code">
									<p>Coupon code:</p>
									<h5>NEWHEALTHY</h5>
								</div>
							</div>	
							<div class="cb-coupons-promo-bottom-category">
								<a href="#">Used by 16 customers</a>
							</div>
						</div>
					</div>
               <div class="col-lg-3">
						<div class="cb-coupons-promo-dicount-box cb-promo-expired">
							<div class="cb-coupons-promo-dicount-head">
								<div class="cb-coupons-promo-dicount-offer">
									<h5> 20% off</h5>
									<p>Exp : Mar 12 2023</p>
								</div>
								<div class="cb-coupons-promo-dicount-code">
									<p>Coupon code:</p>
									<h5>VHEALTHY</h5>
								</div>
							</div>	
							<div class="cb-coupons-promo-bottom-category">
								<a href="#">Used by 12 customers</a>
							</div>
						</div>
					</div>
            
            </div>

            <div class="cb-hr-dc"></div>

            <div class="row">               
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="card-p-10">
                     <div class="row product-header">                   
                        <div class="col-md-12">     
                           <div class="card-header content-header">
                              <div class="col-md-6 col-8 hd-coupons hd-coupons-2-1">                    
                                 <h4>Cashback</h4>
                              </div>
                              <div class="col-md-6 col-4 hd-coupons-1 hd-coupons-3">
                                 <div class="form-group form-group-inventory cb-mb-none">
                                    <input type="text" placeholder="Customer Phone Number" class="search-mt-20" id="inputFname"/>
                                    <i class="fas fa-search inventory-search-icon"></i>
                                 </div> 
                              </div>
                           </div>
                           
                           <div class="hd-cashback-title">
                              <h6>You are currently providing <span> 12% </span> cashback on all orders</h6>
                                 <h6>Total cashback available with customers: <span>₹ 1750</span></h6>
                           </div>                             
                        </div>
                     </div>                     
                  </div>  
               </div>
            </div>
               
            <div class="row">  
               <div class="col-md-3 p-r-0">
                  <div class="cb-cashback">
                     <div  class="cb-cashback-log">                  
                        <div class="cb-cashback-profile">      
                           <img src="https://storage.googleapis.com/bo3151920215/account/4d60cacd94144abb91782ce7fbf37ace/profilephoto.jpeg"  class="cbbd_logo"/>
                           <div class="cb-cashback-user">
                              <h5>Vishnupriya</h5>
                              <div>
                              <h6>Available Cashback <span>₹ 150</span></h6>
                              </div>
                           </div>
                        </div> 
                     </div>
                     <div class="cb-cashback-code-box-1">
                        <div class="cb-cashback-offers-1">
                           <h6>Total Earned:<span>₹ 250</span></h6>    
                           <h6>Valid Till:<span>Dec 12 2025</span></h6>       
                        </div>
                     </div>
                  </div>
               </div>   
               <div class="col-md-3 p-r-0">
                  <div class="cb-cashback">
                     <div  class="cb-cashback-log">                  
                        <div class="cb-cashback-profile">      
                           <img src="https://storage.googleapis.com/bo3151920215/account/4bbce13937c348e7ad8c9083f53473b5/profilephoto.jpeg"  class="cbbd_logo"/>
                           <div class="cb-cashback-user">
                              <h5>Eswar Angusamy</h5>
                              <div>
                              <h6>Available Cashback <span>₹ 75</span></h6>
                              </div>
                           </div>
                        </div> 
                     </div>
                     <div class="cb-cashback-code-box-1">
                        <div class="cb-cashback-offers-1">
                           <h6>Total Earned:<span>₹ 380</span></h6>    
                           <h6>Valid Till:<span>Feb 01, 2024</span></h6>       
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-3 p-r-0">
                  <div class="cb-cashback">
                     <div  class="cb-cashback-log">                  
                        <div class="cb-cashback-profile">      
                           <img src="https://storage.googleapis.com/bo3151920215/account/e1ce7d3e29e64fea8bfb05c5d5ce822f/profilephoto.jpeg"  class="cbbd_logo"/>
                           <div class="cb-cashback-user">
                              <h5>Anantha Narayanan</h5>
                              <div>
                              <h6>Available Cashback <span>₹ 175</span></h6>
                              </div>
                           </div>
                        </div> 
                     </div>
                     <div class="cb-cashback-code-box-1">
                        <div class="cb-cashback-offers-1">
                           <h6>Total Earned:<span>₹ 310</span></h6>    
                           <h6>Valid Till:<span>Dec 12 2025</span></h6>       
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-3">
                  <div class="cb-cashback">
                     <div  class="cb-cashback-log">                  
                        <div class="cb-cashback-profile">      
                           <img src="https://storage.googleapis.com/bo3151920215/account/e1ce7d3e29e64fea8bfb05c5d5ce822f/profilephoto.jpeg"  class="cbbd_logo"/>
                           <div class="cb-cashback-user">
                              <h5>Anantha Narayanan</h5>
                              <div>
                              <h6>Available Cashback <span>₹ 175</span></h6>
                              </div>
                           </div>
                        </div> 
                     </div>
                     <div class="cb-cashback-code-box-1">
                        <div class="cb-cashback-offers-1">
                           <h6>Total Earned:<span>₹ 310</span></h6>    
                           <h6>Valid Till:<span>Dec 12 2025</span></h6>       
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-3">
                  <div class="cb-cashback">
                     <div  class="cb-cashback-log">                  
                        <div class="cb-cashback-profile">      
                           <img src="https://storage.googleapis.com/bo3151920215/account/d2a9524d80794f4ba8441581c548fdd8/profilephoto.jpeg"  class="cbbd_logo"/>
                           <div class="cb-cashback-user">
                              <h5>Priya Ramachandran</h5>
                              <div>
                              <h6>Available Cashback <span>₹ 260</span></h6>
                              </div>
                           </div>
                        </div> 
                     </div>
                     <div class="cb-cashback-code-box-1">
                        <div class="cb-cashback-offers-1">
                           <h6>Total Earned:<span>₹ 380</span></h6>    
                           <h6>Valid Till:<span>Dec 12 2025</span></h6>       
                        </div>
                     </div>
                  </div>
               </div>      
            
            </div>
         </div>
      </div>

 </>
  ); 
} 
export default CouponsDiscount;