import React from 'react'; 
import Header from '../Catalog/Header';
import SideBar from '../../components/SideBar';

function LowStock() 
{ 
return(
 <>
   <Header />     
   <SideBar />

      <div class="page-wrapper">
         <div class="content">    
         <div class="row">
               <div class="col-lg-6 col-sm-6 col-12">
                  <div class="cb-business-header">
                     <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg" alt='Logo'/>
                     <h6>Plant Science</h6>
                  </div>
               </div> 
               <div class="col-lg-6 col-sm-6 col-12">
                  <div class="dash-widget">
                     <div class="dash-widget-content">
                        {/* <div class="dash-widgetimg">
                           <img src="./assets/images/dash1.svg" alt="img" />
                        </div> */}
                        <div class="dash-widgetcontent">
                           <h6>Total Product</h6>
                           <h5>145</h5>
                        </div>
                     </div>
                     <div class="dash-widget-content">
                        {/* <div class="dash-widgetimg">
                           <img src="./assets/images/dash2.svg" alt="img" />
                        </div> */}
                        <div class="dash-widgetcontent">
                           <h6>Total Sales</h6>
                           <h5>Rs 254385</h5>
                        </div>
                     </div>
                     <div class="dash-widget-content wdth-100">
                        {/* <div class="dash-widgetimg dash-widgetimg1">
                           <img src="./assets/images/store.svg" alt="img" />
                        </div> */}
                        <div class="dash-widgetcontent">
                           <h5>Ecommerce enabled</h5>
                           <div class="toggle-btn toggle-btn1">
                              <input type="checkbox" id="toggle-btn-5"/>
                              <label for="toggle-btn-5"></label>
                           </div>
                        </div>
                     </div>
                     
                  </div>
               </div>
            </div>
            <div class="row">               
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="card-p-10">
                     <div class="row product-header">                    
                        <div class="col-md-12">     
                           <div class="card-header content-header">
                              <div>                    
                                 <h4>Active Inventory</h4>
                              </div>
                              {/* <div>
                                 <a href="#" class="btn btn-add-business btn-postion">
                                 <span class="material-icons-outlined">inventory</span>
                                 <div class="text-mb">Update Product</div></a>
                              </div> */}
                           </div>  
                           <div class="cb-inventory-tab-head pd-mt-10">
                              <ul class="cb-inventory-nav-tabs cb-inventory-nav-tabs-business cb-inventory-nav-tabs-product">
                                 <li><a href="/OutofStock">Out of Stock - 17</a></li>
                                 <li><a class="active" href="/LowStock">Low Stock - 12</a></li>
                                 <li><a href="/PriceUpdate">Price Update - 24</a></li>
                                 <li><a href="/Inventory">All Stock - 53</a></li>
                              </ul>     
                           </div>
                        </div>
                        <div class="col-md-12 inventory-header">
                           <div class="head-help">                    
                              <p>
                                 How to update Stock?
                                 <a class="tooltip-tags" data-gloss=" The below products are ‘Out of Stock’ and are not orderable by the customer. Please update the ‘AVAILABLE QTY’."><span class="material-icons">info</span></a>
                              </p>
                           </div>
                           <div>
                              <a href="#" class="btn btn-add-business">
                              <span class="material-icons-outlined">inventory</span>
                              <div class="text-mb">Update Inventory</div></a>
                           </div>
                        </div>  
                        <div class="col-md-5 cb-inventory-tab-title">         
                           <h5>Low Stock <span>- 12</span></h5>
                           <i class="fas fa-search mb-search-icon"></i>
                        </div>
                        <div class="col-md-7 cb-inventory-tab-title"> 
                           <div class="form-group form-group-inventory">
                              <label>Product Category</label>
                              <select class="select">
                                 <option>Clothing & Accessories</option>
                                 <option>Home, Kitchen & Garden</option>
                                 <option>Laundry Supplies</option>
                                 <option>Air Fresheners</option>
                              </select>
                           </div>
                        
                           <div class="form-group form-group-inventory">
                              <label>Product Sub Category</label>
                              <select class="select">
                                 <option>All</option>
                                 <option>Shoe Care</option>
                                 <option>Leather Shoes</option>
                                 <option>Sports Shoes</option>
                                 <option>Laundry Detergent</option>
                              </select>
                           </div>    
                           <div class="form-group form-group-inventory cb-mb-none">
                              <input type="text" placeholder="Search Product" class="search-mt-20" id="inputFname"/>
                              <i class="fas fa-search inventory-search-icon"></i>
                           </div>
                        </div> 

                        <div class="col-lg-12 col-sm-12 col-12">
                           <div class="cb-inventory-table">
                              <div class="cb-inventory-table-head">
                                 <div class="col-md-1 cb-col-inv-1">
                                    {/* <div class="checkbox">
                                       <label><input type="checkbox" value="check1"/> </label>
                                    </div> */}
                                 </div>
                                 <div class="col-md-6 cb-col-inv-6">
                                    <h4>PRODUCT </h4>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2">
                                    <h4>AVAILABLE QTY </h4>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2">
                                    <h4>MIN QTY <span>(per order)</span></h4>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2">
                                    <h4>MAX QTY <span>(per order)</span></h4>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2">
                                    <h4>ALERT QTY </h4>
                                 </div>
                              </div>

                              <div class="cb-inventory-table-body">
                                 <div class="col-md-1 cb-col-inv-1">
                                    <div class="checkbox">
                                       <label><input type="checkbox" value="check1"/> </label>
                                    </div>
                                 </div>
                                 <div class="col-md-6 cb-col-inv-6">
                                    <div class="cb-inventory-product-data">
                                       <div class="cb-inventory-product-data-img">
                                          <img src="https://storage.googleapis.com/bo3151920215/business/a2908c3dd2d74dcd95463059a7f094f6/product/e7cb4f9cc93c4d96967163743f088999/Product2-720822.png" alt="Image" />
                                       </div>
                                       <div class="cb-inventory-product-info">
                                          <h5>Liquid Shoe Polish 75ML Black</h5>
                                          <del>₹ 312</del><span>₹ 300</span>
                                          <p>Current Stock:<strong>110</strong></p>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>AVA QTY </h4>
                                    <input type="text" value="2" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>MIN QTY </h4>
                                    <input type="text" value="1" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>MAX QTY </h4>
                                    <input type="text" value="200" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>ALERT QTY</h4>
                                    <input type="text" value="57" />
                                 </div>
                              </div>

                              <div class="cb-inventory-table-body">
                                 <div class="col-md-1 cb-col-inv-1">
                                    <div class="checkbox">
                                       <label><input type="checkbox" value="check1"/> </label>
                                    </div>
                                 </div>
                                 <div class="col-md-6 cb-col-inv-6">
                                    <div class="cb-inventory-product-data">
                                       <div class="cb-inventory-product-data-img">
                                          <img src="https://storage.googleapis.com/bo3151920215/business/a2908c3dd2d74dcd95463059a7f094f6/product/89a00bb7ef884967b5a643d52cfe09c9/Product3-370971.png" alt="Image" />
                                       </div>
                                       <div class="cb-inventory-product-info">
                                          <h5>Relaxing Lavender Pocket Gel Freshner</h5>
                                          <del>₹ 85</del><span>₹ 70</span>
                                          <p>Current Stock:<strong>143</strong></p>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>AVA QTY </h4>
                                    <input type="text" value="5" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>MIN QTY </h4>
                                    <input type="text" value="1" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>MAX QTY </h4>
                                    <input type="text" value="120" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>ALERT QTY</h4>
                                    <input type="text" value="90" />
                                 </div>
                              </div>

                              <div class="cb-inventory-table-body">
                                 <div class="col-md-1 cb-col-inv-1">
                                    <div class="checkbox">
                                       <label><input type="checkbox" value="check1"/> </label>
                                    </div>
                                 </div>
                                 <div class="col-md-6 cb-col-inv-6">
                                    <div class="cb-inventory-product-data">
                                       <div class="cb-inventory-product-data-img">
                                          <img src="https://storage.googleapis.com/bo3151920215/business/a2908c3dd2d74dcd95463059a7f094f6/product/25a186e7320d47078ceef023c0ec5fd7/Product0-600610.png" alt="Image" />
                                       </div>
                                       <div class="cb-inventory-product-info">
                                          <h5>Max Freshocare Liqud Detergent</h5>
                                          <del>₹ 599</del><span>₹ 579</span>
                                          <p>Current Stock:<strong>68</strong></p>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>AVA QTY </h4>
                                    <input type="text" value="5" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>MIN QTY </h4>
                                    <input type="text" value="2" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>MAX QTY </h4>
                                    <input type="text" value="250" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>ALERT QTY</h4>
                                    <input type="text" value="160" />
                                 </div>
                              </div>

                              <div class="cb-inventory-table-body">
                                 <div class="col-md-1 cb-col-inv-1">
                                    <div class="checkbox">
                                       <label><input type="checkbox" value="check1"/> </label>
                                    </div>
                                 </div>
                                 <div class="col-md-6 cb-col-inv-6">
                                    <div class="cb-inventory-product-data">
                                       <div class="cb-inventory-product-data-img">
                                          <img src="https://storage.googleapis.com/bo3151920215/business/a2908c3dd2d74dcd95463059a7f094f6/product/6a3353cbc5164c2289d0fc2b1b391413/Product0-324282.jpg" alt="Image" />
                                       </div>
                                       <div class="cb-inventory-product-info">
                                          <h5>Gel Freshner 50 g x Four fragrance</h5>
                                          <del>₹ 230</del><span>₹ 200</span>
                                          <p>Current Stock:<strong>91</strong></p>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>AVA QTY </h4>
                                    <input type="text" value="1" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>MIN QTY </h4>
                                    <input type="text" value="1" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>MAX QTY </h4>
                                    <input type="text" value="130" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>ALERT QTY</h4>
                                    <input type="text" value="80" />
                                 </div>
                              </div>

                              <div class="cb-inventory-table-body">
                                 <div class="col-md-1 cb-col-inv-1">
                                    <div class="checkbox">
                                       <label><input type="checkbox" value="check1"/> </label>
                                    </div>
                                 </div>
                                 <div class="col-md-6 cb-col-inv-6">
                                    <div class="cb-inventory-product-data">
                                       <div class="cb-inventory-product-data-img">
                                          <img src="https://storage.googleapis.com/bo3151920215/business/a2908c3dd2d74dcd95463059a7f094f6/product/c79d7485ba454fc2b15bb7094031ed83/Product1-364545.png" alt="Image" />
                                       </div>
                                       <div class="cb-inventory-product-info">
                                          <h5>Tangy Lemon Pocket Gel Freshner</h5>
                                          <del>₹ 280</del><span>₹ 250</span>
                                          <p>Current Stock:<strong>35</strong></p>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>AVA QTY </h4>
                                    <input type="text" value="1" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>MIN QTY </h4>
                                    <input type="text" value="2" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>MAX QTY </h4>
                                    <input type="text" value="140" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-2 cb-inventory-input cb-inv-header-body cb-col-mb-2">
                                    <h4>ALERT QTY</h4>
                                    <input type="text" value="80" />
                                 </div>
                              </div>
                           </div>
                        </div>


                     </div>
                  </div>   
               </div>
            </div>
         </div>
      </div>
 </>
  ); 
} 
export default LowStock;