import React from 'react'; 
import Header from '../Dashboard/Header';
import SideBar from '../../components/SideBar';

function CouponsDiscountEmpty() 
{ 
return(
 <>
   <Header />     
   <SideBar />

      <div class="page-wrapper">
         <div class="content">    
            <div class="row">
               <div class="col-lg-5 col-sm-6 col-12">
                  <div class="cb-business-header">
                     <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg" alt='Logo'/>
                     <h6>Plant Science</h6>
                  </div>
               </div> 
               <div class="col-lg-7 col-sm-6 col-12">
                  {/* <div class="dash-widget">
                     <div class="dash-widget-content">
                        <div class="dash-widgetimg">
                        <span class="material-icons-outlined">discount</span>
                        </div>
                        <div class="dash-widgetcontent">
                           <h6>Total Discount</h6>
                           <h5>618</h5>
                        </div>
                     </div>
                     <div class="dash-widget-content">
                        <div class="dash-widgetimg">
                           <span class="material-icons-outlined">local_offer</span>
                        </div>
                        <div class="dash-widgetcontent">
                           <h6>Active Discount</h6>
                           <h5>572</h5>
                        </div>
                     </div>
                     <div class="dash-widget-content">
                        <div class="dash-widgetimg">
                           <span class="material-icons-outlined">cancel_presentation</span>
                        </div>
                        <div class="dash-widgetcontent">
                           <h6>Expired Discount</h6>
                           <h5>46</h5>
                        </div>
                     </div>
                     
                  </div> */}
               </div>
            </div>
            <div class="row">               
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="card-p-10">
                     <div class="row product-header">                   
                        <div class="col-md-12">     
                           <div class="card-header content-header">
                              <div class="col-md-6 col-8 hd-coupons hd-coupons-2-2">                  
                                 <h4>Coupons/Promo Code</h4>
                                 <div class="legend-1">
                                    <div class="bars-notification-1"> <div class="bars-1 bars-active"></div> <h6>Active</h6></div>
                                    <div class="bars-notification-1"> <div class="bars-1 bars-inactive"></div> <h6>Expired</h6></div>
                                 </div> 
                              </div>
                              <div class="col-md-6 col-4 hd-coupons-1 hd-coupons-clr">
                                 <a href="/AddCoupon" class="btn btn-add-business">
                                 <span class="material-icons-outlined">add</span> <div class="text-mb">New Coupon</div></a>
                              </div>
                           </div>                             
                        </div>
                     </div>                     
                  </div>  
               </div>
            </div>
            <div class="row">
               <div class="col-lg-12">
                  <div class="discount-cashback-empty">
                     <span class="material-icons-outlined">card_giftcard</span>
                     <h5>No Coupons Available</h5>
                  </div>
					</div>            
            </div>

            <div class="cb-hr-dc"></div>

            <div class="row">               
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="card-p-10">
                     <div class="row product-header">                   
                        <div class="col-md-12">     
                           <div class="card-header content-header">
                              <div class="col-md-6 col-8 hd-coupons hd-coupons-2-2">                    
                                 <h4>Cashback</h4>
                              </div>
                              <div class="col-md-6 col-4 hd-coupons-1 hd-coupons-3">
                                 <div class="form-group form-group-inventory cb-mb-none">
                                    <input type="text" placeholder="Customer Phone Number" class="search-mt-20" id="inputFname"/>
                                    <i class="fas fa-search inventory-search-icon"></i>
                                 </div> 
                              </div>
                           </div>
                           
                           <div class="hd-cashback-title">
                              <h6>You are currently providing <span> 0% </span> cashback on all orders</h6>
                              <h6>Total cashback available with customers: <span>₹ 0</span></h6>
                           </div>                             
                        </div>
                     </div>                     
                  </div>  
               </div>
            </div>
               
            <div class="row">
               <div class="col-lg-12">
                  <div class="discount-cashback-empty">
                     <span class="material-icons-outlined">credit_card_off</span>
                     <h5>No Cashback available with customers</h5>
                  </div>
					</div>            
            </div>
         </div>
      </div>

 </>
  ); 
} 
export default CouponsDiscountEmpty;