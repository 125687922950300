import React from 'react'; 
import Header from '../Header';
import SideBar from '../../../components/SideBar';

function EditProduct() 
{ 
return(
 <>
   <Header />     
   <SideBar />

   <div class="page-wrapper">
      <div class="content">
            <div class="row">
               <div class="col-lg-12 col-sm-12 col-12 mb-10">
                  <div class="cb-business-header">
                     <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg" alt='Logo'/>
                     <h6>Plant Science</h6>
                  </div>
               </div> 
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="card">
                     <div class="page-title-process">
                        <div class="card-header cb-display-flex">                     
                           <h4>Anti Blemish Cream - 20g</h4>
                        </div>
                     </div>
                     <div class="card-body">
                        <div class="row">  
                        <div class="col-lg-12 col-sm-12 col-12">
                           <ul class="nav nav-tabs cb-nav-tabs">
                              <li><a class="active" href="/EditProduct"><span class="material-icons-outlined">inventory_2</span>Product Detail</a></li>
                              <li><a href="/EditCategory"><span class="material-icons-outlined">inventory</span>Specification</a></li>
                              <li><a href="/EditPriceTaxInfo"><span class="material-icons-outlined">request_quote</span>Pricing</a></li>
                              <li><a href="/EditMeasurementDeliveryMethod"><span class="material-icons-outlined">view_in_ar</span>Measurement Info</a></li>
                              <li><a href="/EditProductAvailablity"><span class="material-icons-outlined">assignment</span>Availablity</a></li>
                              {/* <li><a href="/SubscriptionInformation"><span class="material-icons-outlined">people</span>Subscription</a></li> */}
                          </ul>
                        </div>                          
                           {/* <div class="col-lg-12 col-sm-12 col-12">
                              <div class="form-group form-group-edit">
                                 <label>Product Name <span>*</span></label>
                                 <input type="text" value="Anti Blemish Cream - 20g" placeholder='Enter Product Name'/>
                              </div>
                           </div>                                                     */}
                           <div class="col-lg-4 col-sm-4 col-12">
                              <div class="form-group form-group-edit">
                                 <label>Brand</label>
                                 <input type="text" value="Skin Care" placeholder='Enter Brand'/>
                              </div>
                           </div>    
                           <div class="col-lg-4 col-sm-4 col-12">
                              <div class="form-group form-group-edit">
                                 <label>Country of Origin <span>*</span></label>
                                 <select class="select">
                                    <option>India</option>
                                 </select>
                              </div>
                           </div>
                           <div class="col-lg-4 col-sm-4 col-12">
                              <div class="form-group form-group-edit">
                                 <label>SKU ID</label>
                                 <input type="text" value="PS24NEW1527" placeholder='Enter SKU ID'/>
                              </div>
                           </div>                                               
                           <div class="col-lg-12">
                              <div class="form-group form-group-edit">
                                 <label>Product Description <span>*</span></label>
                                 <textarea class="form-control" value='Get radiant, blemish-free skin with the Plant Science Anti Blemish Cream using the healing properties of almond oil and brightening properties of papaya.Clean your face and neck with Plant Science Face Wash, pat dry, then gently apply Plant Science Anti Blemish Cream over the face and neck.'></textarea>
                              </div>
                           </div> 
                           <div class="col-lg-12 col-sm-12 col-12 product-image-upload-max">
                              <h6>Upload Product Image (Upto Max 4) <span>*</span></h6>
                           </div>     
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group form-group-edit">
                                 <div class="image-upload image-upload-1">                                    
                                    <span class="material-icons-outlined">remove_circle</span>
                                    <div class="image-upload-image image-upload-image-prd">
                                       <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/product/c13f912e3036400eafb604d35e05f4ee/Product1-287409.jpeg" alt="img"/>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group form-group-edit">
                                 <div class="image-upload image-upload-1">                                    
                                    <span class="material-icons-outlined">remove_circle</span>
                                    <div class="image-upload-image image-upload-image-prd">
                                       <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/product/c13f912e3036400eafb604d35e05f4ee/Product2-836905.jpeg" alt="img"/>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group form-group-edit">
                                 <div class="image-upload image-upload-1">                                    
                                    <span class="material-icons-outlined">remove_circle</span>
                                    <div class="image-upload-image image-upload-image-prd">
                                       <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/product/c13f912e3036400eafb604d35e05f4ee/Product3-219997.jpeg" alt="img"/>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group form-group-edit">
                                 <div class="image-upload image-upload-1">                                    
                                    <span class="material-icons-outlined">remove_circle</span>
                                    <div class="image-upload-image image-upload-image-prd">
                                       <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/product/c13f912e3036400eafb604d35e05f4ee/Product4-392839.jpeg" alt="img"/>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-lg-4 col-sm-4 col-4">
                              {/* <a href="#" class="btn btn-back">Go Back</a> */}
                           </div>
                           <div class="col-lg-8 col-sm-12 col-12 cb-txet-right">
                           <a href="/ProductActive" class="btn btn-submit">Save</a>
                           <a href="/EditCategory" class="btn btn-next cb-ml-4">Preview & Submit</a>
                           </div>
                        </div>
                     </div>
                  </div>
            </div>
            
         </div>
      </div>
   </div>

 </>
  ); 
} 
export default EditProduct;