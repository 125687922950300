import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PlaceOrder from '../components/PlaceOrder'
function HowItWork() {
return( 
<>
<Header />


<section class="how-its-work-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <div class="section-heading">         
                        <span>Key Features for CostBo</span>            
                        <h2>How It Works</h2>
                    </div>
                </div>
            </div>
            <div class="row mb-10" >
                <div class="col-lg-3 col-sm-6 mb-lg-0">
                    <div class="card how-its-work-card how-its-work-hover">
                        <div class="card-body">
                            {/* <div>
                                <span class="material-icons">shopping_cart_checkout</span>
                            </div> */}
                            <div>
                                <h5>Tracking</h5>
                                <p>National / Hyper local Logistics & Tracking</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="card how-its-work-card how-its-work-hover mb-lg-0">
                        <div class="card-body">
                            <div>
                                <h5>Payment integrations</h5>
                                <p>Quick is optimized to work for most devices.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="card how-its-work-card how-its-work-hover mb-lg-0">
                        <div class="card-body">
                            <div>
                                <h5>Business Marketing</h5>
                                <p>Webstore WhatsApp & Influencer marketing.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="card how-its-work-card how-its-work-hover mb-lg-0">
                        <div class="card-body">
                            <div>
                                <h5>Mobile optimized</h5>
                                <p>Change a few variables and the whole theme adapts.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-20">
                <div class="col-lg-3 col-sm-6 mb-lg-0">
                    <div class="card how-its-work-card how-its-work-hover">
                        <div class="card-body">
                            <div>
                                <h5>Built in SEO</h5>
                                <p>All components are built to be used in any combination.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="card how-its-work-card how-its-work-hover mb-lg-0">
                        <div class="card-body">
                            <div>
                                <h5>Inventory & Order management</h5>
                                <p >Quick is optimized to work for most devices.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="card how-its-work-card how-its-work-hover mb-lg-0">
                        <div class="card-body">
                            <div>
                                <h5>Multiple managers</h5>
                                <p >Remain consistent while developing new features.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="card how-its-work-card how-its-work-hover mb-lg-0">
                        <div class="card-body">
                            <div>
                                <h5>Customizable</h5>
                                <p >Change a few variables and the whole theme adapts.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    

<div class="steps-to-promote steps-bg">
   <div class="container">
      <div class="row align-items-center">
         <div class="col-lg-6 align-items-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
            <div class="sec-title step_title mb-20 mt-20">
                <h4>After registering your business Seller dashboard will be available</h4>
            </div>
            <div class="step_content">
               <div class="step">
                  <div>
                     <span>1</span>
                     <p>Instant payments</p>
                  </div>
                  <div>
                     <span>2</span>
                     <p>Pickup</p>
                  </div>
                  <div>
                     <span>3</span>
                     <p>Pickup</p>
                  </div>
                  <div>
                     <span>4</span>
                     <p>Delivery</p>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-lg-1 align-items-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
            <div class="steps_list text-center">
               <h5></h5>
            </div>
         </div>
         <div class="col-lg-5 align-items-center  wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
            <div class="about-content">
               <img src="../assets/images/seller_dashboard.png" alt="Influencers & Affiliates Network" />
            </div>
         </div>
      </div>
   </div>
</div>

    
    <section class="steps-to-promote ptb-90">
   <div class="container">
      <div class="row">
         <div class="col-md-12">
            <div class="sec-title text-center step_title mb-20 mt-20">
               <h4>Run your entire web store from Android/ IOS screen</h4>
             </div>
         </div>
      </div>
      <div class="row align-items-center">
         <div class="col-md-4 col-sm-6">
            <div class="single-feature single-feature-right">               
               <div class="feature-content feature-content-txt-right">
                  <h5>Generate Pay Cart Link</h5>
                  <p>Add products, Customer Address & Shipping method</p>
               </div>
               <div class="feature-icon feature-icon-1 mr-20">
               <img src="../assets/images/icons/paymnet-link.jpg" class="feature-icon-img" alt="Image placeholder" />
               </div>
            </div>
            <div class="single-feature single-feature-right">               
               <div class="feature-content feature-content-txt-right">
                  <h5>Calculate Shipping Cost</h5>
                  <p>Entire shipping weight and destination to calculate the delivery cost</p>
               </div>
               <div class="feature-icon feature-icon-1 feature-icon-width">
               <img src="../assets/images/icons/calculate-shipping.svg"  alt="Image placeholder" />
               </div>
            </div>
            <div class="single-feature single-feature-right">               
               <div class="feature-content feature-content-txt-right">
                  <h5>Customer Orders</h5>
                  <p>View, Monitor, Approve & Ship Orders</p>
               </div>
               <div class="feature-icon feature-icon-1">
               <img src="../assets/images/icons/customer-orders.svg"  alt="Image placeholder" />
               </div>
            </div>
            <div class="single-feature single-feature-right">               
               <div class="feature-content feature-content-txt-right">
                  <h5>Push Notification </h5>
                  <p>Send notifications about deals to customers</p>
               </div>
               <div class="feature-icon feature-icon-1">
               <img src="../assets/images/icons/new-sale-post.jpeg"  alt="Image placeholder" />
               </div>
            </div>
            <div class="single-feature single-feature-right">               
               <div class="feature-content feature-content-txt-right">
                  <h5>Share Catalogs</h5>
                  <p>Share catalogs, discounts to customers</p>
               </div>
               <div class="feature-icon feature-icon-1">
               <img src="../assets/images/icons/share-color.jpeg"  alt="Image placeholder" />
               </div>
            </div>        
            
            
            
         </div>
         <div class="col-md-4 hidden-sm hidden-xs">
            <div class="feature-image">
                <img alt="Image placeholder" src="../assets/images/run_your_ecommerce_business1.jpg" class="img-center img-fluid"/>
            </div>
         </div>
         <div class="col-md-4 col-sm-6">
            <div class="single-feature single-feature-left">
               <div class="feature-icon">
               <img src="../assets/images/icons/update-catalogs.jpeg"  alt="Image placeholder" />
               </div>
               <div class="feature-content">
                  <h5>Update Inventory</h5>
                  <p>Update product price, availability and inventory</p>
               </div>
            </div>
            <div class="single-feature single-feature-left">
               <div class="feature-icon">
               <img src="../assets/images/icons/list.svg"referral-discounts  alt="Image placeholder" />
               </div>
               <div class="feature-content">
                  <h5>CRM</h5>
                  <p>Automated CRM, Purchase details & retargetting</p>
               </div>
            </div>
            <div class="single-feature single-feature-left">
               <div class="feature-icon">
               <img src="../assets/images/icons/offer.svg"  alt="Image placeholder" />
               </div>
               <div class="feature-content">
                  <h5>Referral Discounts</h5>
                  <p>Provide % discounts to customer & their friends</p>
               </div>
            </div>
            <div class="single-feature single-feature-left">
               <div class="feature-icon">
               <img src="../assets/images/icons/referral-discounts.svg"  alt="Image placeholder" />
               </div>
               <div class="feature-content">
                  <h5>Promo Codes</h5>
                  <p>Generate promo codes to increase sales</p>
               </div>
            </div>
            
            <div class="single-feature single-feature-left">
               <div class="feature-icon">               
               <img src="../assets/images/icons/chat.svg" class="feature-icon-img1"  alt="Image placeholder" />
               </div>
               <div class="feature-content ml-20">
                  <h5>Customer Chat </h5>
                  <p>Receive customer messages at one place, including order details</p>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>


<div class="cb-how-its-work cb_pd_20 steps-bg">
    <div class="container text-center">   
        <div class="sec-title text-center mb-20 mt-20 wow bounceIn animated" data-wow-duration="1s" data-wow-delay="0.2s">                    
            <h4>Place order for your customers </h4>  
            
            <span>SEND order  + Shipment + Payment details in one link</span>     
            <div class="step_l_1">
                <h5>01</h5>                
                <h6>Steps</h6>  
            </div>      
        </div>
        <div class="row">
            <div class="col-lg-2  hw_steps">
                <img src="../assets/images/place_order/1.jpg" alt="Place order for your customers" />
            </div>    
            
            <div class="col-lg-2  hw_steps">
                <img src="../assets/images/place_order/2.jpg" alt="Place order for your customers" />
                <h6>Select products from catalog</h6>
            </div>         
            <div class="col-lg-2 hw_steps">
                <img src="../assets/images/place_order/3.jpg" alt="Place order for your customers" />
                <h6>Select products from catalog</h6>
            </div> 
            <div class="col-lg-2 hw_steps">
                <img src="../assets/images/place_order/4.jpg" alt="Place order for your customers" />
                <h6>Select products from catalog</h6>
            </div> 
            <div class="col-lg-2 hw_steps">
                <img src="../assets/images/place_order/5.jpg" alt="Place order for your customers" />
                <h6>Select products from catalog</h6>
            </div> 
        
        </div>
    </div>
</div>
 
<div class="cb-how-its-work cb_pd_20 ">
    <div class="container text-center">   
        <div class="sec-title text-center mb-20 mt-20 wow bounceIn animated" data-wow-duration="1s" data-wow-delay="0.2s">   
        
        {/* and deliver to 
customer location with tracking & insurance                    */}
            <h4>Calculate All India shipping cost</h4>         
            <span>costBo team will pick up from your warehouse  </span> 
            <div class="step_l_1">
                <h5>02</h5>                
                <h6>Steps</h6>  
            </div>                  
        </div>
        <div class="row">
            <div class="col-lg-2  hw_steps">
                <img src="../assets/images/place_order/1-1.jpg" alt="Place order for your customers" />
            </div>    
            
            <div class="col-lg-2  hw_steps">
                <img src="../assets/images/place_order/2.jpg" alt="Place order for your customers" />
                <h6>Select products from catalog</h6>
            </div>         
            <div class="col-lg-2 hw_steps">
                <img src="../assets/images/place_order/3.jpg" alt="Place order for your customers" />
                <h6>Select products from catalog</h6>
            </div> 
            <div class="col-lg-2 hw_steps">
                <img src="../assets/images/place_order/4.jpg" alt="Place order for your customers" />
                <h6>Select products from catalog</h6>
            </div> 
            <div class="col-lg-2 hw_steps">
                <img src="../assets/images/place_order/5.jpg" alt="Place order for your customers" />
                <h6>Select products from catalog</h6>
            </div> 
        
        </div>
    </div>
</div> 


<div class="cb-how-its-work cb_pd_20  steps-bg">
    <div class="container text-center">   
        <div class="sec-title text-center mb-20 mt-20 wow bounceIn animated" data-wow-duration="1s" data-wow-delay="0.2s">                      
            <h4>Answer Customer Inquiries </h4>      
            <div class="step_l_1">
                <h5>03</h5>                
                <h6>Steps</h6>  
            </div>                     
        </div>
        <div class="row">
            <div class="col-lg-2  hw_steps">
                <img src="../assets/images/place_order/1.jpg" alt="Place order for your customers" />
            </div>   
            <div class="col-lg-2  hw_steps">
                <img src="../assets/images/place_order/2.jpg" alt="Place order for your customers" />
                <h6>Listed of connected customers</h6>
            </div>         
            <div class="col-lg-2 hw_steps">
                <img src="../assets/images/place_order/3.jpg" alt="Place order for your customers" />
                <h6>Listed of connected customers</h6>
            </div> 
            <div class="col-lg-2 hw_steps">
                <img src="../assets/images/place_order/4.jpg" alt="Place order for your customers" />
                <h6>Chat Interface</h6>
            </div> 
            <div class="col-lg-2 hw_steps">
                <img src="../assets/images/place_order/5.jpg" alt="Place order for your customers" />
                <h6>More Options</h6>
            </div> 
        
        </div>
    </div>
</div>
 
<div class="cb-how-its-work cb_pd_20">
    <div class="container text-center">   
        <div class="sec-title text-center mb-20 mt-20 wow bounceIn animated" data-wow-duration="1s" data-wow-delay="0.2s">          
            <h4>Generate order Reports to excel</h4>  
            <div class="step_l_1">
                <h5>04</h5>                
                <h6>Steps</h6>  
            </div>                         
        </div>
        <div class="row">
            <div class="col-lg-2  hw_steps">
                <img src="../assets/images/place_order/1.jpg" alt="Place order for your customers" />
            </div>   
            <div class="col-lg-2  hw_steps">
                <img src="../assets/images/place_order/2.jpg" alt="Place order for your customers" />
                <h6>Listed of connected customers</h6>
            </div>         
            <div class="col-lg-2 hw_steps">
                <img src="../assets/images/place_order/3.jpg" alt="Place order for your customers" />
                <h6>Listed of connected customers</h6>
            </div> 
            <div class="col-lg-2 hw_steps">
                <img src="../assets/images/place_order/4.jpg" alt="Place order for your customers" />
                <h6>Chat Interface</h6>
            </div> 
            <div class="col-lg-2 hw_steps">
                <img src="../assets/images/place_order/5.jpg" alt="Place order for your customers" />
                <h6>More Options</h6>
            </div> 
        
        </div>
    </div>
</div> 

<div class="cb-how-its-work cb_pd_20">
    <div class="container text-center">   
        <div class="sec-title text-center mb-20 mt-20 wow bounceIn animated" data-wow-duration="1s" data-wow-delay="0.2s">          
            <h4>Inventory update from PC </h4>                         
        </div>
        <div class="row">
            <div class="col-lg-6">
                <img src="../assets/images/inventory-update1.jpg" alt="Place order for your customers" />
            </div>   
            <div class="col-lg-6">
                <img src="../assets/images/inventory-update2.jpg" alt="Place order for your customers" />
            </div>  
        
        </div>
    </div>
</div> 



<Footer/>
</>
);
}
export default HowItWork;