import React from 'react'; 
import Header from './Header';
import SideBar from '../../components/SideBar'; 
import DashboardSideBar from '../../components/DashboardSideBar';

function DashboardSideBarMenu() 
{ 
return(
 <>
 <DashboardSideBar/>
   <Header />     
   <SideBar />

   <div class="page-wrapper">
      <div class="content">
         <section>
            <div class="row">
               <div class="col-md-12">
                  <div class="dashboard-main-header">
                     <div class="dashboard-main-title">    
                        <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg"/>  
                        <div>             
                           <h2>Plant Science</h2>
                           <span>Onboarded on 12-MAR-2022</span>
                        </div> 
                     </div>
                     <div class="dashboard-header-widget">
                        <span>Total Sales</span>
                        <h4>₹ 75,283.00</h4>
                     </div>
                  </div>
               </div>
            </div>
               
            <div class="row">
               <div class="col-lg-8">
                  <div class="card-dashboard">
                     <div class="row"> 
                        <div class="col-md-12">
                           <div class="dashboard-header">
                              <div>                    
                                 <h3>Orders</h3>
                              </div>
                              <div>
                                 <a href="/Pending">Manage</a>
                              </div>
                           </div>                        
                        </div>                        
                        <div class="col-md-3 col-6 dw-mb-10">
                           <div class="dashboard-widget">
                              <span>New/Pending Approval</span>
                              <h4>7,800</h4>
                           </div>
                        </div>
                        <div class="col-md-3 col-6">
                           <div class="dashboard-widget">
                              <span>Pending Shipment</span>
                              <h4>247</h4>
                           </div>
                        </div>
                        <div class="col-md-3 col-6">
                           <div class="dashboard-widget">
                              <span>Shipped</span>
                              <h6>127</h6>
                           </div>
                        </div>
                        <div class="col-md-3 col-6">
                           <div class="dashboard-widget dashboard-widget-bdr">
                              <span>Total Orders</span>
                              <h5>1,350</h5>
                           </div>
                        </div>
                     </div>                
                  </div>             
               </div>
               <div class="col-lg-4">
                  <div class="card-dashboard">
                     <div class="row">   
                        <div class="col-md-12 12 col-12">
                           <div class="dashboard-header">
                              <div>                    
                                 <h3>Catalog</h3>
                              </div>
                              <div>
                                 <a href="/ProductActive">Manage</a>
                              </div>
                           </div>                        
                        </div>                      
                        <div class="col-md-6 col-6">
                           <div class="dashboard-widget">
                              <span>No of products</span>
                              <h4>1,460</h4>
                           </div>
                        </div>  
                        <div class="col-md-6 col-6">
                           <div class="dashboard-widget dashboard-widget-bdr">
                              <span>Products on Sale</span>
                              <h5>1,350</h5>
                           </div>
                        </div>       
                     </div>                
                  </div>             
               </div>               
            </div>
            
            <div class="row">
               <div class="col-lg-4">
                  <div class="card-dashboard">
                     <div class="row"> 
                        <div class="col-md-12 col-12">
                           <div class="dashboard-header">
                              <div>                    
                                 <h3>Inventory</h3>
                              </div>
                              <div>
                                 <a href="/Inventory">Manage</a>
                              </div>
                           </div>                        
                        </div>   
                        <div class="col-md-12 col-12">
                           <div class="widget-wrap">
                              <div class="donut"><div class="hole"></div></div>
                              <div class="legend">
                                 <div class="bars-notification"> <div class="bars bars-ava"></div> <h6>Available</h6></div>
                                 <div class="bars-notification"> <div class="bars bars-low"></div> <h6>Low Stock</h6></div>
                                 <div class="bars-notification"> <div class="bars bars-out"></div> <h6>Out of Stock</h6></div>
                              </div>                                 
                           </div>
                        </div>                            
                        <div class="col-md-4 col-4">
                           <div class="dashboard-widget">
                              <span>Available</span>
                              <h5>7,800</h5>
                           </div>
                        </div>
                        <div class="col-md-4 col-4">
                           <div class="dashboard-widget">
                              <span>Low Stock</span>
                              <h6>247</h6>
                           </div>
                        </div>
                        <div class="col-md-4 col-4">
                           <div class="dashboard-widget dashboard-widget-bdr">
                              <span>Out of Stock</span>
                              <h6>247</h6>
                           </div>
                        </div>
                     </div>                
                  </div>             
               </div>
               <div class="col-lg-4">
                  <div class="card-dashboard">
                     <div class="row">   
                        <div class="col-md-12 col-12">
                           <div class="dashboard-header">
                              <div>                    
                                 <h3>Total Sales</h3>
                              </div>
                              <div>
                                 <a href="#">Manage</a>
                              </div>
                           </div>                        
                        </div>                      
                        <div class="col-md-12 col-12">
                           <table id="q-graph">
                              <tbody>
                                 <tr class="qtr" id="q1">
                                    <td class="sent bar h-1"></td>
                                    <td class="paid bar h-1"></td>
                                 </tr>
                                 <tr class="qtr" id="q2">
                                    <td class="sent bar h-2"></td>
                                    <td class="paid bar h-2"></td>
                                 </tr>
                                 <tr class="qtr" id="q3">
                                    <td class="sent bar h-3"></td>
                                    <td class="paid bar h-3"></td>
                                 </tr>
                                 <tr class="qtr" id="q4">
                                    <td class="sent bar h-4"></td>
                                    <td class="paid bar h-4"></td>
                                 </tr>
                                 <tr class="qtr" id="q5">
                                    <td class="sent bar h-5"></td>
                                    <td class="paid bar h-5"></td>
                                 </tr>
                              </tbody>
                           </table>
                           <div id="ticks">
                              <div class="tick  h-2" ><p></p></div>
                              <div class="tick  h-2" ><p>5k</p></div>
                              <div class="tick  h-2" ><p>4k</p></div>
                              <div class="tick  h-2" ><p>3k</p></div>
                              <div class="tick  h-2" ><p>2k</p></div>
                              <div class="tick  h-1" ><p>1k</p></div>
                           </div>
                        </div>                             
                     </div>                
                  </div>             
               </div>     
               <div class="col-lg-4">
                  <div class="card-dashboard">
                     <div class="row">   
                        <div class="col-md-12 col-12">
                           <div class="dashboard-header">
                              <div>                    
                                 <h3>Customer Traction</h3>
                              </div>
                           </div>                        
                        </div>                  
                        <div class="col-md-12 col-12">    
                           <div class="row bdr-btm">                 
                              <div class="col-md-6 col-6">
                                 <div class="dashboard-widget">
                                    <span>Views</span>
                                    <h4>1,520</h4>
                                 </div>
                              </div>  
                              <div class="col-md-6 col-6">
                                 <div class="dashboard-widget dashboard-widget-bdr">
                                    <span>Followers</span>
                                    <h5>450</h5>
                                 </div>
                              </div>       
                           </div>  
                           
                           <div class="row bdr-btm1">                  
                              <div class="col-md-6 col-6">
                                 <div class="dashboard-widget">
                                    <span>Reviews</span>
                                    <h4>875</h4>
                                 </div>
                              </div>  
                              <div class="col-md-6 col-6">
                                 <div class="dashboard-widget dashboard-widget-bdr">
                                    <span>Unique buyers</span>
                                    <h5>1,278</h5>
                                 </div>
                              </div>       
                           </div>   
                           <div class="row bdr-btm1">                  
                              <div class="col-md-12 col-12">
                                 <div class="dashboard-widget-rating">
                                    <h5>Rating:</h5>
                                    <div class="rating-inner"><span class="material-icons-round">star</span><h6>4.8</h6></div>
                                 </div>
                              </div>        
                           </div>   
                        </div>      
                     </div>                
                  </div>             
               </div>           
            </div>

            <div class="row">
               <div class="col-lg-4">
                  <div class="card-dashboard">
                     <div class="row"> 
                        <div class="col-md-12 col-12">
                           <div class="dashboard-header">
                              <div>                    
                                 <h3>Avaiable Discounts</h3>
                              </div>
                              <div>
                                 <a href="#">View All</a>
                              </div>
                           </div>                        
                        </div>   
                        <div class="col-md-12 col-12">
                           <div class="cb-deals-dicount-head">
                              <div class="cb-deals-dicount-offer">
                                 <h5>Flat 15% off</h5>
                                 <p>Exp : Mar 12 2023</p>
                              </div>
                              <div class="cb-deals-dicount-code">
                                 <p>Coupon code:</p>
                                 <h5>PSNEW15</h5>
                              </div>
                           </div>
                           <div class="cb-deals-dicount-head">
                              <div class="cb-deals-dicount-offer">
                                 <h5>Flat 20% off</h5>
                                 <p>Exp : Mar 12 2023</p>
                              </div>
                              <div class="cb-deals-dicount-code">
                                 <p>Coupon code:</p>
                                 <h5>PSNEW20</h5>
                              </div>
                           </div>
                           <div class="cb-deals-dicount-head">
                              <div class="cb-deals-dicount-offer">
                                 <h5>Flat 10% off</h5>
                                 <p>Exp : Mar 12 2023</p>
                              </div>
                              <div class="cb-deals-dicount-code">
                                 <p>Coupon code:</p>
                                 <h5>PSNEW10</h5>
                              </div>
                           </div>
                        </div>
                     </div>                
                  </div>             
               </div>
               <div class="col-lg-8">
                  <div class="card-dashboard">
                     <div class="row">   
                        <div class="col-md-12 col-12">
                           <div class="dashboard-header">
                              <div>                    
                                 <h3>Recent Orders</h3>
                              </div>
                              <div>
                                 <a href="#">View All</a>
                              </div>
                           </div>                        
                        </div>                      
                        <div class="col-md-12 col-12">
                           <div class="table-responsive">
                              <table class="table table-hover">
                                 <thead>
                                    <tr>
                                       <th>Customer Name</th>
                                       <th>City</th>
                                       <th>Order Date</th>
                                       <th>Discount</th>
                                       <th>Total Amount</th>
                                       <th>Status</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>    
                                       <td><p>RK</p>Ranganath krishna</td>
                                       <td>Basavanagudi</td>
                                       <td>24-03-2023</td>
                                       <td>₹ 500.00</td>
                                       <td>₹ 56,500.00</td>
                                       <td class="active">Success</td>
                                   </tr>
                                    <tr>    
                                       <td><p>SG</p>Satish Gangaiah</td>
                                       <td>Jayanagar</td>
                                       <td>19-03-2023</td>
                                       <td>₹ 760.00</td>
                                       <td>₹ 42,800.00</td>
                                       <td class="pending">Pending</td>
                                    </tr>
                                    <tr>    
                                       <td><p>PN</p>Praveen N</td>
                                       <td>Koramangala</td>
                                       <td>16-03-2023</td>
                                       <td>₹ 950.00</td>
                                       <td>₹ 67,320.00</td>
                                       <td class="cancel">Cancel</td>
                                   </tr>                
                                 </tbody>
                              </table>
                           </div>
                        </div>       
                     </div>                
                  </div>             
               </div>               
            </div>

            <div class="row">
               <div class="col-lg-6">
                  <div class="card-dashboard">
                     <div class="row"> 
                        <div class="col-md-12 col-12">
                           <div class="dashboard-header mb10-db">
                              <div>                    
                                 <h3>Affiliates Program</h3>
                              </div>
                              <div>
                                 <a href="#">Manage</a>
                              </div>
                           </div>  
                           <p class="affilate-para">(Affiliate Commission 10% Buyer Discount 15%)</p>                      
                        </div>   
                        <div class="col-md-12 col-12">    
                           <div class="row bdr-btm">                 
                              <div class="col-md-6 col-6">
                                 <div class="dashboard-widget">
                                    <h5>153</h5>
                                    <span>No of Affiliates</span>
                                 </div>
                              </div>  
                              <div class="col-md-6 col-6">
                                 <div class="dashboard-widget dashboard-widget-bdr">
                                    <h5>2,450</h5>
                                    <span>No of Shares</span>
                                 </div>
                              </div>       
                           </div>  
                           
                           <div class="row bdr-btm1">                  
                              <div class="col-md-6 col-6">
                                 <div class="dashboard-widget">
                                    <h4>7,875</h4>
                                    <span>Total Orders</span>
                                 </div>
                              </div>  
                              <div class="col-md-6 col-6">
                                 <div class="dashboard-widget dashboard-widget-bdr">
                                    <h4>₹ 31,278</h4>
                                    <span>Total Sales</span>
                                 </div>
                              </div>       
                           </div>     
                        </div> 
                     </div>                
                  </div>             
               </div>
               <div class="col-lg-6">
                  <div class="card-dashboard">
                     <div class="row">   
                        <div class="col-md-12 12 col-12">
                           <div class="dashboard-header">
                              <div>                    
                                 <h3>Supply Chain Fintech</h3>
                              </div>
                              <div>
                                 <a href="#">Manage</a>
                              </div>
                           </div>                        
                        </div> 
                        <div class="col-md-7 col-12">    
                           <div class="row bdr-btm">                 
                              <div class="col-md-12 col-12">
                                 <div class="dashboard-widget dashboard-widget-bdr db-ft-10">
                                    <h5>630</h5>
                                    <span>Number of Orders</span>
                                 </div>
                              </div>       
                           </div>                             
                           <div class="row bdr-btm1 bdr-btm2">                  
                              <div class="col-md-6 col-6">
                                 <div class="dashboard-widget">
                                    <h4>₹ 3,800</h4>
                                    <span>Total Sales</span>
                                 </div>
                              </div>  
                              <div class="col-md-6 col-6">
                                 <div class="dashboard-widget dashboard-widget-bdr">
                                    <h5>378</h5>
                                    <span>Ava Order Value</span>
                                 </div>
                              </div>       
                           </div>     
                        </div>                       
                        <div class="col-md-5 col-12 mt-db-20">
                           <table id="q-graph">
                              <tbody>
                                 <tr class="qtr" id="q1">
                                    <td class="sent bar h-4"></td>
                                    <td class="paid bar h-4"></td>
                                 </tr>
                                 <tr class="qtr" id="q2">
                                    <td class="sent bar h-2"></td>
                                    <td class="paid bar h-2"></td>
                                 </tr>
                                 <tr class="qtr" id="q3">
                                    <td class="sent bar h-3"></td>
                                    <td class="paid bar h-3"></td>
                                 </tr>
                              </tbody>
                           </table>
                           <div id="ticks">
                              <div class="tick  h-2" ><p></p></div>
                              <div class="tick  h-2" ><p>4k</p></div>
                              <div class="tick  h-2" ><p>3k</p></div>
                              <div class="tick  h-2" ><p>2k</p></div>
                              <div class="tick  h-1" ><p>1k</p></div>
                           </div>    
                        </div>  
                          
                     </div>                
                  </div>             
               </div>               
            </div> 

            <div class="row">
               <div class="col-lg-6">
                  <div class="card-dashboard-1">
                     <div class="row"> 
                        <div class="col-md-6 col-12">
                           <img src="./assets/images/dh-aff.jpg" alt=""/>
                        </div>
                        <div class="col-md-6 col-12 aff-fintech-center">
                           <div class="aff-fintech-content">
                              <h6>New market reach through <br/><strong>Influencers & Affiliates</strong></h6>
                              <div class="aff-fintech-inner">
                                 <p><span></span>Increased customer reach</p>
                                 <p><span></span>More brand Visibility</p>
                                 <p><span></span>Success fee based model</p>
                              </div>
                              <a href='#'>Click here </a>
                           </div>
                        </div>                    
                     </div>  
                  </div>                
               </div>      
               <div class="col-lg-6">
                  <div class="card-dashboard-1">
                     <div class="row">                        
                        <div class="col-md-6 col-12 aff-fintech-center-1">
                           <div class="aff-fintech-content">
                              <h6>Increase <strong>B2B Supply</strong> Chain <br/>Efficiency to reach All India</h6>
                              <div class="aff-fintech-inner">
                                 <p><span></span>Deep shipment integration</p>
                                 <p><span></span>Payments upon delivery, Guaranteed</p>
                                 <p><span></span>Build a direct dealer network</p>
                              </div>
                              <a href='#'>Click here </a>
                           </div>
                        </div> 
                        <div class="col-md-6 col-12">
                           <img src="./assets/images/dh-fintech.jpg" alt=""/>
                        </div>                   
                     </div>  
                  </div>                
               </div>                                
            </div>
         </section>
      </div>
   </div>

 </>
  ); 
} 
export default DashboardSideBarMenu;