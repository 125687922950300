
import React, { Component, useEffect } from 'react';
import HeaderMenu from '../../components/HeaderMenu';
import Footer from '../../components/Footer';
import Banner from './Banner';
import JustECommerce from './JustECommerce';
import Webstore from './Webstore';
import SellVarietyProducts from './SellVarietyProducts'; 
import GetCostBoApp from './GetCostBoApp';
import WeChargeLess from './WeChargeLess';
import GrowYourBusiness from './GrowYourBusiness';
import InfluencersAffiliates from './InfluencersAffiliates'; 
import PoweredByCostBo from './PoweredByCostBo'; 
import Iframe from './Iframe';
import HassleFreeB2BSales from './HassleFreeB2BSales';
import ProductSales from './ProductSales';
import OurVision from './OurVision';
import ProductHighlight from './ProductHighlight ';

function  HomeMenu() {  
	return(   
	<>

	<HeaderMenu />
	<div class="header-sidebar-menu">
            <div class="header-sidebar-menu-inner">                        
                <div class="simplebar-content">
                    <ul class="side_links">							
                        <li><a href='#'>Hassle Free B2B Sales</a></li>
                        <li><a href='/BusinessListing'>Business Dashboard</a></li>
                        <li><a href='#'>Pricing</a></li>
                        <li><a href='#'>Get CostBo App</a></li>
                       
                    </ul>                              
                    
                </div>
			</div>
		</div>
	
        <a href="#" class="header-sidebar-menu-overlay"></a>
	{/* <Iframe /> */}
	<Banner />
	<OurVision/>
	{/* <ProductSales/> */}
	{/* <ProductHighlight/> */}
	{/* <JustECommerce /> */}
	
	<SellVarietyProducts/>
	<WeChargeLess />	
	<HassleFreeB2BSales/>	
	<InfluencersAffiliates />
	{/* <Webstore/> */}
	<GetCostBoApp/>
	<GrowYourBusiness />
	<PoweredByCostBo />
	<Footer/>

	</>
);
}
export default HomeMenu;




