import React from 'react';
function SignIn() {
return( 
<>
<div class="account-content">
   <div class="login-wrapper">
      <div class="login-left-sidebar">
         <div class="login-sidebar-logo">
            <img src="./assets/images/logo.png" alt="img"/>        
            <h3>Welcome to CostBo</h3>
            <h4>Your trusted Social Shopping platform</h4>
         </div>
         <div class="login-sidebar-tab">
            <a href='/SignIn'>
               <div class="login-sidebar-tab-list active">
                 <div class="tab-icon">
                     <div class="tab-icon-count"><span class="material-icons-outlined">person</span></div>
                  </div>
                  <div class="tab-icon-count-spacer"></div>
                  <div class="login-tab-description">
                     <h3>Login</h3>
                     <p>Login with your Costbo account</p>
                  </div>
               </div>
            </a>
            <a href='/SignUp'>
               <div class="login-sidebar-tab-list">
                  <div class="tab-icon">
                     <div class="tab-icon-count"><span class="material-icons-outlined">person_add</span></div>
                  </div>
                  <div class="tab-icon-count-spacer"></div>
                  <div class="login-tab-description">
                     <h3>Create an Account</h3>
                     <p>Sign up with your mobile number</p>
                  </div>
               </div>
            </a>
            <a href='/ForgetPassword'>
               <div class="login-sidebar-tab-list">
                  <div class="tab-icon">
                    <div class="tab-icon-count"><span class="material-icons-outlined">key</span></div>
                  </div>
                  <div class="tab-icon-count-spacer"></div>
                  <div class="login-tab-description">
                     <h3>Forgot your password</h3>
                     <p>We’ll help you reset your password.</p>
                  </div>
               </div>
            </a>
            {/* 
            <div class="login-bg"><img src="./assets/images/login-bg.png" /></div>
            */}
         </div>
      </div>
      <div class="login-content">
         <div class="login-userset">
            <div class="login-userheading">
               <h3>Please use your registered CostBo account</h3>
            </div>
            <div class="form-login">
               <label>Phone Number</label>
               <div class="form-addons">
                  <input type="text" placeholder="+91  Enter Phone Number"/>
                  <span class="material-icons-outlined toggle-password">phone_iphone</span>
               </div>
            </div>
            <div class="form-login">
               <label>Password</label>
               <div class="pass-group">
                  <input type="password" class="pass-input" placeholder="Enter your password"/>
                  <span class="fas toggle-password fa-eye"></span>
               </div>
            </div>
            <div class="form-remember-password">
               <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="remember-me"/>
                  <label for="remember-me" class="form-check-label">Remember Me</label>
               </div>
               <a href="/ForgetPassword" class="forgot-btn hover-a">Forgot Password?</a>
            </div>
            <div class="login-by-continuing">By continuing, you agree to CostBo's <a href="#" class="hover-a">Terms of Use</a> & <a href="#" class="hover-a">Privacy Policy</a></div>
            <div class="form-remember-password form-btn-mb">
               <div class="signinform">
                  <h4>New to CostBo?  <a href="/SignUp" class="hover-a">Create an Account</a>
                  </h4>
               </div>
               <div class="form-login">
                  <a class="btn btn-login" href="#">Sign In</a>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</>
);
}
export default SignIn;