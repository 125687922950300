import React, { Component, useEffect } from 'react';
function ResetPassword () {
return( 
<>

  <section class="section-login">
    <div class="login-user">
      <div class="login-modal-dialog">
        <a class="cb-login-close">
          <span class="material-icons-round">close</span>
        </a>
        <div class="cb-login-register">
          <div class="cb-login-register-header">
            <img src="./assets/images/logo.png" alt="Login" />
            <h3>Welcome to CostBo</h3>
            <p>Future ready, All in One D2C E-Commerce Infra</p>
          </div>
          <div class="cb-login-register-body">
            <h4>Reset Password</h4>
            <div class="reset-password">
              <p>Password reset key has been sent to</p>
              <span>vijiwebdesigner@gmail.com</span>

            </div>
            <form>
              <div class="form-group">
                <input type="password" class="form-control" placeholder="Reset Key From Your Email" />
                <span class="material-icons-round">vpn_key</span>
              </div>
              <div class="form-group">
                <input type="password" class="form-control" placeholder="Enter New Password" />
                <span class="material-icons-round">visibility</span>
              </div>
              <div class="cb-password-hint">
                <p><span></span>One Upper case character</p>
                <p><span></span>One Special character</p>
                <p><span></span>8 characters minimum</p>
                <p><span></span>One number</p>
              </div>              
              <div class="cb-mt-12"><p>By continuing, you agree to our <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a></p> </div>            
              <div class="cb-login-btn">
                <a href="/ExistingUser" class="login-btn">Reset & Login</a>
              </div>
            </form>
          </div>
          <div class="cb-login-register-footer">
            <h5>Trusted by 1000s of Brands</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
  
</>
);
}
export default ResetPassword;