import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import HomeSearch from "./pages/Home/HomeSearch";
import HowItWork from './pages/HowItWork'
import StepsToPromote from './pages/StepsToPromote'
import Loader from './pages/Loader'
import Loader1 from './pages/Loader1'
import SignIn from './pages/LoginSignup/SignIn'
import SignUp from './pages/LoginSignup/SignUp'
import OTP from './pages/LoginSignup/OTP'
import SignUpDetail from './pages/LoginSignup/SignUpDetail'
import ForgetPassword from './pages/LoginSignup/ForgetPassword'
import ForgetPasswordReset from './pages/LoginSignup/ForgetPasswordReset'
import GSTDetail from './pages/BusinessOnboarding/GSTDetail'
import AddressDetail from './pages/BusinessOnboarding/AddressDetail'
import StoreDetail from './pages/BusinessOnboarding/StoreDetail'

import BusinessListing from './pages/ChoosetheBusiness/BusinessListing'
import PendingApproval from './pages/ChoosetheBusiness/PendingApproval'
import Draft from './pages/ChoosetheBusiness/Draft'
import Rejected from './pages/ChoosetheBusiness/Rejected'
import ActiveEmpty from './pages/ChoosetheBusiness/ActiveEmpty'
import PendingEmpty from './pages/ChoosetheBusiness/PendingEmpty'
import DraftEmpty from './pages/ChoosetheBusiness/DraftEmpty'
import RejectedEmpty from './pages/ChoosetheBusiness/RejectedEmpty'
import ActiveBusiness from './pages/ChoosetheBusiness/OneBusiness/ActiveBusiness'
import PendingApprovalBusiness from './pages/ChoosetheBusiness/OneBusiness/PendingApprovalBusiness'
import RejectedBusiness from './pages/ChoosetheBusiness/OneBusiness/RejectedBusiness'


import DashboardSideBarMenu from './pages/Dashboard/DashboardSideBarMenu'
import Dashboard from './pages/Dashboard/Dashboard'
import StoreInfo from './pages/Dashboard/StoreInfo'
import StorePreview from './pages/Dashboard/StorePreview'
import StorePreviewPending from './pages/Dashboard/StorePreviewPending'
import StorePreviewNext from './pages/Dashboard/StorePreviewNext'
import StoreAllDataPreview from './pages/Dashboard/StoreAllDataPreview'
import PickupAddress from './pages/Dashboard/PickupAddress'
import BusinessTiming from './pages/Dashboard/BusinessTiming'
import DeliveryMethods from './pages/Dashboard/DeliveryMethods'
import DiscountDetails from './pages/Dashboard/DiscountDetails'
import BankDetail from './pages/Dashboard/BankDetail'
import OtherDetail from './pages/Dashboard/OtherDetail'

import StoreInfoEdit from './pages/Dashboard/EditStore/StoreInfoEdit'

import AddProduct from './pages/Catalog/AddProduct/AddProduct'
import Category from './pages/Catalog/AddProduct/Category'
import PriceTaxInfo from './pages/Catalog/AddProduct/PriceTaxInfo'
import MeasurementDeliveryMethod from './pages/Catalog/AddProduct/MeasurementDeliveryMethod'
import ProductAvailablity from './pages/Catalog/AddProduct/ProductAvailablity'
import SubscriptionInformation from './pages/Catalog/AddProduct/SubscriptionInformation'
import EditProduct from './pages/Catalog/EditProduct/EditProduct'
import EditCategory from './pages/Catalog/EditProduct/EditCategory'
import EditPriceTaxInfo from './pages/Catalog/EditProduct/EditPriceTaxInfo'
import EditMeasurementDeliveryMethod from './pages/Catalog/EditProduct/EditMeasurementDeliveryMethod'
import EditProductAvailablity from './pages/Catalog/EditProduct/EditProductAvailablity'

import ProductActive from './pages/Catalog/ProductList/ProductActive'
import ProductPendingApproval from './pages/Catalog/ProductList/ProductPendingApproval'
import ProductDraft from './pages/Catalog/ProductList/ProductDraft'
import ProductPaused from './pages/Catalog/ProductList/ProductPaused'
import ProductRejected from './pages/Catalog/ProductList/ProductRejected'
import ProductPreview from './pages/Catalog/ProductPreview'
import ProductDetail from './pages/Catalog/ProductDetail'

import Inventory from './pages/Inventory/Inventory'
import PausedStock from './pages/Inventory/PausedStock'
import OutofStock from './pages/Inventory/OutofStock'
import LowStock from './pages/Inventory/LowStock'
import PriceUpdate from './pages/Inventory/PriceUpdate'
import Empty from './pages/Inventory/Empty'

import Pending from './pages/ManageOrder/Pending'
import ReadytoShip from './pages/ManageOrder/ReadytoShip'
import InTransit from './pages/ManageOrder/InTransit'
import Delivered from './pages/ManageOrder/Delivered'
import Cancelled from './pages/ManageOrder/Cancelled'
import AllOrders from './pages/ManageOrder/AllOrders'

import Login from './pages/LoginRegister/Login'
import ExistingUser from './pages/LoginRegister/ExistingUser'
import ResetPassword from './pages/LoginRegister/ResetPassword'
import GuestUser from './pages/LoginRegister/GuestUser'
import RegisterDetail from './pages/LoginRegister/RegisterDetail'

import Affiliates from './pages/Affiliates'
import HomeSlideOne from "./pages/Home/HomeSlideOne";
import HomeSlideTwo from "./pages/Home/HomeSlideTwo";
import HomeSlideThree from "./pages/Home/HomeSlideThree";
import HomeSlideFour from "./pages/Home/HomeSlideFour";
import HomeSlideFive from "./pages/Home/HomeSlideFive";
import HomeSlideSix from "./pages/Home/HomeSlideSix";

import SideBarMobile from "./components/SideBarMobile";
import HomeMenu from "./pages/Home/HomeMenu";
import DashboardSideBar from "./components/DashboardSideBar";

import CouponsDiscount from "./pages/Discount/CouponsDiscount";
import AddCoupon from "./pages/Discount/AddCoupon";
import PreferredDiscounts from "./pages/Discount/PreferredDiscounts";
import AddDiscount from "./pages/Discount/AddDiscount";
import PreferredDiscountsEmpty from "./pages/Discount/PreferredDiscountsEmpty";
import CouponsDiscountEmpty from "./pages/Discount/CouponsDiscountEmpty";



import Paycart from "./pages/Paycart/Paycart";


const routes = (
  <Router>    
    <Switch>
      <Route path='/' exact  component={Home} />  
      <Route path='/HomeSearch' exact  component={HomeSearch} />  
      <Route path='/HomeMenu' exact  component={HomeMenu} />  
      <Route path='/HomeSlideOne' exact  component={HomeSlideOne} />  
      <Route path='/HomeSlideTwo' exact  component={HomeSlideTwo} />  
      <Route path='/HomeSlideThree' exact  component={HomeSlideThree} />  
      <Route path='/HomeSlideFour' exact  component={HomeSlideFour} />  
      <Route path='/HomeSlideFive' exact  component={HomeSlideFive} />  
      <Route path='/HomeSlideSix' exact  component={HomeSlideSix} />  
      <Route path='/HowItWork' exact  component={HowItWork} />
      <Route path='/Loader' exact  component={Loader} /> 
      <Route path='/Loader1' exact  component={Loader1} /> 

      <Route path='/StepsToPromote' exact  component={StepsToPromote} /> 

      <Route path='/SignIn' exact  component={SignIn} />
      <Route path='/SignUp' exact  component={SignUp} />
      <Route path='/OTP' exact  component={OTP} />
      <Route path='/SignUpDetail' exact  component={SignUpDetail} />
      <Route path='/ForgetPassword' exact  component={ForgetPassword} />
      <Route path='/ForgetPasswordReset' exact  component={ForgetPasswordReset} />
      <Route path='/GSTDetail' exact  component={GSTDetail} />
      <Route path='/AddressDetail' exact  component={AddressDetail} />
      <Route path='/StoreDetail' exact  component={StoreDetail} />
      
      <Route path='/BusinessListing' exact  component={BusinessListing} />
      <Route path='/PendingApproval' exact  component={PendingApproval} />
      <Route path='/Draft' exact  component={Draft} />
      <Route path='/Rejected' exact  component={Rejected} />
      <Route path='/ActiveEmpty' exact  component={ActiveEmpty} />
      <Route path='/PendingEmpty' exact  component={PendingEmpty} />
      <Route path='/DraftEmpty' exact  component={DraftEmpty} />
      <Route path='/RejectedEmpty' exact  component={RejectedEmpty} />
      <Route path='/ActiveBusiness' exact  component={ActiveBusiness} />
      <Route path='/PendingApprovalBusiness' exact  component={PendingApprovalBusiness} />
      <Route path='/RejectedBusiness' exact  component={RejectedBusiness} /> 
      
      <Route path='/DashboardSideBarMenu' exact  component={DashboardSideBarMenu} />
      <Route path='/Dashboard' exact  component={Dashboard} />
      <Route path='/StoreInfo' exact  component={StoreInfo} />
      <Route path='/StoreInfoEdit' exact  component={StoreInfoEdit} />
      <Route path='/StorePreview' exact  component={StorePreview} />
      <Route path='/StorePreviewPending' exact  component={StorePreviewPending} />
      <Route path='/StorePreviewNext' exact  component={StorePreviewNext} />
      <Route path='/StoreAllDataPreview' exact  component={StoreAllDataPreview} />
      <Route path='/PickupAddress' exact  component={PickupAddress} />
      <Route path='/BusinessTiming' exact  component={BusinessTiming} />
      <Route path='/DeliveryMethods' exact  component={DeliveryMethods} />
      <Route path='/DiscountDetails' exact  component={DiscountDetails} />
      <Route path='/BankDetail' exact  component={BankDetail} />
      <Route path='/OtherDetail' exact  component={OtherDetail} />

      <Route path='/AddProduct' exact  component={AddProduct} />
      <Route path='/Category' exact  component={Category} />
      <Route path='/PriceTaxInfo' exact  component={PriceTaxInfo} />
      <Route path='/MeasurementDeliveryMethod' exact  component={MeasurementDeliveryMethod} />
      <Route path='/ProductAvailablity' exact  component={ProductAvailablity} />
      <Route path='/SubscriptionInformation' exact  component={SubscriptionInformation} />
      
      <Route path='/EditProduct' exact  component={EditProduct} />
      <Route path='/EditCategory' exact  component={EditCategory} />
      <Route path='/EditPriceTaxInfo' exact  component={EditPriceTaxInfo} />
      <Route path='/EditMeasurementDeliveryMethod' exact  component={EditMeasurementDeliveryMethod} />
      <Route path='/EditProductAvailablity' exact  component={EditProductAvailablity} />
      <Route path='/ProductActive' exact  component={ProductActive} />
      <Route path='/ProductPendingApproval' exact  component={ProductPendingApproval} />
      <Route path='/ProductDraft' exact  component={ProductDraft} />
      <Route path='/ProductPaused' exact  component={ProductPaused} />
      <Route path='/ProductRejected' exact  component={ProductRejected} />
      <Route path='/ProductPreview' exact  component={ProductPreview} />
      <Route path='/ProductDetail' exact  component={ProductDetail} />

      <Route path='/Inventory' exact  component={Inventory} />
      <Route path='/PausedStock' exact  component={PausedStock} />
      <Route path='/OutofStock' exact  component={OutofStock} />
      <Route path='/LowStock' exact  component={LowStock} />
      <Route path='/PriceUpdate' exact  component={PriceUpdate} />
      <Route path='/Empty' exact  component={Empty} />

      <Route path='/Pending' exact  component={Pending} />
      <Route path='/ReadytoShip' exact  component={ReadytoShip} />
      <Route path='/InTransit' exact  component={InTransit} />
      <Route path='/Delivered' exact  component={Delivered} />
      <Route path='/Cancelled' exact  component={Cancelled} />
      <Route path='/AllOrders' exact  component={AllOrders} />

      <Route path='/Login' exact  component={Login } />
      <Route path='/ResetPassword' exact  component={ResetPassword} />
      <Route path='/ExistingUser' exact  component={ExistingUser} />
      <Route path='/GuestUser' exact  component={GuestUser} />
      <Route path='/RegisterDetail' exact  component={RegisterDetail} />

      <Route path='/Affiliates' exact  component={Affiliates} />
      
      <Route path='/SideBarMobile'  component={SideBarMobile} />
      <Route path='/DashboardSideBar'  component={DashboardSideBar} />

      <Route path='/CouponsDiscount'  component={CouponsDiscount} />
      <Route path='/AddCoupon'  component={AddCoupon} />
      <Route path='/PreferredDiscounts'  component={PreferredDiscounts} />
      <Route path='/AddDiscount'  component={AddDiscount} />
      <Route path='/CouponsDiscountEmpty'  component={CouponsDiscountEmpty} />
      <Route path='/PreferredDiscountsEmpty'  component={PreferredDiscountsEmpty} />

      <Route path='/Paycart'  component={Paycart} />

      
      
    </Switch>
 </Router> )

function App() {
  return (
    <div className="main-wrapper">
        {routes}  
    </div>
  );
}

export default App;
