import React, { Component, useEffect } from 'react';
import Header from '../Dashboard/Header';
import SideBar from '../../components/SideBar';
function AddCoupon () {
return( 
<>

<div class="cb-discount-modal-dialog">
   <a href="/CouponsDiscount" class="cb-discount-close"><span class="material-icons-round">close</span></a>
   <div class="cb-discount-model-content">
      <div class="cb-discount-model-content-header">
         <h3>New Coupon/Promo Code</h3>
         {/* <p>Assign Discount to Specific customer</p> */}
      </div>
      <div class="cb-discount-model-content-body">
         <form>
            <div class="row">
              <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label for="input" class="input-label">New Promo Code<span>*</span></label>
                    <input type="text" name="" value="Ex:DIWALIOFFER25"/>
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label for="input" class="input-label">Discount Percentage(%)<span>*</span></label>
                    <input type="text" name="" value="25%"/>
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-sm-6 col-12">
                  <div class="form-group">
                    <label for="input" class="input-label">Valid Start Date<span>*</span></label>
                    <input type="text" name="" value="Feb 01, 2022"/>
                  </div>
              </div>
              <div class="col-lg-6 col-sm-6 col-12">
                  <div class="form-group">
                    <label for="input" class="input-label">Valid End Date<span>*</span></label>
                    <input type="text" name="" value="Nov 21, 2024"/>
                  </div>
              </div>
            </div>
            <div class="cb-discount-model-btn"><a href="/CouponsDiscount" class="login-btn">Submit</a></div>
         </form>
      </div>
   </div>
</div>
  <a href="#" class="cb-discount-overlay"></a>
  <Header />     
   <SideBar />

   <div class="page-wrapper">
         <div class="content">    
            <div class="row">
               <div class="col-lg-5 col-sm-6 col-12">
                  <div class="cb-business-header">
                     <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg" alt='Logo'/>
                     <h6>Plant Science</h6>
                  </div>
               </div> 
               <div class="col-lg-7 col-sm-6 col-12">
                  <div class="dash-widget">
                     <div class="dash-widget-content">
                        <div class="dash-widgetimg">
                        <span class="material-icons-outlined">discount</span>
                        </div>
                        <div class="dash-widgetcontent">
                           <h6>Total Discount</h6>
                           <h5>618</h5>
                        </div>
                     </div>
                     <div class="dash-widget-content">
                        <div class="dash-widgetimg">
                           <span class="material-icons-outlined">local_offer</span>
                        </div>
                        <div class="dash-widgetcontent">
                           <h6>Active Discount</h6>
                           <h5>572</h5>
                        </div>
                     </div>
                     <div class="dash-widget-content">
                        <div class="dash-widgetimg">
                           <span class="material-icons-outlined">cancel_presentation</span>
                        </div>
                        <div class="dash-widgetcontent">
                           <h6>Expired Discount</h6>
                           <h5>46</h5>
                        </div>
                     </div>
                     
                  </div>
               </div>
            </div>
            <div class="row">               
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="card-p-10">
                     <div class="row product-header">                   
                        <div class="col-md-12">     
                           <div class="card-header content-header">
                              <div class="col-md-6 col-8 hd-coupons">                  
                                 <h4>Coupons/Promo Code</h4>
                                 <div class="legend-1">
                                    <div class="bars-notification-1"> <div class="bars-1 bars-active"></div> <h6>Active</h6></div>
                                    <div class="bars-notification-1"> <div class="bars-1 bars-inactive"></div> <h6>Expired</h6></div>
                                 </div> 
                              </div>
                              <div class="col-md-6 col-4 hd-coupons-1">
                                 <div class="form-group form-group-inventory cb-mb-none">
                                    <input type="text" placeholder="Customer Phone Number" class="search-mt-20" id="inputFname"/>
                                    <i class="fas fa-search inventory-search-icon"></i>
                                 </div>
                                 <a href="/AddCoupon" class="btn btn-add-business">
                                 <span class="material-icons-outlined">add</span> <div class="text-mb">New Coupon</div></a>
                              </div>
                           </div>                             
                        </div>
                     </div>                     
                  </div>  
               </div>
            </div>
            <div class="row">
               <div class="col-lg-3 p-r-0">
						<div class="cb-coupons-promo-dicount-box cb-promo-active">
							<div class="cb-coupons-promo-dicount-head">
								<div class="cb-coupons-promo-dicount-offer">
									<h5>Flat 25% off</h5>
									<p>Exp : Mar 12 2023</p>
								</div>
								<div class="cb-coupons-promo-dicount-code">
									<p>Coupon code:</p>
									<h5>AKVHEALTHY</h5>
								</div>
							</div>	
							<div class="cb-coupons-promo-bottom-category">
								<a href="#">Used by 12 customers</a>
							</div>
						</div>
					</div>
               <div class="col-lg-3 p-r-0">
						<div class="cb-coupons-promo-dicount-box cb-promo-expired">
							<div class="cb-coupons-promo-dicount-head">
								<div class="cb-coupons-promo-dicount-offer">
									<h5>Flat 16% off</h5>
									<p>Exp : Mar 12 2023</p>
								</div>
								<div class="cb-coupons-promo-dicount-code">
									<p>Coupon code:</p>
									<h5>16HEALTHY</h5>
								</div>
							</div>	
							<div class="cb-coupons-promo-bottom-category">
								<a href="#">Used by 25 customers</a>
							</div>
						</div>
					</div>
               <div class="col-lg-3 p-r-0">
						<div class="cb-coupons-promo-dicount-box cb-promo-active">
							<div class="cb-coupons-promo-dicount-head">
								<div class="cb-coupons-promo-dicount-offer">
									<h5>Flat 5% off</h5>
									<p>Exp : Apr 12 2023</p>
								</div>
								<div class="cb-coupons-promo-dicount-code">
									<p>Coupon code:</p>
									<h5>NEWHEALTHY</h5>
								</div>
							</div>	
							<div class="cb-coupons-promo-bottom-category">
								<a href="#">Used by 16 customers</a>
							</div>
						</div>
					</div>
               <div class="col-lg-3">
						<div class="cb-coupons-promo-dicount-box cb-promo-expired">
							<div class="cb-coupons-promo-dicount-head">
								<div class="cb-coupons-promo-dicount-offer">
									<h5>Flat 20% off</h5>
									<p>Exp : Mar 12 2023</p>
								</div>
								<div class="cb-coupons-promo-dicount-code">
									<p>Coupon code:</p>
									<h5>VHEALTHY</h5>
								</div>
							</div>	
							<div class="cb-coupons-promo-bottom-category">
								<a href="#">Used by 12 customers</a>
							</div>
						</div>
					</div>
            
            </div>

         </div>
      </div>
</>
);
}
export default AddCoupon;