import React from 'react'; 
import Header from '../Header';
import SideBar from '../../../components/SideBar';

function AddProduct() 
{ 
return(
 <>
   <Header />     
   <SideBar />

   <div class="page-wrapper">
      <div class="content">
            <div class="row">
               <div class="col-lg-12 col-sm-12 col-12 mb-10">
                  <div class="cb-business-header">
                     <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg" alt='Logo'/>
                     <h6>Plant Science</h6>
                  </div>
               </div> 
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="card">
                     <div class="page-title-process">
                        <div class="card-header cb-display-flex">                     
                           <h4>Add Product</h4>
                        </div>
                     </div>
                     <div class="card-body">
                        <div class="row">                            
                           <div class="col-lg-12 col-sm-12 col-12">
                              <div class="form-group">
                                 <label>Product Name <span>*</span></label>
                                 <input type="text" placeholder='Enter Product Name'/>
                              </div>
                           </div>                                                    
                           <div class="col-lg-4 col-sm-4 col-12">
                              <div class="form-group">
                                 <label>Brand</label>
                                 <input type="text" placeholder='Enter Brand'/>
                              </div>
                           </div>    
                           <div class="col-lg-4 col-sm-4 col-12">
                              <div class="form-group">
                                 <label>Country of Origin <span>*</span></label>
                                 <select class="select">
                                    <option>India</option>
                                    <option>Algeria </option>
                                    <option>American Samoa </option>
                                    <option>Colombia</option>
                                    <option>Egypt</option>
                                 </select>
                              </div>
                           </div>
                           <div class="col-lg-4 col-sm-4 col-12">
                              <div class="form-group">
                                 <label>SKU ID</label>
                                 <input type="text" placeholder='Enter SKU ID'/>
                              </div>
                           </div>                                               
                           <div class="col-lg-12">
                              <div class="form-group">
                                 <label>Product Description <span>*</span></label>
                                 <textarea class="form-control" placeholder='Enter Your Product Description'></textarea>
                              </div>
                           </div> 
                           <div class="col-lg-12 col-sm-12 col-12 product-image-upload-max">
                              <h6>Upload Product Image (Upto Max 4) <span>*</span></h6>
                           </div>     
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <div class="image-upload">
                                    <input type="file" />
                                    <div class="image-uploads">
                                       <img src="./assets/images/dashboard/upload.svg" alt="img"/>
                                       <h4>Upload Picture</h4>
                                       <span>100 x 75 pixels <br/>(Square 4:3 , size : 20 - 100 Kb)</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <div class="image-upload">
                                    <input type="file" />
                                    <div class="image-uploads">
                                       <img src="./assets/images/dashboard/upload.svg" alt="img"/>
                                       <h4>Upload Picture</h4>
                                       <span>100 x 75 pixels <br/>(Square 4:3 , size : 20 - 100 Kb)</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <div class="image-upload">
                                    <input type="file" />
                                    <div class="image-uploads">
                                       <img src="./assets/images/dashboard/upload.svg" alt="img"/>
                                       <h4>Upload Picture</h4>
                                       <span>100 x 75 pixels <br/>(Square 4:3 , size : 20 - 100 Kb)</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <div class="image-upload">
                                    <input type="file" />
                                    <div class="image-uploads">
                                       <img src="./assets/images/dashboard/upload.svg" alt="img"/>
                                       <h4>Upload Picture</h4>
                                       <span>100 x 75 pixels <br/>(Square 4:3 , size : 20 - 100 Kb)</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-lg-4 col-sm-4 col-4">
                              {/* <a href="#" class="btn btn-back">Go Back</a> */}
                           </div>
                           <div class="col-lg-8 col-sm-12 col-12 cb-txet-right">
                           {/* <a href="/ProductDraft" class="btn btn-submit">Save</a> */}
                           <a href="/Category" class="btn btn-next cb-ml-4">Next</a>
                           </div>
                        </div>
                     </div>
                  </div>
            </div>
            
         </div>
      </div>
   </div>

 </>
  ); 
} 
export default AddProduct;