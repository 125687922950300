import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Slide,
  Zoom,
  Grid,
} from "@mui/material";
import { bounce } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { useTrail, animated as a, useSpring } from "react-spring";
function JustECommerce() {
  const [toggle, set] = useState(true);
  const config = { mass: 5, tension: 2000, friction: 200 };
  const styles = useSpring({
    loop: true,
    to: [
      { opacity: 1, color: "#ffaaee" },
      { opacity: 0, color: "rgb(14,26,19)" },
    ],
    from: { opacity: 0, color: "red" },
  });

  const items = [
    "Free AI powered, Managed, Zero Maintenance Webstores",
    "Brand discovery through CostBo Marketplace & SEO",
    "Social Commerce powered by Customer Network",
    "Integrated Influencers & Resellers network",
  ];
  const topItems = [
    "Always changing technology & hard to maintain",
    "No visibility, No traffic and Poor SEO",
    "No access to customer network",
    "No integrated marketing channels",
  ];

  const trail = useTrail(items.length, {
    config,
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,

    from: { opacity: 0, x: 20, height: 0 },
  });

  const trailData = useTrail(topItems.length, {
    config,
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 40,

    from: { opacity: 0, x: 40, height: 0 },
  });

  return (
    <Box>
      <Box class="cb-just-ecommerce cb_pd_20">
        <Box class="container">
          <Box class="sec-title text-center mb-20 mt-20">
            <h6>Your current Website</h6>
          </Box>

          <Box class="row wow bounceIn animated">
            <Box class="col-lg-3 col-md-6">
              <Box class="card-box">
                <Box class="card-content">
                  <p>Always changing technology & hard to maintain</p>
                </Box>
              </Box>
            </Box>
            <Box class="col-lg-3 col-md-6">
              <Box class="card-box">
                <Box class="card-content">
                  <p>No visibility, No traffic and Poor SEO</p>
                </Box>
              </Box>
            </Box>
            <div class="col-lg-3 col-md-6">
              <div class="card-box">
                <div class="card-content">
                  <p>No access to customer network</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="card-box">
                <div class="card-content">
                  <p>No integrated marketing channels</p>
                </div>
              </div>
            </div>
          </Box>
          <div class="sec-title text-center mb-20 mt-20 wow bounceIn animated">
            <h6>
              Transformed in <a>Just in 10 minutes</a> <p class="cb-fr-1">as super <a> WEBSTORE</a></p>  Powered by CostBo
            </h6>
          </div>
          <Box
            display="flex"
            flexDirection="row"
            maxWidth="100%"
            sx={{ flexGrow: 1 }}
          >
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 1, sm: 0, md: 12 }}
            >
              {trail.map(({ x, height, ...rest }, index) => (
                <Grid item xs={3}>
                  <a.div
                    key={items[index]}
                    // style={{
                    //   ...rest,
                    //   transform: x.interpolate(
                    //     (x) => `translate3d(0,${x}px,0)`
                    //   ),
                    // }}
                  >
                    <Box>
                      <Box class="card-box-1 wow bounceIn animated">
                        <div class="card-content-1">
                          <Box>
                            <p>{items[index]}</p>
                          </Box>
                        </div>
                      </Box>
                    </Box>
                  </a.div>
                </Grid>
              ))}
            </Grid>
          </Box>

          
          <div class="sec-title text-center mb-20 mt-20">
            <h4>Not Just E-Commerce, It's Connected Social Commerce</h4>
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default JustECommerce;
