import React from 'react';
function HassleFreeB2BSales () {
return(
   <>
    <div class="hassle-B2B-sales cb_pd_20" id="HassleFreeB2BSales">
        <div class="container">
            <div class="sec-title sec-title-fees text-center mb-20 mt-20 wow bounceIn animated" data-wow-duration="1s" data-wow-delay="0.2s">                        
                <span>Realize profits immediately</span>
                <h4>Receive payments instantly  <br/> on your B2B sales</h4>                          
            </div>

            <div class="row wow bounceIn animated B2B-sales-center">                
                <div class="col-lg-6 wow fadeInLeft animated" data-wow-duration="1s" data-wow-delay="0.2s" >
                    <div class="B2B-sales mb-view">
                        <img src="../assets/images/HassleFreeB2BSales1.svg" alt="A future ready FREE Webstore powered by Social Recommendation Engine" />
                    </div>
                    <div class="webstore_content B2B-sales-mt-50">    
                        <h6>No more extending credit for your distributors/retailers</h6>                                    
                        <p><span></span>Power your brand website with integrated lending infrastructure through approved banking partners.</p>         
                        <p><span></span>Your customers can avail digital revolving credit line within minutes! </p>    
                    </div>                           
                </div>  
                <div class="col-lg-6">
                    <div class="B2B-sales lt-view wow fadeInRight animated" data-wow-duration="1s" data-wow-delay="0.2s">
                        <img src="../assets/images/HassleFreeB2BSales1.svg" alt="A future ready FREE Webstore powered by Social Recommendation Engine" />
                    </div>
                </div>                   
            </div> 


        </div>
      </div>      
   </>
);
}

export default HassleFreeB2BSales;