import React, { Component } from "react";
import Slider from 'react-slick/lib/slider';



export default class CenterMode extends Component {
  render() {
    const settings = {
     
      centerMode: true,
      centerPadding: 10,
      dots: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      speed: 500,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true,
            centerMode: false,
            centerPadding: 50,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <img src="../assets/images/place_order/1.jpg" alt="Place order for your customers" />
          </div>
          <div>
            <img src="../assets/images/place_order/2.jpg" alt="Place order for your customers" />
            {/* <h6>Select products from catalog</h6> */}
          </div>
          <div>
            <img src="../assets/images/place_order/3.jpg" alt="Place order for your customers" />
          </div>
          <div>
            <img src="../assets/images/place_order/4.jpg" alt="Place order for your customers" />
          </div>
          <div>
            <img src="../assets/images/place_order/5.jpg" alt="Place order for your customers" />
          </div>
          
          
        </Slider>
      </div>
    );
    }
}