import React from 'react';
function SignUpDetail() {
return( 
<>
<div class="account-content">
   <div class="login-wrapper">
      <div class="login-left-sidebar">
         <div class="login-sidebar-logo">
            <img src="./assets/images/logo.png" alt="img"/>        
            <h3>Welcome to CostBo</h3>
            <h4>Your trusted Social Shopping platform</h4>
         </div>
         <div class="login-sidebar-tab">
            <a href='/SignIn'>
               <div class="login-sidebar-tab-list">
                  <div class="tab-icon">
                     <div class="tab-icon-count"><span class="material-icons-outlined">person</span></div>
                  </div>
                  <div class="tab-icon-count-spacer"></div>
                  <div class="login-tab-description">
                     <h3>Login</h3>
                     <p>Login with your Costbo account</p>
                  </div>
               </div>
            </a>
            <a href='/SignUp'>
               <div class="login-sidebar-tab-list active">
                  <div class="tab-icon">
                    <div class="tab-icon-count"><span class="material-icons-outlined">person_add</span></div>
                  </div>
                  <div class="tab-icon-count-spacer"></div>
                  <div class="login-tab-description">
                     <h3>Create an Account</h3>
                     <p>Sign up with your mobile number</p>
                  </div>
               </div>
            </a>
            <a href='/ForgetPassword'>
               <div class="login-sidebar-tab-list">
                  <div class="tab-icon">
                     <div class="tab-icon-count"><span class="material-icons-outlined">key</span></div>
                  </div>
                  <div class="tab-icon-count-spacer"></div>
                  <div class="login-tab-description">
                     <h3>Forgot your password</h3>
                     <p>We’ll help you reset your password.</p>
                  </div>
               </div>
            </a>
         </div>
      </div>
      <div class="login-content">
         <div class="login-userset">
            <div class="login-userheading">
               <h3>Register Details</h3>
            </div>
            <div class="form-login">
               <label>Full Name</label>
               <div class="form-addons">
                  <input type="text" placeholder="Enter Full Name"/>
                  <span class="material-icons toggle-password">person</span>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-6 col-sm-12">
                  <div class="form-login">
                     <label>Email Id</label>
                     <div class="form-addons">
                     <input type="text" placeholder="Enter Email Id" />
                     <span class="material-icons toggle-password">email</span>
                  </div>
                  </div>
               </div>
               <div class="col-lg-6 col-sm-12">
                  <div class="form-login">
                     <label>Re-Enter Email Id</label>
                     <div class="form-addons">
                     <input type="text" placeholder="Enter Re-Enter Email Id"/>
                     <span class="material-icons toggle-password">email</span>
                  </div>
                  </div>
               </div>              
            </div>
            <div class="form-login">
                  <label>Password</label>
                  <div class="pass-group">
                     <input type="password" class="pass-input" placeholder="Enter password"/>
                     <span class="material-icons toggle-password">visibility</span>
                  </div>
            </div>
            <div class="form-remember-password">
               <div class="signinform">             
                  <ul class="signin_pass_hind">
                     <li><p>One Upper case character</p></li>
                     <li><p>One Special character</p></li>
                  </ul>
               </div>
               <div class="form-login">
                  <ul class="signin_pass_hind">
                     <li><p>One Upper case character</p></li>
                     <li><p>One Special character</p></li>
                  </ul>
               </div>
            </div>
            <div class="login-by-continuing">By continuing, you agree to CostBo's <a href="#" class="hover-a">Terms of Use</a> & <a href="#" class="hover-a">Privacy Policy</a></div>
            <div class="form-remember-password form-btn-mb">
               <div class="signinform">
                  <h4>Already have an account?  <a href="/SignIn" class="hover-a">Log In</a></h4>
               </div>
               <div class="form-login">
                  <a class="btn btn-login" href="/GSTDetail">Create Account</a>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</>
);
}
export default SignUpDetail;