import React from 'react'; 
import Header from './Header';
import SideBar from '../../components/SideBar'; 

function StoreAllDataPreview() 
{ 
return(
 <>
   <Header />     
   <SideBar />

   <div class="page-wrapper">
      <div class="content">            
         <div class="row">
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="card card-main-profile-process card-main-profile-process-bg">
                     <div class="card-main-profile-process-detail">
                        <div class="main-profile-process-detail card-main-profile-process-detail-product">
                           <img src="https://storage.googleapis.com/bo3151920215/business/a2908c3dd2d74dcd95463059a7f094f6/logo.jpeg" alt="img"/>
                           <div class="main-profile-process-detail-title">
                           <h6>Maxwax Multiproducts LLP</h6>
                           <p>Traditional Sarees, Handloom Sarees, Tant Sarees, Silk Sarees and many more.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-12">
                  <div class="card">
                     <div class="card-body store-data-view">
                        <div class="row">
                           <div class="col-lg-12 store-tab-title store-tab-title-pre">
                              <h5>Pickup Address</h5>
                           </div> 
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Pickup Address same as Business Address</label>
                                 <input type="text" value='Yes'/>
                              </div>
                           </div> 
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Pin Code</label>
                                 <input type="text" value='560102'/>
                              </div>
                           </div> 
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>City</label>
                                 <input type="text" value='Bengaluru'/>
                              </div>
                           </div> 
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>State</label>
                                 <input type="text" value='Karnataka'/>
                              </div>
                           </div> 
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Address</label>
                                 <input type="text" value='5th Main Rd, HSR Layout'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Landmark</label>
                                 <input type="text" value='Sri Rama Temple (Nearest landmark)'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group form-group-lt">
                                 <label>Pickup Contact Number</label>
                                 <input type="text" value='+91 9025042838'/>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-12">
                  <div class="card">
                     <div class="card-body store-data-view">
                        <div class="row">
                           <div class="col-lg-12 store-tab-title">
                              <h5>BusinessTiming</h5>
                           </div>                           
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Sunday</label>
                                 <input type="text" value='Closed'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Monday</label>
                                 <input type="text" value='10 AM – 7 PM'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Tuesday</label>
                                 <input type="text" value='10 AM – 7 PM'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Wednesday</label>
                                 <input type="text" value='10 AM – 7 PM'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Thursday</label>
                                 <input type="text" value='10 AM – 7 PM'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Friday</label>
                                 <input type="text" value='10 AM – 7 PM'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group form-group-lt">
                                 <label>Saturday</label>
                                 <input type="text" value='10 AM – 7 PM'/>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-lg-12">
                  <div class="card">
                     <div class="card-body store-data-view">
                        <div class="row">
                           <div class="col-lg-12 store-tab-title">
                              <h5>Delivery Methods</h5>
                           </div>                           
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Delivery Methods</label>
                                 <input type="text" value='Delivery/Fulfillment by Business'/>
                              </div>
                           </div>
                           <div class="col-lg-12 col-sm-12 col-12 store-tab-title store-tab-title-pre">
                              <h6>All India Shipment </h6>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Need Express delivery?</label>
                                 <input type="text" value='Yes'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Cash On Delivery Order Amount Limit</label>
                                 <input type="text" value='Rs 500'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Express Delivery distance</label>
                                 <input type="text" value='75 KM'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Cash on delivery fee charged to</label>
                                 <input type="text" value='Rs 50'/>
                              </div>
                           </div>
                           <div class="col-lg-12 col-sm-12 col-12 store-tab-title store-tab-title-pre">
                              <h6>Hyperlocal</h6>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group form-group-lt">
                                 <label>Express Delivery Distance?</label>
                                 <input type="text" value='150 KM'/>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-lg-12">
                  <div class="card">
                     <div class="card-body store-data-view">
                        <div class="row">
                           <div class="col-lg-12 store-tab-title">
                              <h5>Discount Details</h5>
                           </div>                           
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Free shipment orders above</label>
                                 <input type="text" value='Rs 200'/>
                              </div>
                           </div>
                           <div class="col-lg-12 col-sm-12 col-12 store-tab-title store-tab-title-pre">
                              <h6>Additional Discount based on distance</h6>
                           </div>
                           <div class="col-lg-4 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>From Date</label>
                                 <input type="text" value='13-10-2022'/>
                              </div>
                           </div>
                           <div class="col-lg-4 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>To Date</label>
                                 <input type="text" value='17-10-2022'/>
                              </div>
                           </div>
                           <div class="col-lg-4 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Discount Percentage</label>
                                 <input type="text" value='25%'/>
                              </div>
                           </div>
                           
                           <div class="col-lg-12 col-sm-12 col-12 store-tab-title store-tab-title-pre">
                              <h6>Store Pickup Discount</h6>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Discount Type</label>
                                 <input type="text" value='Festival Offer'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Discount Amount</label>
                                 <input type="text" value='Rs 750'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group form-group-lt">
                                 <label>Discount Percentage</label>
                                 <input type="text" value='30%'/>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-lg-12">
                  <div class="card">
                     <div class="card-body store-data-view">
                        <div class="row">
                           <div class="col-lg-12 store-tab-title">
                              <h5>Bank Detail</h5>
                           </div>                           
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Account Holder Name</label>
                                 <input type="text" value='Sri Vaishnavi'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Account Number</label>
                                 <input type="text" value='32638376352'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>IFSC code</label>
                                 <input type="text" value='SBIN0002200'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Email Id</label>
                                 <input type="text" value='srivaishnavi@gmail.com'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group form-group-lt">
                                 <label>Contact Number</label>
                                 <input type="text" value='+91 9025042838'/>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-12">
                  <div class="card">
                     <div class="card-body store-data-view">
                        <div class="row">
                           <div class="col-lg-12 store-tab-title">
                              <h5>Other Detail</h5>
                           </div>                           
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Preferred Payment Method</label>
                                 <input type="text" value='Online Payment'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Minimum Order Amount</label>
                                 <input type="text" value='Rs 150'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                 <label>Return Policy</label>
                                 <input type="text" value='Directly'/>
                              </div>
                           </div>
                           <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group form-group-lt">
                                 <label>Special Notes</label>
                                 <input type="text" value='Subscription / products purchased'/>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-lg-12">
                  <div class="row">
                        <div class="col-lg-4 col-sm-4 col-5">
                           <a href="/StoreInfo" class="btn btn-back"><span class="material-icons-outlined">keyboard_double_arrow_left</span> Previous</a>
                        </div>
                        <div class="col-lg-8 col-sm-12 col-7 cb-txet-right">
                           <a href="/AddProduct" class="btn btn-submit">submit for approval</a>
                        </div>
                  </div>
               </div>


                         
            </div>
      </div>
   </div>

 </>
  ); 
} 
export default StoreAllDataPreview;