
import React from 'react';

function GetCostBoApp () {    
   return( 
   <>
      
      <section class="cb-download-app cb-download-app_bg" id="cb-download-app">
        <div class="container">
            <div class="row">
           
                <div class="col-lg-6 cb-p-r-30 align-items-center">
                <div class="sec-title sec-title-fees wow bounceIn animated" data-wow-duration="1s" data-wow-delay="0.2s">                        
                <span>CostBo App</span>
                <h4>Get started by registering your business right away!</h4>                         
            </div>
                  <div class="cb_cost-app">
                     <h5>Download CostBo App</h5>
                     <a href="https://play.google.com/store/apps/details?id=com.costbo"> <img src="https://storage.googleapis.com/bodefaults/businessweb/google-store.png" alt="CostBo App" /> </a>
                     <a href="https://apps.apple.com/app/id1468091785">  <img src="https://storage.googleapis.com/bodefaults/businessweb/app-store.png" alt="CostBo App"  /> </a>
                  
                     <img src="https://storage.googleapis.com/bodefaults/businessweb/qr_code.svg" alt="CostBo QR Code" class="qr-image" />   
                  </div>
                  <div class="cb_steps_app">
                     <h5><span></span>Register using Phone number</h5>
                     <h5><span></span>Email Id validate Email Link </h5>
                     <h5><span></span>Add Business (from side menu)</h5>
                     <h6>3 Simple Requirements </h6>
                     <p> 1. Business logo 2. Business Description 3. Banner Image (4:3) 4. GST, PAN, FSSAI(Optional)</p>
                  </div>

                </div>
                <div class="col-lg-6">
                    <div class="cta-phone-image">
                        <img src="assets/images/appimg.png" alt="" class=" img-fluid"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
      
   </>
);
}

export default GetCostBoApp;