import React from 'react'; 
import Header from '../Catalog/Header';
import SideBar from '../../components/SideBar';

function Empty() 
{ 
return(
 <>
   <Header />     
   <SideBar />

      
      <div class="page-wrapper">
         <div class="content">    
         <div class="row">
               <div class="col-lg-6 col-sm-6 col-12">
                  <div class="cb-business-header">
                     <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg" alt='Logo'/>
                     <h6>Plant Science</h6>
                  </div>
               </div> 
               <div class="col-lg-6 col-sm-6 col-12">
                  <div class="dash-widget">
                     <div class="dash-widget-content">
                        {/* <div class="dash-widgetimg">
                           <img src="./assets/images/dash1.svg" alt="img" />
                        </div> */}
                        <div class="dash-widgetcontent">
                           <h6>Total Product</h6>
                           <h5>145</h5>
                        </div>
                     </div>
                     <div class="dash-widget-content">
                        {/* <div class="dash-widgetimg">
                           <img src="./assets/images/dash2.svg" alt="img" />
                        </div> */}
                        <div class="dash-widgetcontent">
                           <h6>Total Sales</h6>
                           <h5>Rs 254385</h5>
                        </div>
                     </div>
                     <div class="dash-widget-content wdth-100">
                        {/* <div class="dash-widgetimg dash-widgetimg1">
                           <img src="./assets/images/store.svg" alt="img" />
                        </div> */}
                        <div class="dash-widgetcontent">
                           <h5>Ecommerce enabled</h5>
                           <div class="toggle-btn toggle-btn1">
                              <input type="checkbox" id="toggle-btn-5"/>
                              <label for="toggle-btn-5"></label>
                           </div>
                        </div>
                     </div>
                     
                  </div>
               </div>
            </div>
            <div class="row">               
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="card-p-10">
                     <div class="row product-header">                   
                        <div class="col-md-12">     
                           <div class="card-header content-header">
                              <div>                    
                                 <h4>Active Inventory</h4>
                              </div>
                              {/* <div>
                                 <a href="#" class="btn btn-add-business btn-postion">
                                 <span class="material-icons-outlined">inventory</span>
                                 <div class="text-mb">Update Product</div></a>
                              </div> */}
                           </div>  
                           <div class="cb-inventory-tab-head pd-mt-10">
                              <ul class="cb-inventory-nav-tabs cb-inventory-nav-tabs-business cb-inventory-nav-tabs-product">
                                 <li><a href="/OutofStock">Out of Stock - 17</a></li>
                                 <li><a href="/LowStock">Low Stock - 12</a></li>
                                 <li><a href="/PriceUpdate">Price Update - 24</a></li>
                                 <li><a href="/Inventory">All Stock - 53</a></li>
                                 <li><a class="active" href="/Empty">Empty - 0</a></li>
                              </ul>     
                           </div>
                        </div> 
                        <div class="col-md-5 cb-inventory-tab-title">         
                           <h5>All Stock <span>- 0</span></h5>
                           <i class="fas fa-search mb-search-icon"></i>
                        </div>
                         
                        <div class="col-lg-12">
                           <div class="discount-cashback-empty">
                              <span class="material-icons-outlined">content_paste</span>
                              <h5>No Stock available</h5>
                           </div>
                        </div>    
                     </div>

                  </div>   
               </div>
            </div>
         </div>
      </div>

 </>
  ); 
} 
export default Empty;