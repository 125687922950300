import React, { Component } from "react";
import Slider from 'react-slick/lib/slider'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



export default class CenterMode extends Component {
  render() {
    const settings = {
     
      centerMode: true,
      centerPadding: 15,
      dots: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: false,
      speed: 500,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true,
            centerMode: false,
            centerPadding: 50,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <img src="../assets/images/category_groceries.jpg"  alt="groceries" />
            <h6>Food Industry</h6>
          </div>
          
          <div>
            <img src="../assets/images/category_pharma.jpg"  alt="Auto & Spare" />
            <h6>Pharma</h6>
          </div>
          <div>
            <img src="../assets/images/category_fmcg.jpg"  alt="FMCG" />
            <h6>FMCG</h6>
          </div>
          <div>
            <img src="../assets/images/category_auto_care.jpg"  alt="Auto & Spare" />
            <h6>Auto & Spare</h6>
          </div>
          <div>
            <img src="../assets/images/category_electronics.jpg"  alt="Electronics" />
            <h6>Electronics</h6>
          </div>
        </Slider>
      </div>
    );
    }
}