import React from 'react'; 
import Header from '../Header';
import SideBar from '../../../components/SideBar';

function EditMeasurementDeliveryMethod() 
{ 
return(
 <>
   <Header />     
   <SideBar />

   <div class="page-wrapper">
      <div class="content">
         <div class="row">
            <div class="col-lg-12 col-sm-12 col-12 mb-10">
               <div class="cb-business-header">
                  <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg" alt='Logo'/>
                  <h6>Plant Science</h6>
               </div>
            </div> 
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="card">
                     <div class="page-title-process">
                        <div class="card-header cb-display-flex">                     
                           <h4>Anti Blemish Cream - 20g</h4>
                        </div>
                     </div>
                  <div class="card-body">
                     <div class="row">
                        <div class="col-lg-12 col-sm-12 col-12">
                        <ul class="nav nav-tabs cb-nav-tabs">
                              <li><a class="active1" href="/EditProduct"><span class="material-icons-outlined">inventory_2</span>Product Detail</a></li>
                              <li><a class="active1" href="/EditCategory"><span class="material-icons-outlined">inventory</span>Specification</a></li>
                              <li><a class="active1"  href="/EditPriceTaxInfo"><span class="material-icons-outlined">request_quote</span>Pricing</a></li>
                              <li><a class="active" href="/EditMeasurementDeliveryMethod"><span class="material-icons-outlined">view_in_ar</span>Measurement Info</a></li>
                              <li><a href="/EditProductAvailablity"><span class="material-icons-outlined">assignment</span>Availablity</a></li>
                              {/* <li><a href="/SubscriptionInformation"><span class="material-icons-outlined">people</span>Subscription</a></li> */}
                           </ul>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12 store-tab-title">
                           <h5>Product Measurement</h5>
                        </div>   
                        <div class="col-lg-6 col-sm-6 col-12">
                           <div class="form-group form-group-edit">
                              <label>Product Weight (in gms) <span>*</span></label>
                              <input type="text" value='68'/>
                           </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-12">
                           <div class="form-group form-group-edit">
                              <label>Product Length (in mm)</label>
                              <input type="text" value='142'/>
                           </div>
                        </div>
                        
                        <div class="col-lg-6 col-sm-6 col-12">
                           <div class="form-group form-group-edit">
                              <label>Product Width (in mm)</label>
                              <input type="text" value='85'/>
                           </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-12">
                           <div class="form-group form-group-edit">
                              <label>Product Height (in mm)</label>
                              <input type="text" value='164'/>
                           </div>
                        </div>
                        {/* <div class="col-lg-12 col-sm-12 col-12 store-tab-title"><h6>Delivery Method</h6></div>
                        <div class="col-lg-6 col-sm-6 col-12">
                           <div class="form-group form-group-edit">
                              <label class="pd-display">Select Delivery Method <span>*</span></label>
                              <input id="Yes" type="radio" name="user" value="Yes"/>
                              <label for="Yes" class="mr-2"><span></span>Delivery Fulfillment</label>

                              <input id="No" type="radio" name="user" value="No"/>
                              <label for="No"><span></span>Store Pickup</label>
                           </div>
                        </div> */}
                     
                     </div>
                     <div class="row">
                        <div class="col-lg-4 col-sm-4 col-5">
                           <a href="/EditPriceTaxInfo" class="btn btn-back"><span class="material-icons-outlined">keyboard_double_arrow_left</span> Previous</a>
                        </div>
                        <div class="col-lg-8 col-sm-12 col-7 cb-txet-right">
                           <a href="/ProductDraft" class="btn btn-submit">Save</a>
                           <a href="/EditProductAvailablity" class="btn btn-next cb-ml-4">Preview & Submit</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            
         </div>
      </div>
   </div>

 </>
  ); 
} 
export default EditMeasurementDeliveryMethod;