import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PlaceOrder from '../components/PlaceOrder'
function Affiliates() {
return( 
<>
<Header />

<div class="app-nav"><div class="app-nav"></div></div>
<div class="app-header">
        <div class="site-nav-container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <div class="section-heading">         
                        <span>Key Features for CostBo</span>            
                        <h2>How It Works</h2>
                    </div>
                </div>
            </div>
        
        
        </div>
    </div>


</>
);
}
export default Affiliates;