import React from 'react';
function Pricing () {
   return( 

   <>
     <section class="pricing-tab">
        <div class="container">
            <div class="sec-title pricing-sec-title text-center mb-10 mt-20 wow bounceIn animated" data-wow-duration="1s" data-wow-delay="0.2s">                        
                <span>SOLUTIONS</span>
                <h4>Direct  to Customers Online sales! <br/>Grow your business by 10x</h4>     
                <h6><a>Available as a Plugin / Brand New website</a> - Just in hours </h6>
                                     
            </div>
                <div class="planing-tab-head">
                    <div class="planing-tab-title">
                        <h6>Choose your growth model</h6>
                    </div>
                    <input type="radio" id="tab1" name="tab-control" checked/>
                    <input type="radio" id="tab2" name="tab-control"/>
                    <input type="radio" id="tab3" name="tab-control"/>
                    <input type="radio" id="tab4" name="tab-control"/>
                    <ul class="planing-tab-list cb-dt">
                        <li title="Features" class="active">
                        <label for="tab1" role="button">
                            <span>D2C BRANDS</span></label>
                        </li>
                        <li title="Delivery Contents" class="wd-mb">
                        <label for="tab2" role="button">
                            <span>FINTECH - B2B SUPPLY CHAIN </span></label>
                        </li>
                        <li title="Shipping">
                        <label for="tab3" role="button">
                            <span>AFFILIATE PLUGIN</span></label>
                        </li>
                        <li title="Returns">
                        <label for="tab4" role="button">
                            <span>ENTERPRISE PLAN</span></label>
                        </li>
                    </ul>
                    <ul class="planing-tab-list cb-mb">
                        <li title="Features" class="active">
                        <label for="tab1" role="button">
                            <p>1</p>
                            <span>D2C BRANDS</span></label>
                        </li>
                        <li title="Delivery Contents" class="wd-mb">
                        <label for="tab2" role="button">
                            <p>2</p>
                            <span>FINTECH-B2B </span></label>
                        </li>
                        <li title="Shipping">
                        <label for="tab3" role="button">
                            <p>3</p>
                            <span>AFFILIATE</span></label>
                        </li>
                        <li title="Returns">
                        <label for="tab4" role="button">
                            <p>4</p>
                            <span>ENTERPRISE</span></label>
                        </li>
                    </ul>
                   
                    <div class="content">
                        <section>
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="solutions-area s4">
                                        <div class="solutions-title">
                                            <span>Eliminate the Intermediary</span>
                                            <h4>Own Your Brand and<br/>  Own <strong>Your Customers!</strong></h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="solutions-area s5">
                                        <div class="solutions-title">
                                            <span>Take your online Business to the Next Level! </span>
                                            <h4><strong>Go Pan-India</strong> in Under 2 hours <br/>with Integrated Fintech</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-lg-6 align-self-center  wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
                                    <div class="cb-planing-tab lt-view">
                                        <img src="../assets/images/b2c-business.png" alt="Influencers & Affiliates Network" />
                                    </div>
                                </div> 
                                <div class="col-lg-6 align-items-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
                                    <div class="planing-title mb-10 mt-10">         
                                        <h4>All in One D2C E-Commerce Infra for your Brand</h4> 
                                        <p>Is your website built on <a>Shopify, WIX, Word press </a> or other tools? </p>
                                        <p>Are you leveraging the most advanced E-commerce technologies to stay ahead of the competition and drive sales?</p>
                                    </div> 
                                    <div class="cb-planing-tab mb-view">
                                        <img src="../assets/images/b2c-business.png" alt="Influencers & Affiliates Network" />
                                    </div> 
                                    <div class="price_plan_list">                                    
                                        <h6>We got you covered!</h6>
                                        <div class="price_plan_lt">
                                            <ul class="cb-planing-tab-content">
                                                <li><p>PAN-India Low cost Shipping (Up to 2500 Kgs)</p></li>
                                                <li><p>Buy now/ Pay later option </p></li>
                                                <li><p>Automated CRM Tools </p></li>
                                                <li><p>Google SEO & ONDC Reach</p></li>
                                                <li><p>First time buyer discounts</p></li>                                
                                            
                                            </ul>
                                            <ul class="cb-planing-tab-content">
                                                <li><p>Loyalty wallets for Customers</p></li>
                                                <li><p>Full Back office tools</p></li>
                                                <li><p>Dedicated Mobile dashboard</p></li>
                                                <li><p>Coupon codes, Preferred Discounts</p></li>
                                                <li><p>Free Sample programs</p></li>
                                            </ul> 
                                        </div>  
                                    </div>  
                                    <a href="#" class="btn_pricing_button">Get Started</a>                                   
                                </div>
                                <div class="price-amount-detail">
                                    <p>(One time Onboarding fee Rs 9,999, Free Training and Support) + Service Fee on Transactions </p>                             
                                </div>
                            </div>
                        </section>
                       
                        
                        
                        <section>
                        <div class="row align-items-center">
                                <div class="col-lg-12">
                                    <div class="solutions-area s6">
                                        <div class="solutions-title">
                                            <span>Don’t sweat about Cash Flow</span>
                                            <h3><strong>Automated Fintech</strong> for your buyers</h3>
                                            <h5>Instant Credit line powered by NBFC partners</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-center">                                
                                <div class="col-lg-6 align-items-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
                                    <div class="planing-title mb-10 mt-10">         
                                        <h4>Increase B2B Supply Chain Efficiency to reach All India</h4> 
                                        <p>Is your business impacted by <a>delayed payments</a> from dealers due to credit extensions?</p>
                                        <p>Are  you looking to <a>expand your dealer network</a> while minimizing credit risk?</p>
                                    </div> 
                                    <div class="cb-planing-tab mb-view">
                                        <img src="../assets/images/fintech-plugin.png" alt="Influencers & Affiliates Network" />
                                    </div> 
                                    <div class="price_plan_list">                                    
                                    <h6>We got you covered!</h6>
                                        <div class="price_plan_lt">
                                            <ul class="cb-planing-tab-content">
                                                <li><p>Build a direct dealer network from brand website</p></li>
                                                <li><p>No risk Supply chain Finance to your B2B buyers (via trusted NBFC partners)</p></li>
                                                <li><p>Dealer exclusivity based on regions</p></li>                           
                                            
                                            </ul>
                                            <ul class="cb-planing-tab-content">
                                                <li><p>Preferred pricing model </p></li>
                                                <li><p>Deep shipment integration (up to 10,000 Kgs)</p></li>
                                                <li><p>Payments upon delivery, Guaranteed</p></li>
                                            </ul> 
                                        </div>
                                        <span>(Also includes all features listed for D2C brand websites)</span>  
                                    </div> 
                                    <a href="#" class="btn_pricing_button">Get Started</a>   
                                </div>
                                <div class="col-lg-6 align-self-center  wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
                                    <div class="cb-planing-tab lt-view">
                                        <img src="../assets/images/fintech-plugin.png" alt="Influencers & Affiliates Network" />
                                    </div>
                                </div> 
                                <div class="price-amount-detail">
                                    <p>(Starting Rs 29,999 - One time step up) + Service Fee on Transactions</p>                             
                                </div>
                            </div>
                        </section>

                        <section>
                        <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="solutions-area s1">
                                        <div class="solutions-title">
                                            <span>Increase sales by 10x </span>
                                            <h4><strong>Unleash the Magic</strong> of Affiliate Marketing, <br/>Right Now!</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="solutions-area s2">
                                        <div class="solutions-title">
                                            <span>More Reach . More Sales . More Profits</span>
                                            <h4>Turn your <strong>Customers into Influencers</strong>  <br/>Today!</h4>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="col-lg-4">
                                    <div class="solutions-area s3">
                                        <div class="solutions-title">
                                            <span>Increase sales by 10x </span>
                                            <h4><strong>Multiply your Sales Force</strong> with Affiliate Marketing right away!</h4>
                                            <h6>More Reach . More Sales . More Profits</h6>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div class="row align-items-center">
                                <div class="col-lg-6 align-self-center  wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
                                    <div class="cb-planing-tab lt-view">
                                        <img src="../assets/images/affiliates.png" alt="Influencers & Affiliates Network" />
                                    </div>
                                </div> 
                                <div class="col-lg-6 align-items-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
                                    <div class="planing-title mb-10 mt-10">         
                                        <h4>Measure & GROW your ROI of Digital Investments</h4> 
                                        <p>Are you spending too much on <a>Digital Promotions</a> without any results? </p>
                                        <p>Would you like to <a>grow your affiliate network</a> and reach more potential customers?</p>
                                    </div> 
                                    <div class="cb-planing-tab mb-view">
                                        <img src="../assets/images/affiliates.png" alt="Influencers & Affiliates Network" />
                                    </div> 
                                    <div class="price_plan_list">                                    
                                        <h6>We got you covered!</h6>
                                        <div class="price_plan_lt">
                                            <ul class="cb-planing-tab-content">
                                                <li><p>Affiliate enrollment from you Brand website</p></li>
                                                <li><p>Success Fee based model, Direct Settlements</p></li>
                                                <li><p>Employee Referral Programs</p></li>                       
                                            </ul>
                                            <ul class="cb-planing-tab-content">
                                                <li><p>Social Media Sales Tracking</p></li>
                                                <li><p>Affiliate performance monitoring</p></li>
                                                <li><p>Performance based commissions</p></li>
                                            </ul> 
                                        </div>  
                                    </div>  
                                    <a href="#" class="btn_pricing_button">Get Started</a>   
                                </div>
                                <div class="price-amount-detail">
                                    <p>Starting Rs 9,999 - One time step up, Free Training + Service Fee on Transactions</p>                             
                                </div>
                            </div>
                        </section>
                        <section>                            
                        <div class="row align-items-center">
                                <div class="col-lg-12">
                                    <div class="solutions-area s7">
                                        <div class="solutions-title solutions-title-1">
                                            <span>No IT Dept ? No Problem!</span>
                                            <h3>Plug & Play with Future Ready <strong>E-comm Ecosystem</strong></h3>
                                            <h5>Faster to Market, Increased B2B + B2C sales</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-center">                                
                                <div class="col-lg-6 align-items-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
                                    <div class="planing-title mb-10 mt-10">         
                                        <h4>Future-proof your business by strengthening  your online infrastructure with the latest technology</h4> 
                                        <p>Is your Business doing 25 CR ARR or Above? </p>
                                        <p>Are you looking to <a>increase top line by 2X-3X?</a> </p>
                                    </div> 
                                    <div class="cb-planing-tab mb-view">
                                        <img src="../assets/images/b2b-business.png" alt="Influencers & Affiliates Network" />
                                    </div> 
                                    <div class="price_plan_list">                                    
                                        <h6>Enjoy costBo's enterprise package!</h6>
                                        <div class="price_plan_lt">
                                            <ul class="cb-planing-tab-content">
                                                <li><p>B2C/B2B Plugins to existing website</p></li>
                                                <li><p>Affiliate Plugin Existing websites</p></li>
                                                <li><p>Multi Franchise operations</p></li>                           
                                                <li><p>FINTECH Supply chain optimization</p></li>                           
                                                <li><p>Trade organization set ups</p></li>                            
                                            
                                            </ul>
                                            <ul class="cb-planing-tab-content">
                                                <li><p>Dedicated Brand Manager Support </p></li>
                                                <li><p>Dedicated Mobile dashboard </p></li>
                                                <li><p>ONDC Push </p></li>
                                                <li><p>Multi ware house model </p></li>
                                                <li><p>Google SEO & costBo Discovery </p></li>
                                            </ul> 
                                        </div>  
                                    </div>  
                                    <a href="#" class="btn_pricing_button">Get Started</a>   
                                </div>
                                <div class="col-lg-6 align-self-center  wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
                                    <div class="cb-planing-tab lt-view">
                                        <img src="../assets/images/b2b-business.png" alt="Influencers & Affiliates Network" />
                                    </div>
                                </div> 
                                <div class="price-amount-detail">
                                    <p>(For Pricing Details - Contact us)</p>                             
                                </div>
                            </div>
                        </section>
                        
                        
                        

                    </div>
            </div>
                
        </div>
    </section>


   </>
);
}

export default Pricing;