import React, { Component, useEffect } from 'react';
function  Login () {
return( 
<>
  <section class="section-login">
    <div class="login-user">
      <div class="login-modal-dialog">
        <a class="cb-login-close">
          <span class="material-icons-round">close</span>
        </a>
        <div class="cb-login-register">
          <div class="cb-login-register-header">
            <img src="./assets/images/logo.png" alt="Login" />
            <h3>Welcome to CostBo</h3>
            <p>Future ready, All in One D2C E-Commerce Infra</p>
          </div>
          <div class="cb-login-register-body">
            <h4>Enter your mobile number</h4>
            <form>
              <div class="form-group cb-form-group-number">
                <div class="cb-phone-country-box">
                   {/* <p class="cb-coun">Country</p> */}
                   <p class="cb-num">+91</p>
                  <div class="cb-bdr"></div>
                </div>
                <input type="tel" class="form-control" placeholder="Phone Number" />
                <span class="material-icons-round">smartphone</span>
              </div>
              <p>By continuing, you agree to our <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a></p>
              <div class="cb-login-btn">
                <a href="/ExistingUser" class="login-btn">Continue</a>
              </div>
            </form>
          </div>
          <div class="cb-login-register-footer">
            <h5>Trusted by 1000s of Brands</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
  
</>
);
}
export default Login;