import React from 'react';
function InfluencersAffiliates () {
   return( 

   <>

        <div class="cb-influencersaffiliates cb_pd_20">
            <div class="container">
                <div class="row align-items-center">
                   <div class="col-lg-6 align-self-center  wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
                        <div class="about-content cb-influencer-content lt-view">
                            <img src="../assets/images/affiliates-market-Network.png" alt="Influencers & Affiliates Network" />
                        </div>
                    </div> 
                    <div class="col-lg-6 align-items-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
                        <div class="sec-title sec-title-white-sn mb-20 mt-20">      
                            <span>Influencers & Affiliates Network</span>                  
                            <h4>Exponential Sales & New market reach through Influencers & Affiliates</h4>    
                        </div> 
                         <div class="about-content cb-influencer-content mb-view">
                            <img src="../assets/images/affiliates-market-Network.png" alt="Influencers & Affiliates Network" />
                        </div> 
                        <div class="cb-influencer-content">                                       
                            <p><span></span>Increased customer reach</p>        
                            <p><span></span>More brand Visibility</p>         
                            <p><span></span>Success fee based model</p>      
                        </div>                           
                    </div>
                </div>
            </div>              
        </div>
      
   </>
);
}

export default InfluencersAffiliates;