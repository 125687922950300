import React from 'react'; 
import Header from './Header';
import SideBar from '../../components/SideBar'; 

function ProductDetail() 
{ 
return(
 <>
   <Header />     
   <SideBar />

   <div class="page-wrapper">
      <div class="content">            
         <div class="row">
            <div class="col-lg-5 col-sm-6 col-12">
               <div class="cb-business-header">
                  <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg" alt='Logo'/>
                  <h6>Plant Science</h6>
               </div>
            </div> 
            <div class="col-lg-7 col-sm-6 col-12">
               <div class="dash-widget">
                  <div class="dash-widget-content">
                     <div class="dash-widgetcontent">
                        <h6>Total Order</h6>
                        <h5>145</h5>
                     </div>
                  </div>
                  <div class="dash-widget-content">
                     {/* <div class="dash-widgetimg">
                        <img src="./assets/images/dash2.svg" alt="img" />
                     </div> */}
                     <div class="dash-widgetcontent">
                        <h6>Total Sales</h6>
                        <h5>Rs 254385</h5>
                     </div>
                  </div>
                  <div class="dash-widget-content wdth-100">
                     {/* <div class="dash-widgetimg dash-widgetimg1">
                        <img src="./assets/images/store.svg" alt="img" />
                     </div> */}
                     <div class="dash-widgetcontent">
                        <h5>Ecommerce enabled</h5>
                        <div class="toggle-btn toggle-btn1">
                           <input type="checkbox" id="toggle-btn-5"/>
                           <label for="toggle-btn-5"></label>
                        </div>
                     </div>
                  </div>
                  
               </div>
            </div>
         </div>         
         <div class="row">
            <div class="col-md-12">
               <div class="db-product-bm-wrapper">
                  <div class="db-product-categories">
                     Category:<span >Auto Parts</span>
                  </div>
                  <h1 class="db-product-title">Safe Harvest Kodo Millet Rice - 500 Grm</h1>
               </div>
            </div>
            <div class="col-md-9">
               <div class="row">                  
                  <div class="col-md-6">
                     <div class="db-product-gallery">   
                        <div class="db-swiper-wrapper">
                           <div class="db-swiper-slide">
                              <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/product/c13f912e3036400eafb604d35e05f4ee/Product1-287409.jpeg" alt=""/>
                           </div>
                           <div class="db-swiper-slide">
                              <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/product/c13f912e3036400eafb604d35e05f4ee/Product2-836905.jpeg" alt="" />
                           </div>
                           <div class="db-swiper-slide">
                              <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/product/c13f912e3036400eafb604d35e05f4ee/Product3-219997.jpeg" alt=""/>
                           </div>
                           <div class="db-swiper-slide">
                              <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/product/c13f912e3036400eafb604d35e05f4ee/Product4-392839.jpeg" alt=""/>
                           </div>
                        </div>   
                        <div class="db-product-thumbs-wrap">
                           <div class="db-product-thumb">
                              <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/product/c13f912e3036400eafb604d35e05f4ee/Product1-287409.jpeg" alt="Product Thumb"/>
                           </div>
                        </div>                        
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="db-product-details">
                        <div class="db-product-price">
                           <del>₹ 380</del>
                           <ins>₹ 335</ins>
                           <label>12% OFF</label>
                           <div class="db-product-short-desc">
                              <p>(Inclusive of all taxes)</p>
                           </div> 
                        </div>
                        <div class="db-product-other-date">
                           <h6>Start Selling On :<span>12-01-2022</span></h6>
                           <h6>End Selling On :<span>01-12-2024</span></h6>
                        </div> 
                                               
                        <hr class="db-product-divider line_divider"/>
                        <div class="db-product-other">
                           <h6>Brand:<span>Skin Care</span></h6>
                           <h6>Country:<span>India</span></h6>
                           <h6>SKU ID:<span>PS24NEW1527</span></h6>
                        </div>                      
                        <hr class="db-product-divider line_divider"/>  
                        <div class="db-product-other">
                           <h6>GST/HSN code:<span>33AABCU9603R1ZU</span></h6>
                        </div>                        
                        <hr class="db-product-divider line_divider"/>
                        <div class="db-product-other">
                           <h6>Weight (in gms):<span>68</span></h6>
                           <h6>Length (in mm):<span>142</span></h6>
                        </div>                        
                        <div class="db-product-other">
                           <h6>Width (in mm):<span>85</span></h6>
                           <h6>Height (in mm):<span>167</span></h6>
                        </div>              
                     </div>
                  </div>
               </div>
            </div>
            
            <div class="col-md-3">
               <div class="card-header card-header-second">
                  <h5>Product Availablity</h5>
               </div>
               <div class="db-product-other">
                  <h6>Available Quantity:<span>120</span></h6>
               </div>                        
               <div class="db-product-other">
                  <h6>Alert Quantity :<span>167</span></h6>
               </div>   
               <hr class="db-product-divider line_divider"/>
               <div class="db-product-other">
                  <h6>Min Quantity Per Order :<span>142</span></h6>
               </div>                        
               <div class="db-product-other">
                  <h6>Max Quantity Per Order:<span>167</span></h6>
               </div> 
            </div>
            
            <div class="col-md-12">
            <div class="cb-inventory-tab-head">
                  <ul class="cb-inventory-nav-tabs cb-inventory-nav-tabs-business cb-inventory-nav-tabs-product cb-tab-mb-12">
                     <li><a class="active" href="#">Description</a></li>
                     <li><a href="#">Specification</a></li>
                  </ul>     
               </div>
               <div class="tab-content">
                  <div class="db-product-tab-description">
                     <div class="row">
                        <div class="col-md-12">
                           <h5>Description</h5>
                           <p>Safe Harvest Private Limited (SHPL) was born out of the idea that consumers would be willing to pay a modest premium for guaranteed safe food products produced by small farmers, 
                              creating a win-win situation for both farmers and the consumers. By connecting these farmers with organized markets, Safe Harvest aims is to address pressing problems of the 
                              agriculture economy and the environment.
                           </p>                           
                        </div>
                     </div>
                  </div>
               </div>
               </div>
         </div>            
      
         <div class="row">
            <div class="col-lg-4 col-sm-4 col-5">
               <a href="/ProductActive" class="btn btn-back"><span class="material-icons-outlined">keyboard_double_arrow_left</span> Previous</a>
            </div>
            <div class="col-lg-8 col-sm-12 col-7 cb-txet-right">
               <a href="/EditProduct" class="btn btn-submit">Edit</a>
            </div>
         </div>
                     
      </div>
   </div>

 </>
  ); 
} 
export default ProductDetail;