import React from 'react'; 
import Header from '../Dashboard/Header';
import SideBarBusiness from '../../components/SideBarBusiness'; 

function BusinessListing() 
{ 
return(
 <>
   <Header />     
   <SideBarBusiness />

   <div class="page-wrapper">
      <div class="content">
         <div class="row">
            <div class="col-lg-12 col-sm-12 col-12">  
                  <div class="card-header content-header block">
                     <div>                    
                        <h4>Business Listing</h4>
                        <p>Select the business you want to manage</p>
                     </div>
                     <div class="bd-top">
                        <div class="form-group-bd">
                        <label>Status</label>
                           <select class="select">
                              <option>All</option>
                              <option>Active</option>
                              <option>Pending</option>
                              <option>Draft</option>
                              <option>Rejected</option>
                           </select>
                        </div>
                        <a href="/StoreDetail" class="btn btn-add-business-1 dt"><span class="material-icons-outlined">add</span> Add Business</a></div>
                  </div>                          
                  <div class="card-body">
                     <div class="row">
                        <div class="col-md-12 pd-0">
                           <div class="filter-hd">
                              <h6>Showing 4 results for <strong>“All”</strong></h6>
                              <a href='#'>Clear</a>
                           </div>
                        </div>
                        <div class="col-md-3 pd-0">
                           <div class="cb-business-status">
                              <div class="cb-business-status-header">
                                 <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg"/>
                                 <h6>Plant Science</h6>                                      
                              </div>
                              <div class="cb-business-status-footer">
                                 <div class="cb-business-options-edit">
                                    {/* <a href="#">Edit Business </a> */}
                                 </div>
                                 <div class="cb-business-options-status clr-active" >
                                 <a href="/Dashboard">Active</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-3 pd-0">
                           <div class="cb-business-status">
                                 {/* <div class="select-option"><span class="material-icons">check_circle</span></div> */}
                              <div class="cb-business-status-header">
                                 <img src="https://storage.googleapis.com/bo3151920215/business/c147c9d959f74365bbce4d2633e99e97/logo.jpeg"/>
                                 <h6>Tavastha Ayurveda</h6>                                    
                              </div>
                              <div class="cb-business-status-footer">
                                 <div class="cb-business-options-edit">
                                    {/* <a href="#">Edit Business </a> */}
                                 </div>
                                 <div class="cb-business-options-status clr-pending" >
                                 <a href="/StorePreviewPending">Pending</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-3 pd-0">
                           <div class="cb-business-status">
                              <div class="cb-business-status-header">
                                 <img src="https://storage.googleapis.com/bo3151920215/business/5d89b03dd1f34a6780ab5f538c6c41f3/logo.jpeg"/>
                                 <h6>Shivi Herbal Care</h6>                                 
                              </div>
                              <div class="cb-business-status-footer">
                                 <div class="cb-business-options-edit">
                                    <a href="#">Edit Business </a>
                                 </div>
                                 <div class="cb-business-options-status clr-draft" >
                                 <a href="/StoreInfo">Draft</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-3 pd-0">
                           <div class="cb-business-status">
                                 <div class="cb-business-status-header">
                                    <img src="https://storage.googleapis.com/bo3151920215/business/9d3cccae1bdf4b5a804f8a271c94199d/logo.jpeg"/>
                                    <h6>Presso Coldpress Oil - B2B</h6>                                    
                                 </div>
                              <div class="cb-business-status-footer">
                                 <div class="cb-business-options-edit">
                                    {/* <a href="#">Edit Business </a> */}
                                 </div>
                                 <div class="cb-business-options-status clr-rejected" >
                                 <a href="#">Rejected</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-12"><a href="/StoreDetail" class="btn btn-add-business mp-none mp-none-1"><span class="material-icons-outlined">add</span> Add Business</a></div>
                
                     </div>                                  
                  </div>
               </div>      
         </div>
      </div>
   </div>

 </>
  ); 
} 
export default BusinessListing;