import React from 'react'; 
import Header from '../Header';
import SideBar from '../../../components/SideBar';

function SubscriptionInformation() 
{ 
return(
 <>
   <Header />     
   <SideBar />

   <div class="page-wrapper">
      <div class="content">
         <div class="row">
            <div class="col-lg-12 col-sm-12 col-12 mb-10">
               <div class="cb-business-header">
                  <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg" alt='Logo'/>
                  <h6>Plant Science</h6>
               </div>
            </div> 
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="card">
                     <div class="page-title-process">
                        <div class="card-header cb-display-flex">                     
                           <h4>Add Product</h4>
                        </div>
                     </div>
                  <div class="card-body card--p-0">
                     <div class="row">
                        <div class="col-lg-12 col-sm-12 col-12">
                           <ul class="nav nav-tabs cb-nav-tabs">
                              <li><a class="active1" href="/AddProduct"><span class="material-icons-outlined">inventory_2</span>Product Detail</a></li>
                              <li><a class="active1" href="/Category"><span class="material-icons-outlined">inventory</span>Specification</a></li>
                              <li><a class="active1"  href="/PriceTaxInfo"><span class="material-icons-outlined">request_quote</span>Pricing</a></li>
                              <li><a class="active1" href="/MeasurementDeliveryMethod"><span class="material-icons-outlined">view_in_ar</span>Measurement Info</a></li>
                              <li><a class="active1" href="/ProductAvailablity"><span class="material-icons-outlined">assignment</span>Availablity</a></li>
                              <li><a class="active" href="/SubscriptionInformation"><span class="material-icons-outlined">people</span>Subscription</a></li>
                           </ul>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12 store-tab-title">
                           <h5>Subscription Information</h5>
                        </div>
                        
                        <div class="col-lg-12 col-sm-12 col-12">  
                        
                        <div class="pd-text-lb"><h6>Is the product can be subscribed on regular intervals</h6></div>                         
                           <div class="delivery_checkbox">
                              <div class="form-group">
                                 <input type="checkbox" id="Daily"/>
                                 <label for="Daily">Daily</label>
                              </div>
                              <div class="form-group">
                                 <input type="checkbox" id="Alternate Days" />
                                 <label for="Alternate Days">Alternate Days</label>
                              </div>
                              <div class="form-group">
                                 <input type="checkbox" id="Weekly" />
                                 <label for="Weekly">Weekly</label>
                              </div>
                              <div class="form-group">
                                 <input type="checkbox" id="Monthly" />
                                 <label for="Monthly">Monthly</label>
                              </div>
                           </div>
                        </div> 

                        <div class="col-lg-3 col-sm-6 col-12">
                           <div class="form-group">
                              <label>Original Value <span>*</span></label>
                              <input type="text" placeholder='Enter Original Value'/>
                           </div>
                        </div>                        
                        <div class="col-lg-3 col-sm-6 col-12">
                           <div class="form-group">
                              <label>Offer Value <span>*</span></label>
                              <input type="text" placeholder='Enter Offer Value'/>
                           </div>
                        </div>      
                        
                        <div class="col-lg-3 col-sm-4 col-12">
                           <div class="form-group">
                              <label>Cancel Time(24 hr Format) <span>*</span></label>
                              <select class="select">
                                 <option>Grains & Pulses </option>
                                 <option>Fruit & Tree Nuts </option>
                                 <option>Vegetables </option>
                                 <option>Herbs & Spices </option>
                                 <option>Diary Farming </option>
                              </select>
                           </div>
                        </div>
                        <div class="col-lg-3 col-sm-4 col-12">
                           <div class="form-group">
                              <label>Cancel Days (Weekly Subscription) <span>*</span></label>
                              <select class="select">
                                 <option>Acorn Squash</option>
                                 <option>Alfalfa Sprouts</option>
                                 <option>Banana Squash</option>
                                 <option>Bean Sprouts</option>
                                 <option>Beet Greens (Chard)</option>
                              </select>
                           </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                           <div class="form-group">
                              <label>Service Fee(optional)</label>
                              <input type="text" placeholder='Enter Service Fee'/>
                           </div>
                        </div>                        
                        {/* <div class="col-lg-3 col-sm-6 col-12">
                           <div class="form-group">
                              <label>Special Fee(optional)</label>
                              <input type="text" placeholder='Enter Special Fee'/>
                           </div>
                        </div>      */}
                        <div class="col-lg-3 col-sm-6 col-12">
                           <div class="form-group">
                              <label>Packing Fee(optional)</label>
                              <input type="text" placeholder='Enter Packing Fee'/>
                           </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                           <div class="form-group">
                              <label>Start Date <span>*</span></label>
                              <input type="Date" placeholder='Enter Start Date'/>
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-lg-4 col-sm-4 col-5">
                           <a href="/ProductAvailablity" class="btn btn-back"><span class="material-icons-outlined">keyboard_double_arrow_left</span> Previous</a>
                        </div>
                        <div class="col-lg-8 col-sm-12 col-7 cb-txet-right">
                           <a href="/ProductDraft" class="btn btn-submit">Save</a>
                           <a href="/ProductPreview" class="btn btn-next cb-ml-4">Preview</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            
         </div>
      </div>
   </div>

 </>
  ); 
} 
export default SubscriptionInformation;