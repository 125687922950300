import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
function StepsToPromote() {
   return( 

   <>
    <Header />
        <div class="steps-to-promote">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12">
                    <h2>Steps To Promote & Earn More</h2>   
                    </div> 
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-4 align-self-center  wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
                        <div class="about-content lt-view">
                            <img src="../assets/images/step_1.png" alt="Influencers & Affiliates Network" />
                        </div>
                    </div>
                    <div class="col-lg-2 align-items-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">                    
                       <div class="steps_list text-center">  
                        <h5>01</h5>  
                        {/* <h6>Steps</h6>    */}
                        </div>                           
                    </div>
                    <div class="col-lg-6 align-items-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
                        <div class="sec-title step_title mb-20 mt-20">         
                            <h4>Paylink for instant online payments</h4>    
                        </div> 
                        <div class="about-content mb-view">
                            <img src="https://storage.googleapis.com/bodefaults/businessweb/influencers_affiliates.png" alt="Influencers & Affiliates Network" />
                        </div>
                        <div class="step_content">   
                            <div class="step">                         
                               <div> <span>1</span><p>Instant payments</p>  </div>      
                               <div><span>2</span><p>Pickup</p>        </div> 
                               <div><span>3</span><p>Pickup</p>  </div>
                               <div><span>4</span><p>Delivery</p>  </div>
                            </div>           
                        </div>                           
                    </div>
                </div>
            </div>              
        </div>


        <div class="steps-to-promote steps-bg">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 align-items-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
                        <div class="sec-title step_title mb-20 mt-20">         
                            <h4>Customer referrals to reach more</h4>    
                        </div> 
                        <div class="about-content mb-view">
                            <img src="https://storage.googleapis.com/bodefaults/businessweb/influencers_affiliates.png" alt="Influencers & Affiliates Network" />
                        </div>
                        <div class="step_content">   
                            <div class="step">                         
                            <div><span>1</span><p>Reach 10,000+ customer’s instantly</p>     </div>   
                            <div><span>2</span><p>No papers & Wider Reach</p>    </div>     
                            <div> <span>3</span><p>More Brand Visibility</p>  </div>
                            <div> <span>3</span><p>Sell more</p>  </div>
                            </div>           
                        </div>                           
                    </div>
                    <div class="col-lg-2 align-items-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">                    
                       <div class="steps_list text-center">  
                        <h5>02</h5>  
                        </div>                           
                    </div>
                    <div class="col-lg-4 align-self-center  wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
                        <div class="about-content lt-view">
                            <img src="../assets/images/step_1.png" alt="Influencers & Affiliates Network" />
                        </div>
                    </div>
                   
                </div>
            </div>              
        </div>

        <div class="steps-to-promote">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 align-self-center  wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
                        <div class="about-content lt-view">
                            <img src="../assets/images/step_1.png" alt="Influencers & Affiliates Network" />
                        </div>
                    </div>
                    <div class="col-lg-2 align-items-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">                    
                       <div class="steps_list text-center">  
                        <h5>03</h5>    
                        </div>                           
                    </div>
                    <div class="col-lg-6 align-items-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
                        <div class="sec-title step_title mb-20 mt-20">         
                            <h4>Digital coupon code for offers</h4>    
                        </div> 
                        <div class="about-content mb-view">
                            <img src="https://storage.googleapis.com/bodefaults/businessweb/influencers_affiliates.png" alt="Influencers & Affiliates Network" />
                        </div>
                        <div class="step_content">   
                            <div class="step">                         
                            <div>  <span>1</span><p>Reach 10,000+ customer’s instantly</p> </div>       
                            <div>   <span>2</span><p>No papers & Wider Reach</p>   </div>      
                                <div> <span>3</span><p>More Brand Visibility</p>  </div>
                                <div> <span>3</span><p>Sell more</p>  </div>
                            </div>           
                        </div>                        
                    </div>
                </div>
            </div>              
        </div>


        <div class="steps-to-promote steps-bg">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 align-items-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
                        <div class="sec-title step_title mb-20 mt-20">         
                            <h4>Share catalogs to customers</h4>    
                        </div> 
                        <div class="about-content mb-view">
                            <img src="https://storage.googleapis.com/bodefaults/businessweb/influencers_affiliates.png" alt="Influencers & Affiliates Network" />
                        </div>
                        <div class="step_content">   
                            <div class="step">                         
                            <div><span>1</span><p>Learn about customer order details</p>   </div>     
                            <div> <span>2</span><p>Share catalogs & Deals</p>    </div>     
                            <div> <span>3</span><p>Learn about customer interests</p>  </div>
                            <div> <span>3</span><p>Sell more</p></div>
                            </div>           
                        </div>                           
                    </div>
                    <div class="col-lg-2 align-items-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">                    
                       <div class="steps_list text-center">  
                            <h5>04</h5>    
                        </div>                           
                    </div>
                    <div class="col-lg-4 align-self-center  wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
                        <div class="about-content lt-view">
                            <img src="../assets/images/step_1.png" alt="Influencers & Affiliates Network" />
                        </div>
                    </div>
                   
                </div>
            </div>              
        </div>

        <div class="steps-to-promote">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 align-self-center  wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
                        <div class="about-content lt-view">
                            <img src="../assets/images/step_1.png" alt="Influencers & Affiliates Network" />
                        </div>
                    </div>
                    <div class="col-lg-2 align-items-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">                    
                       <div class="steps_list text-center">  
                        <h5>05</h5>  
                          
                        </div>                           
                    </div>
                    <div class="col-lg-6 align-items-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
                        <div class="sec-title step_title mb-20 mt-20">         
                            <h4>Use to customer relationship management (CRM) data</h4>    
                        </div> 
                        <div class="about-content mb-view">
                            <img src="https://storage.googleapis.com/bodefaults/businessweb/influencers_affiliates.png" alt="Influencers & Affiliates Network" />
                        </div>
                        <div class="step_content">   
                            <div class="step">             
                            <div>  <span>1</span><p>Learn about customer order details</p> </div>       
                            <div>   <span>2</span><p>Share catalogs & Deals</p>   </div>      
                                <div> <span>3</span><p>Learn about customer interests</p>  </div>
                                <div> <span>3</span><p>Sell more</p>  </div>
                            </div>           
                        </div>                        
                    </div>
                </div>
            </div>              
        </div>



    <Footer/>
      
   </>
);
}

export default StepsToPromote;