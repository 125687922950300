
import React, { Component, useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Banner from './Banner';
import JustECommerce from './JustECommerce';
import Webstore from './Webstore';
import SellVarietyProducts from './SellVarietyProducts'; 
import GetCostBoApp from './GetCostBoApp';
import WeChargeLess from './WeChargeLess';
import GrowYourBusiness from './GrowYourBusiness';
import InfluencersAffiliates from './InfluencersAffiliates'; 
import PoweredByCostBo from './PoweredByCostBo'; 
import Iframe from './Iframe';
import HassleFreeB2BSales from './HassleFreeB2BSales';
import ProductSales from './ProductSales';
import OurVision from './OurVision';
import ProductHighlight from './ProductHighlight ';
import Banner5 from './Banner5';
import Pricing from './Pricing';

function  HomeSlideFive () {  
	return(   
	<>

	<Header />
	<Banner5 />
	<Pricing/>	
	<SellVarietyProducts/>
	<WeChargeLess />	
	<HassleFreeB2BSales/>	
	<InfluencersAffiliates />
	<GetCostBoApp/>
	<GrowYourBusiness />
	<PoweredByCostBo />
	<Footer/>

	</>
);
}
export default HomeSlideFive;




