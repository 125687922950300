import React from 'react';
import GrowYourBusinessSlider from '../../components/GrowYourBusinessSlider'
function GrowYourBusiness () {
   return( 

   <>
        <div class="cb-growyourbusiness cb_pd_20">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-5 align-items-center wow fadeInLeft animated" data-wow-duration="1s" data-wow-delay="0.2s">
                            <div class="sec-title mb-20 mt-20">   
                                <span>features</span>               
                                <h4>Powerful features to inspire customer purchase</h4>    
                                <h5>Grow your business by 10x</h5>
                            </div>                                                     
                        </div>
                        <div class="col-lg-7 wow fadeInRight animated" data-wow-duration="1s" data-wow-delay="0.2s">
                            <GrowYourBusinessSlider />
                            {/* <div class="about-content">
                               <img src="./assets/images/grow_your_business.png" alt="Images" />
                            </div> */}
                        </div>                       
                    </div>
                </div>              
            </div>
      
   </>
);
}

export default GrowYourBusiness ;