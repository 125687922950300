import React from 'react'; 
import Header from '../Header';
import SideBar from '../../../components/SideBar';

function EditPriceTaxInfo() 
{ 
return(
 <>
   <Header />     
   <SideBar />

   <div class="page-wrapper">
      <div class="content">
         <div class="row">
            <div class="col-lg-12 col-sm-12 col-12 mb-10">
               <div class="cb-business-header">
                  <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg" alt='Logo'/>
                  <h6>Plant Science</h6>
               </div>
            </div> 
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="card">
                     <div class="page-title-process">
                        <div class="card-header cb-display-flex">                     
                           <h4>Anti Blemish Cream - 20g</h4>
                        </div>
                     </div>
                  <div class="card-body">
                     <div class="row">
                        <div class="col-lg-12 col-sm-12 col-12">
                           <ul class="nav nav-tabs cb-nav-tabs">
                              <li><a class="active1" href="/EditProduct"><span class="material-icons-outlined">inventory_2</span>Product Detail</a></li>
                              <li><a class="active1" href="/EditCategory"><span class="material-icons-outlined">inventory</span>Specification</a></li>
                              <li><a class="active"  href="/EditPriceTaxInfo"><span class="material-icons-outlined">request_quote</span>Pricing</a></li>
                              <li><a href="/EditMeasurementDeliveryMethod"><span class="material-icons-outlined">view_in_ar</span>Measurement Info</a></li>
                              <li><a href="/EditProductAvailablity"><span class="material-icons-outlined">assignment</span>Availablity</a></li>
                              {/* <li><a href="/SubscriptionInformation"><span class="material-icons-outlined">people</span>Subscription</a></li> */}
                           </ul>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12 store-tab-title">
                           <h5>Price Details</h5>
                        </div>    
                        <div class="col-lg-4 col-sm-4 col-12">
                           <div class="form-group form-group-edit">
                              <label>Currency <span>*</span></label>
                              <input type="text" value='₹ 380'/>
                           </div>
                        </div>
                        <div class="col-lg-4 col-sm-4 col-12">
                           <div class="form-group form-group-edit">
                              <label>Original Price <span>*</span></label>
                              <input type="text" value='₹ 342'/>
                           </div>
                        </div>
                        
                        <div class="col-lg-4 col-sm-4 col-12">
                           <div class="form-group form-group-edit">
                              <label>Offer Price <span>*</span></label>
                              <input type="text" value='10%'/>
                           </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12 store-tab-title"><h6>Tax and GST Information</h6></div>
                        <div class="col-lg-4 col-sm-4 col-12">
                           <div class="form-group form-group-edit">
                              <label class="pd-display">Taxes Included <span>*</span></label>
                              <input id="Yes" type="radio" name="user" value="Yes"/>
                              <label for="Yes" class="mr-2"><span></span>Yes</label>
                              <input id="No" type="radio" name="user" value="No"/>
                              <label for="No"><span></span>No</label>
                           </div>
                        </div>
                        <div class="col-lg-4 col-sm-4 col-12">
                           <div class="form-group form-group-edit">
                              <label>GST/HSN code <span>*</span></label>
                              <input type="text" value='33AABCU9603R1ZU'/>
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-lg-4 col-sm-4 col-5">
                           <a href="/EditCategory" class="btn btn-back"><span class="material-icons-outlined">keyboard_double_arrow_left</span> Previous</a>
                        </div>
                        <div class="col-lg-8 col-sm-12 col-7 cb-txet-right">
                           <a href="/ProductDraft" class="btn btn-submit">Save</a>
                           <a href="/EditMeasurementDeliveryMethod" class="btn btn-next cb-ml-4">Preview & Submit</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            
         </div>
      </div>
   </div>

 </>
  ); 
} 
export default EditPriceTaxInfo;