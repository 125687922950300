import React from 'react'; 
import Header from '../Dashboard/Header';
import SideBarBusiness from '../../components/SideBarBusiness'; 

function Draft() 
{ 
return(
 <>
   <Header />     
   <SideBarBusiness />

   <div class="page-wrapper">
      <div class="content">
         <div class="row">
            <div class="col-lg-12 col-sm-12 col-12">            
               <div class="card">
                  <div class="page-title-process">
                     <div class="card-header">                     
                        <h4>Business Dashboard</h4>
                        <ul class="business_list">
                           <li><p><strong>3 Businesses</strong> in draft status. Please complete the details and submit for approval</p></li>
                           <li><p><strong>5 Businesses</strong> rejected by costBo’s CMS team. Please review</p></li>
                        </ul>
                     </div>
                  </div>                           
                  <div class="card-body">
                     <div class="cb-inventory-tab-head">
                        <ul class="cb-inventory-nav-tabs cb-inventory-nav-tabs-business">
                           <li><a href="/Active">Active (7)</a></li>
                           <li><a href="/PendingApproval">Pending Approval (4)</a></li>
                           <li><a class="active" href="/Draft">Draft (3)</a></li>
                           <li><a href="/Rejected">Rejected (5)</a></li>
                        </ul>
                        <p>Following businesses are in draft status. 
Please click on the business logo/name to complete the details and submit for approval. Once submitted our agents will review and approve the business within 48 hours. 
</p>
                     </div>
                     <ul className="choose-business">   
                        <li class="add-choose-business-link">
                           <a href='/StoreInfo'>
                              <div className="choose-business-add-icon">     
                                 <img src="./assets/images/add_product.svg" alt="Product Name" />
                              </div>
                              <div className="choose-business-add-title">
                                 <h6>Add Business</h6>                             
                              </div>                           
                           </a>
                        </li>                   
                        
                        <li>
                           <a href='#'>
                              <div className="choose-business-product-media">
                                 <a className="choose-business-product-image" >
                                    <img src="https://storage.googleapis.com/bo3151920215/business/13cab95b30c44d6383c0fb08fedb8289/logo.jpeg" alt="Product Name" />
                                 </a>
                              </div>
                              <div className="choose-business-product-content">
                                 <a>Lucasta Tea Products</a>
                              </div>
                           </a>
                        </li>
                        <li>
                           <a href='#'>
                              <div className="choose-business-product-media">
                                 <a className="choose-business-product-image" >
                                    <img src="https://storage.googleapis.com/bo3151920215/business/c862b02807c74b2b9413b181ffd28e7c/logo.jpeg" alt="Product Name" />
                                 </a>
                              </div>
                              <div className="choose-business-product-content">
                                 <a>The Entrepreneur Brand Store</a>
                              </div>
                           </a>
                        </li>
                        <li>
                           <a href='#'>
                              <div className="choose-business-product-media">
                                 <a className="choose-business-product-image" >
                                    <img src="https://storage.googleapis.com/bo3151920215/business/cfa6a18dfa1048228899c2c427c7d781/logo.jpeg" alt="Product Name" />
                                 </a>
                              </div>
                              <div className="choose-business-product-content">
                                 <a>Millet Maagic Meal</a>
                              </div>
                           </a>
                        </li>
                     </ul>                       
                  </div>
               </div>
            </div>
      
         </div>
      </div>
   </div>

 </>
  ); 
} 
export default Draft;