import React from 'react';
function Header() {
return( 
<>
      <div class="header">
         <div class="header-left db-head active">
            <a href="/" class="logo">
               <img src="./assets/images/logo.png" alt="" />
            </a>
         </div>
         <a id="mobile_btn" class="mobile_btn" href="#sidebar">
            <span class="bar-icon">
            <span></span>
            <span></span>
            <span></span>
            </span>
         </a>
         <div class="user-img-mobile">
            <img src="../assets/images/dashboard/user-ava.png" alt="" />
            <p class="status online"></p>
         </div>
         <div class="cb-top-header">
            <div class=" cb-page-title">
               <ul class="cb-dashboard-nav">
                  <li><a href="/BusinessListing">Dashboard</a></li>
                  <li><a href="/ProductActive">Catalog</a></li>
                  <li><a href="/Inventory">Inventory</a></li>
                  <li><a href="#">Reports</a></li>
               </ul>
            </div>
            <ul class="nav user-menu">
               <li>
                  <a href="#">
                     <img src="./assets/images/dashboard/search.png" alt="img"/>
                     {/* <sub class="badge rounded-pill orange">4</sub> */}
                  </a>
               </li>
               <li>
                  <a href="#">                  
                     <img src="./assets/images/dashboard/notification.png" alt="img"/>
                     <sub class="badge rounded-pill green">13</sub>
                  </a>
               </li>
               <li>
                  <a href="#">
                     <img src="./assets/images/dashboard/settings-1.png" alt="img"/>
                     <sub class="badge rounded-pill red">25</sub>
                  </a>
               </li>
               <li class="dp-open">
                  <a href="#" class="nav-link">
                     <div class="user-img">
                        <img src="../assets/images/dashboard/user-ava.png" alt="" />
                        <p class="status online"></p>
                        <div class="user-info">
                           <h6>Rahul Sharma</h6>
                           <p>Log Out</p>
                        </div>
                     </div>
                  </a>
               </li>
            </ul>
         </div>
     </div>

</>
);
}
export default Header;