
import React from 'react';
       
function Footer () {
   return (
  
<>
         <footer>
            <div class="footer-start-selling wow fadeIn animated" data-wow-duration="1s" data-wow-delay="0.25s">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 text-left">
                           <a class="logo" href="index.html">
                              <img src="./assets/images/logo.png" class="img-responsive" alt="CostBo" />
                           </a>
                        </div>
                        <div class="col-lg-8">
                            <div class="copyright text-right">
                                <a href='#'>Your profitable online journey is just few minutes away!</a>
                                <button class="sell_btn">Start Selling</button>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
            <div class="footer-top wow fadeIn animated" data-wow-duration="1s" data-wow-delay="0.25s">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 text-left">
                            <p>Any questions/clarifications, we are here to help!</p>
                        </div>
                        <div class="col-lg-6">
                            <div class="copyright cr-mt-3 text-right ">
                                <a href="mailto:ecommerce@costbo.com"><span class="material-icons">email</span> ecommerce@costbo.com</a>
                                <a href='https://wa.me/8088422611'><i class="fab fa-whatsapp" aria-hidden="true"></i> +91 8088422611</a>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="container">                    
                    <div class="row">                        
                        <div class="col-lg-12">
                            <div class="copyright text-center ">
                                <p>© 2023 CostBo Services Private Ltd, All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div class="backdrop"></div>
    <a class="backtop fas fa-arrow-up" href="#"></a>
</>

);
}

export default Footer;




