import React from 'react';

function  Header () {
   return (
   <>
   

   
   <header>
    <nav class="costbo-business-demo wow slideInDown" data-wow-duration="0.75s" data-wow-delay="0s">
      <div class="container">
        <div class="row">
        <div class="top-header-content">
            <div class="col-md-3 header_mb_menu">
              <a class="logo" href="/">
                <img src="../assets/images/logo.png" class="img-responsive" alt="CostBo Logo" />
              </a>
              </div>
              <div class="col-md-7 bc-none-mb">
                <ul class="cb-navbar-nav">               
                  <li><a href="#HassleFreeB2BSales">Hassle Free B2B Sales</a></li>
                  <li><a href="/BusinessListing">Business Dashboard</a></li>
                  <li><a href="#pricing">Pricing</a></li>
                  <li><a href="#cb-download-app">Get CostBo App</a></li>
                </ul>
              </div>
              <div class="col-md-2 costbo-business ">
                {/* <a href="https://business-dashboard.costbo.com/" class="nav-button" target="_blank">Get Started</a> */}
                <nav role="navigation" class="primary-navigation">
                  <ul>
                    <li><a href="https://business-dashboard.costbo.com/" class="nav-button">Get Started <span class="material-icons-outlined">arrow_drop_down</span></a>
                      <ul class="dropdown">
                        <li><a href="https://business.costbo.com/RegisterNew">Register Your Business</a></li>
                        <li class="lst-or">Or</li>
                        <li><a href="#">Reach Us for More Info</a></li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
              
              <a href='/HomeMenu' class="header_mb_menu_icon"><span class="material-icons-outlined">menu</span> </a> 
              </div>
            {/* <a href='' class="header_mb_profile"><span class="material-icons-outlined">person_outline</span></a> */}
            
        </div>
      </div>
    </nav>

  </header>
   

  </>
);
}

export default Header;