import React from 'react'; 
import Header from '../Catalog/Header';
import SideBar from '../../components/SideBar';

function Inventory() 
{ 
return(
 <>
   <Header />     
   <SideBar />

      
      <div class="page-wrapper">
         <div class="content">    
         <div class="row">
               <div class="col-lg-6 col-sm-6 col-12">
                  <div class="cb-business-header">
                     <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg" alt='Logo'/>
                     <h6>Plant Science</h6>
                  </div>
               </div> 
               <div class="col-lg-6 col-sm-6 col-12">
                  <div class="dash-widget">
                     <div class="dash-widget-content">
                        {/* <div class="dash-widgetimg">
                           <img src="./assets/images/dash1.svg" alt="img" />
                        </div> */}
                        <div class="dash-widgetcontent">
                           <h6>Total Product</h6>
                           <h5>145</h5>
                        </div>
                     </div>
                     <div class="dash-widget-content">
                        {/* <div class="dash-widgetimg">
                           <img src="./assets/images/dash2.svg" alt="img" />
                        </div> */}
                        <div class="dash-widgetcontent">
                           <h6>Total Sales</h6>
                           <h5>Rs 254385</h5>
                        </div>
                     </div>
                     <div class="dash-widget-content wdth-100">
                        {/* <div class="dash-widgetimg dash-widgetimg1">
                           <img src="./assets/images/store.svg" alt="img" />
                        </div> */}
                        <div class="dash-widgetcontent">
                           <h5>Ecommerce enabled</h5>
                           <div class="toggle-btn toggle-btn1">
                              <input type="checkbox" id="toggle-btn-5"/>
                              <label for="toggle-btn-5"></label>
                           </div>
                        </div>
                     </div>
                     
                  </div>
               </div>
            </div>
            <div class="row">               
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="card-p-10">
                     <div class="row product-header">                   
                        <div class="col-md-12">     
                           <div class="card-header content-header">
                              <div>                    
                                 <h4>Active Inventory</h4>
                              </div>
                              {/* <div>
                                 <a href="#" class="btn btn-add-business btn-postion">
                                 <span class="material-icons-outlined">inventory</span>
                                 <div class="text-mb">Update Product</div></a>
                              </div> */}
                           </div>  
                           <div class="cb-inventory-tab-head pd-mt-10">
                              <ul class="cb-inventory-nav-tabs cb-inventory-nav-tabs-business cb-inventory-nav-tabs-product">
                                 <li><a href="/OutofStock">Out of Stock - 17</a></li>
                                 <li><a href="/LowStock">Low Stock - 12</a></li>
                                 <li><a href="/PriceUpdate">Price Update - 24</a></li>
                                 <li><a class="active" href="/Inventory">All Stock - 53</a></li>
                                 <li><a href="/Empty">Empty - 0</a></li>
                              </ul>     
                           </div>
                        </div>
                        <div class="col-md-12 inventory-header">
                           <div class="head-help">                    
                              <p>
                                 How to update Stock?
                                 <a class="tooltip-tags" data-gloss=" The below products are ‘Out of Stock’ and are not orderable by the customer. Please update the ‘AVAILABLE QTY’."><span class="material-icons">info</span></a>
                              </p>
                           </div>
                           <div>
                              <a href="#" class="btn btn-add-business">
                              <span class="material-icons-outlined">inventory</span>
                              <div class="text-mb">Update Inventory</div></a>
                           </div>
                        </div>  
                        <div class="col-md-5 cb-inventory-tab-title">         
                           <h5>All Stock <span>- 53</span></h5>
                           <i class="fas fa-search mb-search-icon"></i>
                        </div>
                        <div class="col-md-7 cb-inventory-tab-title"> 
                           <div class="form-group form-group-inventory">
                              <label>Product Category</label>
                              <select class="select">
                                 <option>Clothing & Accessories</option>
                                 <option>Home, Kitchen & Garden</option>
                                 <option>Laundry Supplies</option>
                                 <option>Air Fresheners</option>
                              </select>
                           </div>
                        
                           <div class="form-group form-group-inventory">
                              <label>Product Sub Category</label>
                              <select class="select">
                                 <option>All</option>
                                 <option>Shoe Care</option>
                                 <option>Leather Shoes</option>
                                 <option>Sports Shoes</option>
                                 <option>Laundry Detergent</option>
                              </select>
                           </div>    
                           <div class="form-group form-group-inventory cb-mb-none">
                              <input type="text" placeholder="Search Product" class="search-mt-20" id="inputFname"/>
                              <i class="fas fa-search inventory-search-icon"></i>
                           </div>
                        </div> 

                        
                        <div class="col-lg-12 col-sm-12 col-12">
                           <div class="cb-inventory-table cb-inventory-table-mb">
                              <div class="cb-inventory-table-head">
                                 <div class="col-md-1 cb-col-inv-1">
                                  {/*   <div class="checkbox">
                                       <label><input type="checkbox" value="check1"/> </label>
                                    </div>*/}
                                 </div> 
                                 <div class="col-md-5 cb-col-inv-5">
                                    <h4>PRODUCT </h4>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-1 ps-left">
                                    <h4>AVAILABILITY</h4>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 ps-left-1">
                                    <h4>AVAILABLE QTY </h4>
                                 </div>   
                                 <div class="col-md-2 cb-col-inv-1-2">
                                    <h4>min qty <br/><span>per order</span></h4>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2">
                                    <h4>Max qty  <br/><span>per order</span> </h4>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-4">
                                    <h4>ALERT <br/>QTY </h4>
                                 </div>   
                                 <div class="col-md-2 cb-col-inv-1-2">
                                    <h4>ORIGINAL PRICE</h4>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2">
                                    <h4>SALE <br/>PRICE </h4>
                                 </div>                           
                                 <div class="col-md-2 cb-col-inv-1-4">
                                    <h4>GST <br/>%</h4>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2">
                                    <h4>STARt SELLING ON</h4>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2">
                                    <h4>END <br/>SELLING ON</h4>
                                 </div>
                              </div>

                              <div class="cb-inventory-table-body cb-inventory-table-body-1">
                                 <div class="col-md-1 cb-col-inv-1">
                                    <div class="checkbox checkbox_rsp_1">
                                       <label><input type="checkbox" value="check1"/> </label>
                                    </div>
                                 </div>
                                 <div class="col-md-5 cb-col-inv-5">
                                    <div class="cb-inventory-product-data">
                                       <div class="cb-inventory-product-info cb-inventory-product-info-1">
                                          <h5>Liquid Shoe Polish 75ML Black</h5>
                                          <del>₹ 312</del><span>₹ 300</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-1 cb-col-inv-1-2-res cb-ps-15 cb-inv-header-body">
                                    <h4>AVAILABILITY</h4>
                                    <div class="toggle_btn">
                                       <input type="checkbox" class="" id="switch"/><label for="switch">Toggle</label>
                                    </div>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inv-header-body ps-left-1">
                                    <h4>AVAILABLE QTY</h4>
                                    <input type="text" value="2" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                                    <h4>MIN QTY PER ORDER</h4>
                                    <input type="text" value="1" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                                    <h4>MAX QTY PER ORDER</h4>
                                    <input type="text" value="200" />
                                 </div>
                                 
                                 <div class="col-md-2 cb-col-inv-1-1 cb-ps-15 cb-inventory-input cb-inv-header-body">
                                    <h4>ALERT QTY</h4>
                                    <input type="text" value="57" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inv-header-body">
                                    <h4>ORIGINAL PRICE</h4>
                                    <input type="text" value="2" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inv-header-body">
                                    <h4>SALE PRICE</h4>
                                    <input type="text" value="2" />
                                 </div> 
                                 <div class="col-md-2 cb-col-inv-1-1 cb-ps-15 cb-inventory-input cb-inv-header-body">
                                    <h4>GST %</h4>
                                    <input type="text" value='5%'/>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-inventory-input-1 cb-inv-header-body">
                                    <h4>STARING SELLING ON</h4>
                                    <input type="date"/>
                                 </div>   
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-inventory-input-1 cb-inv-header-body">
                                    <h4>END SELLING ON</h4>
                                    <input type="date"/>
                                 </div>                      
                              </div>

                              <div class="cb-inventory-table-body cb-inventory-table-body-1">
                                 <div class="col-md-1 cb-col-inv-1">
                                    <div class="checkbox checkbox_rsp_1">
                                       <label><input type="checkbox" value="check1"/> </label>
                                    </div>
                                 </div>
                                 <div class="col-md-5 cb-col-inv-5">
                                    <div class="cb-inventory-product-data">
                                       <div class="cb-inventory-product-info cb-inventory-product-info-1">
                                          <h5>Relaxing Lavender Pocket Gel Freshner</h5>
                                          <del>₹ 212</del><span>₹ 200</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-1 cb-col-inv-1-2-res cb-ps-15 cb-inv-header-body">
                                    <h4>AVAILABILITY</h4>
                                    <div class="toggle_btn">
                                       <input type="checkbox" class="" id="switch"/><label for="switch">Toggle</label>
                                    </div>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 ps-left-1 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>AVAILABLE QTY</h4>
                                    <input type="text" value="5" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-inventory-input-bdr cb-ps-15 cb-inv-header-body">
                                    <h4>MIN QTY PER ORDER</h4>
                                    <input type="text" value="5" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-inventory-input-bdr cb-ps-15 cb-inv-header-body">
                                    <h4>MAX QTY PER ORDER</h4>
                                    <input type="text" value="150" />
                                 </div>
                                 
                                 <div class="col-md-2 cb-col-inv-1-1 cb-ps-15 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>ALERT QTY</h4>
                                    <input type="text" value="280" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>ORIGINAL PRICE</h4>
                                    <input type="text" value="212" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>Sale PRICE</h4>
                                    <input type="text" value="200" />
                                 </div> 
                                 <div class="col-md-2 cb-col-inv-1-1 cb-ps-15 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>GST %</h4>
                                    <input type="text" value='10%'/>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-inventory-input-1  cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>STARING SELLING ON</h4>
                                    <input type="date"/>
                                 </div>   
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-inventory-input-1  cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>END SELLING ON</h4>
                                    <input type="date"/>
                                 </div>                     
                              </div>


                              <div class="cb-inventory-table-body cb-inventory-table-body-1">
                                 <div class="col-md-1 cb-col-inv-1">
                                    <div class="checkbox checkbox_rsp_1">
                                       <label><input type="checkbox" value="check1"/> </label>
                                    </div>
                                 </div>
                                 <div class="col-md-5 cb-col-inv-5">
                                    <div class="cb-inventory-product-data">
                                       <div class="cb-inventory-product-info cb-inventory-product-info-1">
                                          <h5>Max Freshocare Liqud Detergent</h5>
                                          <del>₹ 150</del><span>₹ 130</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-1 cb-col-inv-1-2-res cb-ps-15 cb-inv-header-body">
                                    <h4>AVAILABILITY</h4>
                                    <div class="toggle_btn">
                                       <input type="checkbox" class="" id="switch"/><label for="switch">Toggle</label>
                                    </div>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 ps-left-1 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>AVAILABLE QTY</h4>
                                    <input type="text" value="1" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-inventory-input-bdr cb-ps-15 cb-inv-header-body">
                                    <h4>MIN QTY PER ORDER</h4>
                                    <input type="text" value="2" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-inventory-input-bdr cb-ps-15 cb-inv-header-body">
                                    <h4>MAX QTY PER ORDER</h4>
                                    <input type="text" value="130" />
                                 </div>
                                 
                                 <div class="col-md-2 cb-col-inv-1-1 cb-ps-15 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>ALERT QTY</h4>
                                    <input type="text" value="90" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>ORIGINAL PRICE</h4>
                                    <input type="text" value="150" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>Sale PRICE</h4>
                                    <input type="text" value="130" />
                                 </div> 
                                 <div class="col-md-2 cb-col-inv-1-1 cb-ps-15 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>GST %</h4>
                                    <input type="text" value='8%'/>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-inventory-input-1  cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>STARING SELLING ON</h4>
                                    <input type="date"/>
                                 </div>   
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-inventory-input-1  cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>END SELLING ON</h4>
                                    <input type="date"/>
                                 </div>                     
                              </div>

                              <div class="cb-inventory-table-body cb-inventory-table-body-1">
                                 <div class="col-md-1 cb-col-inv-1">
                                    <div class="checkbox checkbox_rsp_1">
                                       <label><input type="checkbox" value="check1"/> </label>
                                    </div>
                                 </div>
                                 <div class="col-md-5 cb-col-inv-5">
                                    <div class="cb-inventory-product-data">
                                       <div class="cb-inventory-product-info cb-inventory-product-info-1">
                                          <h5>Liquid Shoe Polish 75ML Black</h5>
                                          <del>₹ 120</del><span>₹ 100</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-1 cb-col-inv-1-2-res cb-ps-15 cb-inv-header-body">
                                    <h4>AVAILABILITY</h4>
                                    <div class="toggle_btn">
                                       <input type="checkbox" class="" id="switch"/><label for="switch">Toggle</label>
                                    </div>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 ps-left-1 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>AVAILABLE QTY</h4>
                                    <input type="text" value="1" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-inventory-input-bdr cb-ps-15 cb-inv-header-body">
                                    <h4>MIN QTY PER ORDER</h4>
                                    <input type="text" value="2" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-inventory-input-bdr cb-ps-15 cb-inv-header-body">
                                    <h4>MAX QTY PER ORDER</h4>
                                    <input type="text" value="140" />
                                 </div>
                                 
                                 <div class="col-md-2 cb-col-inv-1-1 cb-ps-15 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>ALERT QTY</h4>
                                    <input type="text" value="170" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>ORIGINAL PRICE</h4>
                                    <input type="text" value="120" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>Sale PRICE</h4>
                                    <input type="text" value="100" />
                                 </div> 
                                 <div class="col-md-2 cb-col-inv-1-1 cb-ps-15 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>GST %</h4>
                                    <input type="text" value='15%'/>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-inventory-input-1  cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>STARING SELLING ON</h4>
                                    <input type="date"/>
                                 </div>   
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-inventory-input-1  cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>END SELLING ON</h4>
                                    <input type="date"/>
                                 </div>                     
                              </div>

                              <div class="cb-inventory-table-body cb-inventory-table-body-1">
                                 <div class="col-md-1 cb-col-inv-1">
                                    <div class="checkbox checkbox_rsp_1">
                                       <label><input type="checkbox" value="check1"/> </label>
                                    </div>
                                 </div>
                                 <div class="col-md-5 cb-col-inv-5">
                                    <div class="cb-inventory-product-data">
                                       <div class="cb-inventory-product-info cb-inventory-product-info-1">
                                          <h5>Liquid Shoe Polish 75ML Black</h5>
                                          <del>₹ 240</del><span>₹ 220</span>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-1 cb-col-inv-1-2-res cb-ps-15 cb-inv-header-body">
                                    <h4>AVAILABILITY</h4>
                                    <div class="toggle_btn">
                                       <input type="checkbox" class="" id="switch"/><label for="switch">Toggle</label>
                                    </div>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 ps-left-1 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>AVAILABLE QTY</h4>
                                    <input type="text" value="5" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-inventory-input-bdr cb-ps-15 cb-inv-header-body">
                                    <h4>MIN QTY PER ORDER</h4>
                                    <input type="text" value="2" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-inventory-input-bdr cb-ps-15 cb-inv-header-body">
                                    <h4>MAX QTY PER ORDER</h4>
                                    <input type="text" value="200" />
                                 </div>
                                 
                                 <div class="col-md-2 cb-col-inv-1-1 cb-ps-15 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>ALERT QTY</h4>
                                    <input type="text" value="150" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>ORIGINAL PRICE</h4>
                                    <input type="text" value="240" />
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>Sale PRICE</h4>
                                    <input type="text" value="220" />
                                 </div> 
                                 <div class="col-md-2 cb-col-inv-1-1 cb-ps-15 cb-inventory-input cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>GST %</h4>
                                    <input type="text" value='12%'/>
                                 </div>
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-inventory-input-1  cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>STARING SELLING ON</h4>
                                    <input type="date"/>
                                 </div>   
                                 <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-inventory-input-1  cb-inventory-input-bdr cb-inv-header-body">
                                    <h4>END SELLING ON</h4>
                                    <input type="date"/>
                                 </div>                     
                              </div>
                           </div>
                        </div>
                        
                     </div>

                     <div class="row">
                     <div class="col-md-3">
                        <div class="best_deals">
                           <div class="best_deals_brand">
                              <div class="bd_brand_log">
                              <div class="checkbox checkbox_rsp_1">
                                 <label>
                                    <input type="checkbox" value="check1"/>
                                 </label>
                                 <h6>Liquid Shoe Polish 125ML Black</h6>
                              </div>
                              <div class="cb-available">
                                 <div class="toggle_btn">
                                    <input type="checkbox" class="" id="switch1"/>
                                    <label for="switch1">Toggle</label>
                                 </div>
                              </div>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inv-header-body">
                              <h4>Ava QTY</h4>
                              <input type="text" value="2"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inv-header-body">
                              <h4>Alt QTY</h4>
                              <input type="text" value="57"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>Min QTY</h4>
                              <input type="text" value="16"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>Max QTY</h4>
                              <input type="text" value="6"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>ORI PRICE</h4>
                              <input type="text" value="8"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>Sale Price</h4>
                              <input type="text" value="15"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>Start Date</h4>
                              <input type="text" value="19"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>End Date</h4>
                              <input type="text" value="43"/>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-3">
                        <div class="best_deals">
                           <div class="best_deals_brand">
                              <div class="bd_brand_log">
                              <div class="checkbox checkbox_rsp_1">
                                 <label>
                                    <input type="checkbox" value="check1"/>
                                 </label>
                                 <h6>Liquid Shoe Polish 125ML Black</h6>
                              </div>
                              <div class="cb-available">
                                 <div class="toggle_btn">
                                    <input type="checkbox" class="" id="switch1"/>
                                    <label for="switch1">Toggle</label>
                                 </div>
                              </div>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inv-header-body">
                              <h4>Ava QTY</h4>
                              <input type="text" value="2"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inv-header-body">
                              <h4>Alt QTY</h4>
                              <input type="text" value="57"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>Min QTY</h4>
                              <input type="text" value="16"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>Max QTY</h4>
                              <input type="text" value="6"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>ORI PRICE</h4>
                              <input type="text" value="8"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>Sale Price</h4>
                              <input type="text" value="15"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>Start Date</h4>
                              <input type="text" value="19"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>End Date</h4>
                              <input type="text" value="43"/>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-3">
                        <div class="best_deals">
                           <div class="best_deals_brand">
                              <div class="bd_brand_log">
                              <div class="checkbox checkbox_rsp_1">
                                 <label>
                                    <input type="checkbox" value="check1"/>
                                 </label>
                                 <h6>Liquid Shoe Polish 125ML Black</h6>
                              </div>
                              <div class="cb-available">
                                 <div class="toggle_btn">
                                    <input type="checkbox" class="" id="switch1"/>
                                    <label for="switch1">Toggle</label>
                                 </div>
                              </div>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inv-header-body">
                              <h4>Ava QTY</h4>
                              <input type="text" value="2"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inv-header-body">
                              <h4>Alt QTY</h4>
                              <input type="text" value="57"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>Min QTY</h4>
                              <input type="text" value="16"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>Max QTY</h4>
                              <input type="text" value="6"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>ORI PRICE</h4>
                              <input type="text" value="8"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>Sale Price</h4>
                              <input type="text" value="15"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>Start Date</h4>
                              <input type="text" value="19"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>End Date</h4>
                              <input type="text" value="43"/>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-3">
                        <div class="best_deals">
                           <div class="best_deals_brand">
                              <div class="bd_brand_log">
                              <div class="checkbox checkbox_rsp_1">
                                 <label>
                                    <input type="checkbox" value="check1"/>
                                 </label>
                                 <h6>Liquid Shoe Polish 125ML Black</h6>
                              </div>
                              <div class="cb-available">
                                 <div class="toggle_btn">
                                    <input type="checkbox" class="" id="switch1"/>
                                    <label for="switch1">Toggle</label>
                                 </div>
                              </div>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inv-header-body">
                              <h4>Ava QTY</h4>
                              <input type="text" value="2"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-ps-15 cb-inventory-input cb-inv-header-body">
                              <h4>Alt QTY</h4>
                              <input type="text" value="57"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>Min QTY</h4>
                              <input type="text" value="16"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>Max QTY</h4>
                              <input type="text" value="6"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>ORI PRICE</h4>
                              <input type="text" value="8"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>Sale Price</h4>
                              <input type="text" value="15"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>Start Date</h4>
                              <input type="text" value="19"/>
                              </div>
                              <div class="col-md-2 cb-col-inv-1-2 cb-inventory-input cb-ps-15 cb-inv-header-body">
                              <h4>End Date</h4>
                              <input type="text" value="43"/>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  </div>   
               </div>
            </div>
         </div>
      </div>

 </>
  ); 
} 
export default Inventory;