import React from 'react'; 
import Header from '../Header';
import SideBar from '../../../components/SideBar';

function EditCategory() 
{ 
return(
 <>
   <Header />     
   <SideBar />

   <div class="page-wrapper">
      <div class="content">
         <div class="row">
            <div class="col-lg-12 col-sm-12 col-12 mb-10">
               <div class="cb-business-header">
                  <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg" alt='Logo'/>
                  <h6>Plant Science</h6>
               </div>
            </div> 
               {/* <div class="col-lg-4 col-sm-12 col-12">
                  <div class="card card-main-profile-process card-main-profile-process-bg">
                     <div class="card-main-profile-process-detail">
                        <div class="main-profile-process-detail">
                        <div class="main-profile-process-detail-title pd-md-0">
                              <h6>Upload Product Image</h6>
                              <p>Image upload multiple maximum of four images(100 x 75 pixels - Square 4:3 , size : 20 - 100 Kb)</p>
                           </div>
                        </div>
                        
                     </div>
                  </div>
               </div> */}
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="card">
                     <div class="page-title-process">
                        <div class="card-header cb-display-flex">                     
                           <h4>Anti Blemish Cream - 20g</h4>
                        </div>
                     </div>
                  <div class="card-body">
                     <div class="row">
                        <div class="col-lg-12 col-sm-12 col-12">
                           <ul class="nav nav-tabs cb-nav-tabs">
                              <li><a class="active1" href="/EditProduct"><span class="material-icons-outlined">inventory_2</span>Product Detail</a></li>
                              <li><a class="active" href="/EditCategory"><span class="material-icons-outlined">inventory</span>Specification</a></li>
                              <li><a href="/EditPriceTaxInfo"><span class="material-icons-outlined">request_quote</span>Pricing</a></li>
                              <li><a href="/EditMeasurementDeliveryMethod"><span class="material-icons-outlined">view_in_ar</span>Measurement Info</a></li>
                              <li><a href="/EditProductAvailablity"><span class="material-icons-outlined">assignment</span>Availablity</a></li>
                              {/* <li><a href="/SubscriptionInformation"><span class="material-icons-outlined">people</span>Subscription</a></li> */}
                          </ul>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12 store-tab-title">
                           <h5>Category </h5>
                        </div>    
                        <div class="col-lg-4 col-sm-4 col-12">
                           <div class="form-group form-group-edit">
                              <label>Business Category <span>*</span></label>
                              <select class="select">
                                 <option>Agriculture & Farming </option>
                              </select>
                           </div>
                        </div>
                        <div class="col-lg-4 col-sm-4 col-12">
                           <div class="form-group form-group-edit">
                              <label>Product Category <span>*</span></label>
                              <select class="select">
                                 <option>Grains & Pulses </option>
                              </select>
                           </div>
                        </div>
                        <div class="col-lg-4 col-sm-4 col-12">
                           <div class="form-group form-group-edit">
                              <label>Product Sub-Category</label>
                              <select class="select">
                                 <option>Acorn Squash</option>
                              </select>
                           </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12 store-tab-title"><h6>Product Specification</h6></div>
                        <div class="col-lg-5 col-sm-6 col-12">
                           <div class="form-group form-group-edit">
                              <label>Label</label>
                              <input type="text" value='All Skin Types'/>
                           </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-12">
                           <div class="form-group form-group-edit">
                              <label>Value</label>
                              <input type="text" value='pH balanced'/>
                           </div>
                        </div>
                        <div class="col-lg-1 col-sm-6 col-12">
                           <div class="page-btn">
                              <a href="#" class="btn btn-added">
                                 <img src="../assets/images/dashboard/plus.svg" alt="img"/>
                              </a>
                           </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12 store-tab-title"><h6>Reference Link</h6></div>
                        <div class="col-lg-5 col-sm-6 col-12">
                           <div class="form-group form-group-edit">
                              <label>Link Name</label>
                              <input type="text" value='Youtube'/>
                           </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-12">
                           <div class="form-group form-group-edit">
                              <label>Link</label>
                              <input type="text" value='https://www.youtube.com/'/>
                           </div>
                        </div>
                        <div class="col-lg-1 col-sm-6 col-12">
                           <div class="page-btn">
                              <a href="#" class="btn btn-added">
                                 <img src="../assets/images/dashboard/plus.svg" alt="img"/>
                              </a>
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-lg-4 col-sm-4 col-5">
                           <a href="/EditProduct" class="btn btn-back"><span class="material-icons-outlined">keyboard_double_arrow_left</span> Previous</a>
                        </div>
                        <div class="col-lg-8 col-sm-12 col-7 cb-txet-right">
                           <a href="/ProductDraft" class="btn btn-submit">Save</a>
                           <a href="/EditPriceTaxInfo" class="btn btn-next cb-ml-4">Preview & Submit</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            
         </div>
      </div>
   </div>

 </>
  ); 
} 
export default EditCategory;