import React from 'react'; 
import Header from '../Catalog/Header';
import SideBar from '../../components/SideBar';

function Cancelled() 
{ 
return(
 <>
   <Header />     
   <SideBar />

   <div class="page-wrapper">
      <div class="content">
         <div class="row">
            <div class="col-lg-5 col-sm-6 col-12">
               <div class="cb-business-header">
                  <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg" alt='Logo'/>
                  <h6>Plant Science</h6>
               </div>
            </div> 
            <div class="col-lg-7 col-sm-6 col-12">
               <div class="dash-widget">
                  <div class="dash-widget-content">
                     <div class="dash-widgetimg">
                        <img src="./assets/images/dash1.svg" alt="img" />
                     </div>
                     <div class="dash-widgetcontent">
                        <h5>145</h5>
                        <h6>Total Order</h6>
                     </div>
                  </div>
                  <div class="dash-widget-content">
                     <div class="dash-widgetimg">
                        <img src="./assets/images/dash2.svg" alt="img" />
                     </div>
                     <div class="dash-widgetcontent">
                        <h5>Rs 254385</h5>
                        <h6>Total Sales</h6>
                     </div>
                  </div>
                  <div class="dash-widget-content wdth-100">
                     <div class="dash-widgetimg dash-widgetimg1">
                        <img src="./assets/images/store.svg" alt="img" />
                     </div>
                     <div class="dash-widgetcontent">
                        <h5>Ecommerce enabled</h5>
                        <div class="toggle-btn toggle-btn1">
                           <input type="checkbox" id="toggle-btn-5"/>
                           <label for="toggle-btn-5"></label>
                        </div>
                     </div>
                  </div>
                  
               </div>
            </div>
         </div>  
         
         <div class="row"> 
            <div class="col-lg-12 col-sm-12 col-12">
               <div class="card-p-10">
                  <div class="row product-header">                   
                     <div class="col-md-12">     
                        <div class="card-header content-header">
                           <div>                    
                              <h4>Manage Orders</h4>
                           </div>
                           {/* <div><a href="/AddProduct" class="btn btn-add-business"><span class="material-icons-outlined">add</span> Add Product</a></div> */}
                        </div>  
                     </div>         
                     <div class="col-lg-12 col-sm-12 col-12">
                        <div class="cb-inventory-tab-head">
                              <ul class="cb-inventory-nav-tabs nb-none">
                                 <li><a href="/Pending">Pending Approval  - 11</a></li>
                                 <li><a href="/ReadytoShip">Ready to Ship - 90</a></li>
                                 <li><a href="/InTransit">InTransit - 75</a></li>
                                 <li><a href="/Delivered">Delivered - 28</a></li>
                                 <li><a  class="active" href="/Cancelled">Canceled - 10</a></li>
                                 <li><a href="/AllOrders">All Orders - 156</a></li>
                              </ul>
                              <div class="arrow-tab">
                                 <a href="/Pending" class="active">
                                    <span class="arrow-tab__inner">
                                    <span class="arrow-tab__title">Pending Approval  - 11</span>
                                    </span>
                                 </a>
                                 <a href="/ReadytoShip" class="active">
                                    <span class="arrow-tab__inner">
                                    <span class="arrow-tab__title">Ready to Ship - 90</span>
                                    </span>
                                 </a>
                                 <a href="/InTransit" class="active">
                                    <span class="arrow-tab__inner">
                                    <span class="arrow-tab__title">InTransit - 75</span>
                                    </span>
                                 </a>
                                 <a href="/Delivered" class="active">
                                    <span class="arrow-tab__inner">
                                    <span class="arrow-tab__title">Delivered - 28</span>
                                    </span>
                                 </a>
                                 <a href="/Cancelled" class="active">
                                    <span class="arrow-tab__inner">
                                    <span class="arrow-tab__title">Canceled - 10</span>
                                    </span>
                                 </a>
                                 <a href="/AllOrders">
                                    <span class="arrow-tab__inner">
                                    <span class="arrow-tab__title">All Orders - 156</span>
                                    </span>
                                 </a>
                              </div>
                           </div>
                        </div>     
                        <div class="col-md-7 cb-manage-order-tab-title">           
                           <h5>Cancelled <span>- 10 order</span></h5>                           
                           {/* <p>List of orders that are canceled by you.</p> */}
                        </div>
                        
                        <div class="col-lg-12 col-sm-12 col-12 cb-col-order-flex">
                           <div class="cb-manage-order-list">
                              <div class="col-md-3 cb-col-order-3 cb-col-order-3-2">
                                 <div class="cb-manage-order-info">
                                    <h5><span class="material-icons-outlined">sticky_note_2</span>Order# CBW994</h5>
                                    <p>02 Nov, 02:35 PM </p>
                                 </div>
                              </div>
                              <div class="col-md-3 cb-col-order-3 cb-col-order-3-2">
                                 <div class="cb-manage-order-info">
                                    <h5><span class="material-icons">person</span>Sri Vaishnavi</h5>
                                    <h6><span class="material-icons">credit_card</span>Rs 14682 </h6>
                                    <h6><span class="material-icons">shopping_cart</span>14 </h6>
                                 </div>
                              </div>
                              <div class="col-md-6 cb-col-order-6 cb-col-order-6-1">
                                 <div class="cb-manage-order-info">
                                    <div class="cb-manage-order-train">
                                       <div class="cb-manage-order-train-item completed">
                                          <span class="material-icons">check</span>
                                          <h4>Created</h4>
                                       </div>
                                       <div class="cb-manage-order-train-item completed">
                                          <span class="material-icons">check</span>
                                          <h4>Approved</h4>
                                       </div>
                                       <div class="cb-manage-order-train-item completed">
                                          <span class="material-icons">check</span>
                                          <h4>Shipped</h4>
                                       </div>
                                       <div class="cb-manage-order-train-item">
                                          <span class="material-icons">check</span>
                                          <h4>Delivered</h4>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-2 cb-col-order-2 dp-none">
                                 <div class="cb-manage-order-process">
                                   <h6>Out of Stock</h6>
                                 </div>
                              </div>
                              <div class="col-lg-4 cb-col-order-4">
                                 <div class="cb-manage-order-btn">
                                    <a href="#" class="btn btn-more">More</a>
                                    <a href="#" class="btn btn-track">Track</a>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="col-lg-12 col-sm-12 col-12 cb-col-order-flex">
                           <div class="cb-manage-order-list">
                              <div class="col-md-3 cb-col-order-3 cb-col-order-3-2">
                                 <div class="cb-manage-order-info">
                                    <h5><span class="material-icons-outlined">sticky_note_2</span>Order# CBW994</h5>
                                    <p>02 Nov, 02:35 PM </p>
                                 </div>
                              </div>
                              <div class="col-md-3 cb-col-order-3 cb-col-order-3-2">
                                 <div class="cb-manage-order-info">
                                    <h5><span class="material-icons">person</span>Dheekshan Sai Sri</h5>
                                    <h6><span class="material-icons">credit_card</span>Rs 17600 </h6>
                                    <h6><span class="material-icons">shopping_cart</span>28 </h6>
                                 </div>
                              </div>
                              <div class="col-md-6 cb-col-order-6 cb-col-order-6-1">
                                 <div class="cb-manage-order-info">
                                    <div class="cb-manage-order-train">
                                       <div class="cb-manage-order-train-item completed">
                                          <span class="material-icons">check</span>
                                          <h4>Created</h4>
                                       </div>
                                       <div class="cb-manage-order-train-item completed">
                                          <span class="material-icons">check</span>
                                          <h4>Approved</h4>
                                       </div>
                                       <div class="cb-manage-order-train-item active">
                                          <span class="material-icons">check</span>
                                          <h4>Shipped</h4>
                                       </div>
                                       <div class="cb-manage-order-train-item">
                                          <span class="material-icons">check</span>
                                          <h4>Delivered</h4>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-2 cb-col-order-2 dp-none">
                                 <div class="cb-manage-order-process lw-stock">
                                   <h6>Low Stock</h6>
                                 </div>
                              </div>
                              <div class="col-lg-4 cb-col-order-4">
                                 <div class="cb-manage-order-btn">
                                    <a href="#" class="btn btn-more">More</a>
                                    <a href="#" class="btn btn-track">Track</a>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="col-lg-12 col-sm-12 col-12 cb-col-order-flex">
                           <div class="cb-manage-order-list">
                              <div class="col-md-3 cb-col-order-3 cb-col-order-3-2">
                                 <div class="cb-manage-order-info">
                                    <h5><span class="material-icons-outlined">sticky_note_2</span>Order# CBW994</h5>
                                    <p>02 Nov, 02:35 PM </p>
                                 </div>
                              </div>
                              <div class="col-md-3 cb-col-order-3 cb-col-order-3-2">
                                 <div class="cb-manage-order-info">
                                    <h5><span class="material-icons">person</span>Vanitha</h5>
                                    <h6><span class="material-icons">credit_card</span>Rs 12000 </h6>
                                    <h6><span class="material-icons">shopping_cart</span>8 </h6>
                                 </div>
                              </div>
                              <div class="col-md-6 cb-col-order-6 cb-col-order-6-1">
                                 <div class="cb-manage-order-info">
                                    <div class="cb-manage-order-train">
                                       <div class="cb-manage-order-train-item completed">
                                          <span class="material-icons">check</span>
                                          <h4>Created</h4>
                                       </div>
                                       <div class="cb-manage-order-train-item">
                                          <span class="material-icons">check</span>
                                          <h4>Approved</h4>
                                       </div>
                                       <div class="cb-manage-order-train-item">
                                          <span class="material-icons">check</span>
                                          <h4>Shipped</h4>
                                       </div>
                                       <div class="cb-manage-order-train-item">
                                          <span class="material-icons">check</span>
                                          <h4>Delivered</h4>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-2 cb-col-order-2 dp-none">
                                 <div class="cb-manage-order-process lw-rd">
                                   <h6>Price Update</h6>
                                 </div>
                              </div>
                              <div class="col-lg-4 cb-col-order-4">
                                 <div class="cb-manage-order-btn">
                                    <a href="#" class="btn btn-more">More</a>
                                    <a href="#" class="btn btn-track">Track</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12 cb-col-order-flex">
                           <div class="cb-manage-order-list">
                              <div class="col-md-3 cb-col-order-3 cb-col-order-3-2">
                                 <div class="cb-manage-order-info">
                                    <h5><span class="material-icons-outlined">sticky_note_2</span>Order# CBW994</h5>
                                    <p>02 Nov, 02:35 PM </p>
                                 </div>
                              </div>
                              <div class="col-md-3 cb-col-order-3 cb-col-order-3-2">
                                 <div class="cb-manage-order-info">
                                    <h5><span class="material-icons">person</span>Sri Vaishnavi</h5>
                                    <h6><span class="material-icons">credit_card</span>Rs 14682 </h6>
                                    <h6><span class="material-icons">shopping_cart</span>14 </h6>
                                 </div>
                              </div>
                              <div class="col-md-6 cb-col-order-6 cb-col-order-6-1">
                                 <div class="cb-manage-order-info">
                                    <div class="cb-manage-order-train">
                                       <div class="cb-manage-order-train-item completed">
                                          <span class="material-icons">check</span>
                                          <h4>Created</h4>
                                       </div>
                                       <div class="cb-manage-order-train-item completed">
                                          <span class="material-icons">check</span>
                                          <h4>Approved</h4>
                                       </div>
                                       <div class="cb-manage-order-train-item completed">
                                          <span class="material-icons">check</span>
                                          <h4>Shipped</h4>
                                       </div>
                                       <div class="cb-manage-order-train-item">
                                          <span class="material-icons">check</span>
                                          <h4>Delivered</h4>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-2 cb-col-order-2 dp-none">
                                 <div class="cb-manage-order-process">
                                   <h6>Out of Stock</h6>
                                 </div>
                              </div>
                              <div class="col-lg-4 cb-col-order-4">
                                 <div class="cb-manage-order-btn">
                                    <a href="#" class="btn btn-more">More</a>
                                    <a href="#" class="btn btn-track">Track</a>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="col-lg-12 col-sm-12 col-12 cb-col-order-flex ">
                           <div class="cb-manage-order-list">
                              <div class="col-md-3 cb-col-order-3 cb-col-order-3-2">
                                 <div class="cb-manage-order-info">
                                    <h5><span class="material-icons-outlined">sticky_note_2</span>Order# CBW994</h5>
                                    <p>02 Nov, 02:35 PM </p>
                                 </div>
                              </div>
                              <div class="col-md-3 cb-col-order-3 cb-col-order-3-2">
                                 <div class="cb-manage-order-info">
                                    <h5><span class="material-icons">person</span>Dheekshan Sai Sri</h5>
                                    <h6><span class="material-icons">credit_card</span>Rs 17600 </h6>
                                    <h6><span class="material-icons">shopping_cart</span>28 </h6>
                                 </div>
                              </div>
                              <div class="col-md-6 cb-col-order-6 cb-col-order-6-1">
                                 <div class="cb-manage-order-info">
                                    <div class="cb-manage-order-train">
                                       <div class="cb-manage-order-train-item completed">
                                          <span class="material-icons">check</span>
                                          <h4>Created</h4>
                                       </div>
                                       <div class="cb-manage-order-train-item completed">
                                          <span class="material-icons">check</span>
                                          <h4>Approved</h4>
                                       </div>
                                       <div class="cb-manage-order-train-item active">
                                          <span class="material-icons">check</span>
                                          <h4>Shipped</h4>
                                       </div>
                                       <div class="cb-manage-order-train-item">
                                          <span class="material-icons">check</span>
                                          <h4>Delivered</h4>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-2 cb-col-order-2 dp-none">
                                 <div class="cb-manage-order-process  lw-stock">
                                    <h6>Low Stock</h6>
                                 </div>
                              </div>
                              <div class="col-lg-4 cb-col-order-4">
                                 <div class="cb-manage-order-btn">
                                    <a href="#" class="btn btn-more">More</a>
                                    <a href="#" class="btn btn-track">Track</a>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="col-lg-12 col-sm-12 col-12 cb-col-order-flex">
                           <div class="cb-manage-order-list cb-manage-order-list-last-child">
                              <div class="col-md-3 cb-col-order-3 cb-col-order-3-2">
                                 <div class="cb-manage-order-info">
                                    <h5><span class="material-icons-outlined">sticky_note_2</span>Order# CBW994</h5>
                                    <p>02 Nov, 02:35 PM </p>
                                 </div>
                              </div>
                              <div class="col-md-3 cb-col-order-3 cb-col-order-3-2">
                                 <div class="cb-manage-order-info">
                                    <h5><span class="material-icons">person</span>Vanitha</h5>
                                    <h6><span class="material-icons">credit_card</span>Rs 12000 </h6>
                                    <h6><span class="material-icons">shopping_cart</span>8 </h6>
                                 </div>
                              </div>
                              <div class="col-md-6 cb-col-order-6 cb-col-order-6-1">
                                 <div class="cb-manage-order-info">
                                    <div class="cb-manage-order-train">
                                       <div class="cb-manage-order-train-item completed">
                                          <span class="material-icons">check</span>
                                          <h4>Created</h4>
                                       </div>
                                       <div class="cb-manage-order-train-item">
                                          <span class="material-icons">check</span>
                                          <h4>Approved</h4>
                                       </div>
                                       <div class="cb-manage-order-train-item">
                                          <span class="material-icons">check</span>
                                          <h4>Shipped</h4>
                                       </div>
                                       <div class="cb-manage-order-train-item">
                                          <span class="material-icons">check</span>
                                          <h4>Delivered</h4>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-2 cb-col-order-2 dp-none">
                                 <div class="cb-manage-order-process">
                                   <h6>Out of Stock</h6>
                                 </div>
                              </div>
                              <div class="col-lg-4 cb-col-order-4">
                                 <div class="cb-manage-order-btn">
                                    <a href="#" class="btn btn-more">More</a>
                                    <a href="#" class="btn btn-track">Track</a>
                                 </div>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div> 
               <div class="col-md-12 text-center">
                  <ul class="pagination">
                     <li><a href="#"><span class="material-icons-outlined">arrow_back</span></a></li>
                     <li class="active"><a href="#">1</a></li>
                     <li><a href="#">2</a></li>
                     <li><a href="#">3</a></li>
                     <li><a href="#">4</a></li>
                     <li><a href="#">5</a></li>
                     <li><a href="#"><span class="material-icons-outlined">arrow_forward</span></a></li>
                  </ul>
               </div>        
            </div>

      </div>
   </div>

 </>
  ); 
} 
export default Cancelled;