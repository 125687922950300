import React, { Component } from "react";
import Slider from "react-slick";

export default class CenterMode extends Component {
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      centerPadding: "0px",
      slidesToShow:1,
      slidesToScroll: 1,
      dots: true,
      autoplay: true,
      speed: 500,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <img src="../assets/images/features_social_recommendations.jpg" alt="Images" />
          </div>
          <div>
            <img src="../assets/images/features_catalog_update.jpg" alt="Images" />
          </div>
          <div>
            <img src="../assets/images/features_B2C_B2B.jpg" alt="Images" />
          </div>
          <div>
            <img src="../assets/images/features_announce_deals.jpg" alt="Images" />
          </div>
          <div>
            <img src="../assets/images/features_customer_reviews.jpg" alt="Images" />
          </div>
          <div>
            <img src="../assets/images/features_loyalty_points.jpg" alt="Images" />
          </div>
         
        </Slider>
      </div>
    );
  }
}