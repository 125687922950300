import React from 'react';
function ForgetPasswordReset() {
return( 
<>
<div class="account-content">
   <div class="login-wrapper">
      <div class="login-left-sidebar">
         <div class="login-sidebar-logo">
            <img src="./assets/images/logo.png" alt="img"/>        
            <h3>Welcome to CostBo</h3>
            <h4>Your trusted Social Shopping platform</h4>
         </div>
         <div class="login-sidebar-tab">
            <a href='/SignIn'>
               <div class="login-sidebar-tab-list">
                  <div class="tab-icon">
                     <div class="tab-icon-count"><span class="material-icons-outlined">person</span></div>
                  </div>
                  <div class="tab-icon-count-spacer"></div>
                  <div class="login-tab-description">
                     <h3>Login</h3>
                     <p>Login with your Costbo account</p>
                  </div>
               </div>
            </a>
            <a href='/SignUp'>
               <div class="login-sidebar-tab-list ">
                  <div class="tab-icon">
                    <div class="tab-icon-count"><span class="material-icons-outlined">person_add</span></div>
                  </div>
                  <div class="tab-icon-count-spacer"></div>
                  <div class="login-tab-description">
                     <h3>Create an Account</h3>
                     <p>Sign up with your mobile number</p>
                  </div>
               </div>
            </a>
            <a href='/ForgetPassword'>
               <div class="login-sidebar-tab-list active">
                  <div class="tab-icon">
                     <div class="tab-icon-count"><span class="material-icons-outlined">key</span></div>
                  </div>
                  <div class="tab-icon-count-spacer"></div>
                  <div class="login-tab-description">
                     <h3>Forgot your password</h3>
                     <p>We’ll help you reset your password.</p>
                  </div>
               </div>
            </a>
            {/* 
            <div class="login-bg"><img src="./assets/images/login-bg.png" /></div>
            */}
         </div>
      </div>
      <div class="login-content">
         <div class="login-userset">
            <div class="login-userheading theme-color">
               <h3>Forgot your password</h3>
               <h4>Password reset key has been sent to <a href="#">srivaishnavi@gamil.com</a></h4>
            </div>
            <div class="form-login">
               <label>Reset Key From Your Email</label>
               <div class="form-addons">
                  <input type="text" placeholder="Enter Reset Key From Your Email"/>
                  <span class="material-icons-outlined toggle-password">key</span>
               </div>
            </div>
            <div class="form-login">
               <label>New Password</label>
               <div class="pass-group">
                  <input type="password" class="pass-input" placeholder="Enter New Password"/>
                  <span class="material-icons-outlined toggle-password">password</span>
               </div>
            </div>
            <div class="form-remember-password">
               <div class="signinform">             
                  <ul class="signin_pass_hind">
                     <li><p>One Upper case character</p></li>
                     <li><p>One Special character</p></li>
                  </ul>
               </div>
               <div class="form-login">
                  <ul class="signin_pass_hind">
                     <li><p>One Upper case character</p></li>
                     <li><p>One Special character</p></li>
                  </ul>
               </div>
            </div>
            <div class="login-by-continuing">By continuing, you agree to CostBo's <a href="#" class="hover-a">Terms of Use</a> & <a href="#" class="hover-a">Privacy Policy</a></div>
            <div class="form-remember-password form-btn-mb">
               <div class="signinform">
                  <h4>Return to  <a href="#" class="hover-a">Log In</a>
                  </h4>
               </div>
               <div class="form-login">
                  <a class="btn btn-login" href="/SignIn">Reset & Login</a>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</>
);
}
export default ForgetPasswordReset;