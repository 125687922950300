import React from 'react';
function WeChargeLess () {
   return( 

   <>

      <div class="we-charge-less cb_pd_20" id="pricing">
        <div class="container">
            <div class="sec-title text-center mb-40 mt-20 wow bounceIn animated" data-wow-duration="1s" data-wow-delay="0.2s">                        
                <span>Plan & Pricing</span>
                <h4>Integrated end to end E-commerce in your existing website OR <br/>Setup a new webstore, In just few hours</h4>                           
            </div>
            <div class="row wow bounceIn animated">
                <div class="col-lg-6 col-md-6">
                    <div class="card-box">                                
                        <div class="price_header_content">
                            <div class="price_plan_title">
                                <h4>Basic Plan</h4>
                                {/* <span>Micro Website</span> */}
                            </div>
                            <div class="price_value_year">
                                <h6>Rs 2999 <sub>(Yearly) </sub></h6>
                                <h5>Rs 999 <sub>(First Year Only) </sub></h5>
                            </div>
                        </div>
                        <div class="plan_description">
                            <h6>Micro Website </h6>
                            <div class="price_plan_lt">
                                <ul class="plan_list">
                                    <li><p>Advanced Unlimited Digital Catalogs</p></li>
                                    <li><p>Logistics Door to Door (25g to 1000 Kgs)</p></li>
                                    <li><p>Payment Gateway</p></li>
                                    <li><p>Loyalty Point System</p></li>
                                    <li><p>User Review</p></li>
                                    <li><p>Promo Tolls (Coupons & Discounts)</p></li>                                    
                                    <li><p>Social Trends to Attract Customers</p></li>                                    
                                   
                                </ul>
                                <ul class="plan_list">
                                    <li><p>Affilaites* - Future</p></li>
                                    <li><p>CRM to track & Retarget customers</p></li>
                                    <li><p>Adv. AI based Recommendation Engine</p></li>
                                    <li><p>Direct Cloud Based Chat</p></li>
                                    <li><p>Whatsapp Campaigns</p></li>
                                    <li><p>Android / IOS Ordering</p></li>
                                </ul> 
                            </div>                       
                            
                        </div>
                        {/* <div class="plan_fees_chagers">
                            <div class="plan_fees_pd">
                                <h6>3 % on Transaction commissions</h6>
                            </div>
                        </div> */}
                        <div class="plan_get_start_btn">
                            <a href="#">Get Started</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="card-box pr-mt-20">    
                        <span class="price_most_popular">Most Popular</span>                            
                        <div class="price_header_content">
                            <div class="price_plan_title">
                                <h4>Add Ons</h4>
                                {/* <span>Full Fledged Website</span> */}
                            </div>
                            {/* <div class="price_value_year">
                                <h6>Rs 7999 <sub>(Yearly) </sub></h6>
                                <h5>Rs 2999 <sub>(First Year Only) </sub></h5>
                            </div> */}
                        </div>
                        {/* <div class="plan_description">
                            <h6>Full Fledged Website </h6>
                            <div class="price_plan_lt">
                                <ul class="plan_list plan_list_1">
                                    <li><p>Basic Plan +</p></li>
                                </ul>
                                <ul class="plan_list">
                                    <li><p>Website Monitoring</p></li>
                                    <li><p>Serach Engine Optimization ( SEO)</p></li>
                                    <li><p>Assisted Store Set Up</p></li>
                                    <li><p>Digital Catalog Assistance</p></li>
                                    <li><p>Subscription Model (Optional)</p></li>
                                </ul>
                            </div>   
                        </div> */}
                        {/* <div class="plan_other_head">
                        <h6>Add Ons to standard Plan*</h6>
                        </div> */}
                        <div class="plan_video_price">
                            <img src="../assets/images/special-offer.png" class="special-offer" alt="special-offer" />
                            <img src="../assets/images/ssl.png" class="img-responsive" alt="20 X More Engagement to inspire shopping" />
                            <div class="plan_video_price_value">
                                <h6>Hosting Services</h6>
                                <span>Secured Connect (SSL) <strong> Rs 5000 / Year</strong></span>
                            </div>
                        </div>
                        <div class="plan_video_price">
                            <img src="../assets/images/special-offer.png" class="special-offer" alt="special-offer" />
                            <img src="../assets/images/sales.png" class="img-responsive" alt="20 X More Engagement to inspire shopping" />
                            <div class="plan_video_price_value">
                                <h6>Credit line for B2B Sales Up to 25 Lakhs</h6>
                                <span><strong>One time fee - Rs 29,999</strong> (1 + Year GST filing data needed)</span>
                            </div>
                        </div>
                        <div class="plan_video_price">
                            <img src="../assets/images/special-offer.png" class="special-offer" alt="special-offer" />
                            <img src="../assets/images/price-video.png" class="img-responsive" alt="20 X More Engagement to inspire shopping" />
                            <div class="plan_video_price_value">
                                <h6>20 X More Engagement to inspire shopping</h6>
                                <span>Affiliate program integration One time fee  <strong>Rs 9,999</strong></span>
                            </div>
                        </div>
                        <div class="plan_get_start_btn">
                            <a href="#">Get Started</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="plan_fees_comment-chagers">
                        <h6>3% on all transactions</h6>
                        <h5>2% on transactions above Rs 25,000/-</h5>
                    </div>
                </div>
                
            </div>
                    
            <div class="row wow bounceIn animated">
                <div class="col-lg-4 col-md-6">
                    <div class="cb-plan-pricing-card-detail">                                
                        <div class="cb-plan-pricing-card-content">
                            <img src="../assets/images/price-payment-gateway.png" alt="price-payment-gateway" />     
                            <h6>Payment Gateway Charges</h6>                      
                            <p>1.85% ( Credit Cards,Net Banking & Debit cards) <br/> 
                            0% on Payment gateway charges ( UPI and Gpay) <br/>
                            2% Merchant cards & Other special cards - Actuals
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="cb-plan-pricing-card-detail">                                
                        <div class="cb-plan-pricing-card-content">
                            <img src="../assets/images/price-shipment-charges.png" alt="price-payment-gateway" />     
                            <h6>Shipment charges: (All India)* - <span>Tentative Prices</span></h6>                      
                            <p>Starting at Rs 29 / 500 Grams <br/> 2-5 Kgs - Rs 120, 5-10 Kgs - Rs 145</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="cb-plan-pricing-card-detail">                                
                        <div class="cb-plan-pricing-card-content">
                            <img src="../assets/images/price-b2b-shipment-charges.png" alt="price-payment-gateway" />     
                            <h6>B2B Shipment charges: (All India)*</h6>                      
                            <p>(15 kgs - 2000 Kgs) - Starting at Rs 9/ Kg</p>
                        </div>
                    </div>
                </div>
            </div>

           

        </div>
      </div>
      
   </>
);
}

export default WeChargeLess;