import React from 'react'; 
import Header from '../Header';
import SideBar from '../../../components/SideBar';

function ProductDraft() 
{ 
return(
 <>
   <Header />     
   <SideBar />

   <div class="page-wrapper">
      <div class="content">        

            <div class="row">
               
               <div class="col-lg-5 col-sm-6 col-12">
                  <div class="cb-business-header">
                     <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/logo.jpeg" alt='Logo'/>
                     <h6>Plant Science</h6>
                  </div>
               </div> 
               <div class="col-lg-7 col-sm-6 col-12">
                  <div class="dash-widget">
                     <div class="dash-widget-content">
                        <div class="dash-widgetimg">
                           <img src="./assets/images/dash1.svg" alt="img" />
                        </div>
                        <div class="dash-widgetcontent">
                           <h5>145</h5>
                           <h6>Total Product</h6>
                        </div>
                     </div>
                     <div class="dash-widget-content">
                        <div class="dash-widgetimg">
                           <img src="./assets/images/dash2.svg" alt="img" />
                        </div>
                        <div class="dash-widgetcontent">
                           <h5>Rs 254385</h5>
                           <h6>Total Sales</h6>
                        </div>
                     </div>
                     <div class="dash-widget-content wdth-100">
                        <div class="dash-widgetimg dash-widgetimg1">
                           <img src="./assets/images/store.svg" alt="img" />
                        </div>
                        <div class="dash-widgetcontent">
                           <h5>Ecommerce enabled</h5>
                           <div class="toggle-btn toggle-btn1">
                              <input type="checkbox" id="toggle-btn-5"/>
                              <label for="toggle-btn-5"></label>
                           </div>
                        </div>
                     </div>
                     
                  </div>
               </div>
            </div>
            <div class="row">               
               <div class="col-lg-12 col-sm-12 col-12">
                  <div class="card-p-10">
                     <div class="row product-header">                   
                        <div class="col-md-12">     
                        <div class="card-header content-header">
                           <div>                    
                              <h4>Product Catalog</h4>
                           </div>
                           <div>
                              <a href="/AddProduct" class="btn btn-add-business">
                              <span class="material-icons-outlined">add</span> <div class="text-mb">Add Product</div></a>
                           </div>
                        </div>  
                        <div class="cb-inventory-tab-head pd-mt-10">
                           <ul class="cb-inventory-nav-tabs cb-inventory-nav-tabs-business cb-inventory-nav-tabs-product">
                              <li><a href="/ProductActive">Active (11)</a></li>
                              <li><a href="/ProductPendingApproval">Pending Approval (4)</a></li>
                              <li><a class="active" href="/ProductDraft">Draft (3)</a></li>
                              <li><a href="/ProductPaused">Paused (6)</a></li>
                              <li><a href="/ProductRejected">Rejected (5)</a></li>
                           </ul>       
                           
                        </div>
                        </div>

                           <div class="col-md-5 cb-inventory-tab-title">         
                              <h5>Draft (3)</h5>
                              <i class="fas fa-search mb-search-icon"></i>
                           </div>
                           <div class="col-md-7 cb-inventory-tab-title"> 
                              <div class="form-group form-group-inventory">
                                 <label>Product Category</label>
                                 <select class="select">
                                    <option>Clothing & Accessories</option>
                                    <option>Home, Kitchen & Garden</option>
                                    <option>Laundry Supplies</option>
                                    <option>Air Fresheners</option>
                                 </select>
                              </div>
                           
                              <div class="form-group form-group-inventory">
                                 <label>Product Sub Category</label>
                                 <select class="select">
                                    <option>All</option>
                                    <option>Shoe Care</option>
                                    <option>Leather Shoes</option>
                                    <option>Sports Shoes</option>
                                    <option>Laundry Detergent</option>
                                 </select>
                              </div>    
                              <div class="form-group form-group-inventory cb-mb-none">
                                 <input type="text" placeholder="Search Product" class="search-mt-20" id="inputFname"/>
                                 <i class="fas fa-search inventory-search-icon"></i>
                              </div>
                           </div>                  
                        
                     </div>
                     <ul class="home_product">
                        <li>
                           <a href='/ProductDetail'>
                              <div class="product-media">
                                 <div class="product-label"><label class="label-text">12%</label></div>
                                 <div class="product-label-copy">
                                    <a href='#' class="label-text-copy" title="Copy"><span class="material-icons-outlined">content_copy</span></a>
                                 </div>
                                 <a class="product-image" >
                                 <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/product/59ea536ecb6841858e0783c4afd20aa3/Product0-386980.jpg" alt="Product Name" />
                                 </a>
                              </div>
                              <div class="product-content">
                                 <h6 class="product-name"><a>Back 2 Forest – Earthy Root 90ml</a></h6>
                                 <h6 class="product-price"><del>₹ 1800</del><span>₹ 1620</span></h6>      
                                 <div class="button-option">
                                    <a href="/EditProduct" class="btn-brand"><span class="material-icons-round">edit</span> Edit</a>
                                    <a href="#" class="btn-delete"><span class="material-icons-round">delete</span>Delete</a>
                                 </div>
                              </div>
                           </a>
                        </li>
                        <li>
                           <a href='/ProductDetail'>
                              <div class="product-media">
                                 <div class="product-label"><label class="label-text">30%</label></div>
                                 <div class="product-label-copy">
                                    <a href='#' class="label-text-copy" title="Copy"><span class="material-icons-outlined">content_copy</span></a>
                                 </div>
                                 <a class="product-image" >
                                 <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/product/4caf789b4cf34f98a4f2dd8b719063da/Product1-799635.jpeg" alt="Product Name" />
                                 </a>
                              </div>
                              <div class="product-content">
                                 <h6 class="product-name"><a>Infection care - 60 Capsules - 57g</a></h6>
                                 <h6 class="product-price"><del>₹ 360</del><span>₹ 252</span></h6>      
                                 <div class="button-option">
                                    <a href="/EditProduct" class="btn-brand"><span class="material-icons-round">edit</span> Edit</a>
                                    <a href="#" class="btn-delete"><span class="material-icons-round">delete</span>Delete</a>
                                 </div>
                              </div>
                           </a>
                        </li>
                        <li>
                           <a href='/ProductDetail'>
                              <div class="product-media">
                                 <div class="product-label"><label class="label-text">10%</label></div>
                                 <div class="product-label-copy">
                                    <a href='#' class="label-text-copy" title="Copy"><span class="material-icons-outlined">content_copy</span></a>
                                 </div>
                                 <a class="product-image" >
                                 <img src="https://storage.googleapis.com/bo3151920215/business/af9555a7720d4f07a1a428003832bd28/product/9bb685b2a4af48669e429273007adbbb/Product1-175553.jpeg" alt="Product Name" />
                                 </a>
                              </div>
                              <div class="product-content">
                                 <h6 class="product-name"><a>Natural Protein Shampoo - 200 ml</a></h6>
                                 <h6 class="product-price"><del>₹ 300</del><span>₹ 270</span></h6>      
                                 <div class="button-option">
                                    <a href="/EditProduct" class="btn-brand"><span class="material-icons-round">edit</span> Edit</a>
                                    <a href="#" class="btn-delete"><span class="material-icons-round">delete</span>Delete</a>
                                 </div>
                              </div>
                           </a>
                        </li>
                        
                     </ul>
                  </div>   

                  {/* <div class="product-list-mb">
                     <div class="col-lg-5 wd_20 ">
                        <figure class="itemside me-lg-5">
                           <div class="aside">                           
                              <img src="https://storage.googleapis.com/bo3151920215/business/a2908c3dd2d74dcd95463059a7f094f6/product/d45e8458c6e642e9b96fc9f5e9d403d8/product1-tS8LT2.jpeg" alt="Image"/>
                           </div>
                           <figcaption class="info cart-item">
                              <h4>Paste Shoe Polish 15GMS Black</h4>
                           </figcaption>
                        </figure>
                     </div>
                     <div class="col-lg-3 col-sm-4 col-6 wd-21">
                        <div class="product-price-mb"><del>₹340.00</del><ins>₹240.00</ins><label>20% OFF</label></div>
                     </div>
                     <div class="col-lg-1 col-sm-1 wd-25 cb-mb-icon">
                        <a href="#" class="product-edit"><span class="material-icons">edit</span></a>
                        <a href="#" class="product-copy"><span class="material-icons">content_copy</span></a>
                        <a href="#" class="product-delete"><span class="material-icons">delete</span></a>
                     </div>
                  </div>
                  <div class="product-list-mb">
                     <div class="col-lg-5 wd_20 ">
                        <figure class="itemside me-lg-5">
                           <div class="aside">                           
                           <img src="https://storage.googleapis.com/bo3151920215/business/a2908c3dd2d74dcd95463059a7f094f6/product/18594d51012e4644b562982043ff73d6/product1-UHNgQo.jpeg" alt="Product Name" />
                              </div>
                           <figcaption class="info cart-item">
                              <h4>Paste Shoe Polish 40 GMS Brown</h4>
                           </figcaption>
                        </figure>
                     </div>
                     <div class="col-lg-3 col-sm-4 col-6 wd-21">
                        <div class="product-price-mb"><del>₹340.00</del><ins>₹240.00</ins><label>20% OFF</label></div>
                     </div>
                     <div class="col-lg-1 col-sm-1 wd-25 cb-mb-icon">
                        <a href="#" class="product-edit"><span class="material-icons">edit</span></a>
                        <a href="#" class="product-copy"><span class="material-icons">content_copy</span></a>
                        <a href="#" class="product-delete"><span class="material-icons">delete</span></a>
                     </div>
                  </div>
                  <div class="product-list-mb">
                     <div class="col-lg-5 wd_20 ">
                        <figure class="itemside me-lg-5">
                           <div class="aside">                           
                           <img src="https://storage.googleapis.com/bo3151920215/business/a2908c3dd2d74dcd95463059a7f094f6/product/e7cb4f9cc93c4d96967163743f088999/Product2-720822.png" alt="Product Name"/>

                           </div>
                           <figcaption class="info cart-item">
                              <h4>Liquid Shoe Polish 75ML Black</h4>
                           </figcaption>
                        </figure>
                     </div>
                     <div class="col-lg-3 col-sm-4 col-6 wd-21">
                        <div class="product-price-mb"><del>₹340.00</del><ins>₹240.00</ins><label>20% OFF</label></div>
                     </div>
                     <div class="col-lg-1 col-sm-1 wd-25 cb-mb-icon">
                        <a href="#" class="product-edit"><span class="material-icons">edit</span></a>
                        <a href="#" class="product-copy"><span class="material-icons">content_copy</span></a>
                        <a href="#" class="product-delete"><span class="material-icons">delete</span></a>
                     </div>
                  </div> */}
            </div>
         </div>
      </div>
   </div>

 </>
  ); 
} 
export default ProductDraft;