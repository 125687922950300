import React from 'react'; 
import Header from './Header';
import SideBar from '../../components/SideBar'; 
function BusinessTiming() 
{ 
return(
 <>
   <Header />     
   <SideBar />

   <div class="page-wrapper">
   <div class="content">
      <div class="row">
         <div class="col-lg-12 col-sm-12 col-12">
            <div class="card card-main-profile-process cb-none">
               <div class="card-main-profile-process-detail">
                  <div class="main-profile-process-detail">
                     <img src="./assets/images/dashboard/profile-process.svg" alt="img"/>
                     <div class="main-profile-process-detail-title">
                        <h6>Complete Your Profile</h6>
                        <p>Profile needs to be at least 80% completed.</p>
                     </div>
                  </div>
                  <div class="main-profile-process-bar">
                     <div class="circle-wrap">
                        <div class="circle">
                           <div class="mask full">
                              <div class="fill"></div>
                           </div>
                           <div class="mask half">
                              <div class="fill"></div>
                           </div>
                           <div class="inside-circle">
                              <h6>70% </h6>
                              <span>Completed</span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="card">
               <div class="page-title-process">
                  <div class="card-header cb-display-flex">
                     <img src="./assets/images/dashboard/store-info.svg" alt="img"/>
                     <h4>Sai Sri Vaishnavi Pvt Limited</h4>
                  </div>
                  <div class="profile-progress">
                     <div class="profile-progress-data">
                        <h6>Store Detail Completed :<span> 65% </span></h6>
                        <div class="profile-progress-bar">
                           <div class="profile-progress-bar-process"></div>
                        </div>
                     </div>
                     <a href='#'>View More</a>
                  </div>
               </div>
               <div class="card-body">
                  <div class="row">
                     <div class="col-lg-12 col-sm-12 col-12">
                        <ul class="nav nav-tabs cb-nav-tabs">
                           <li><a class="active1" href="/StoreInfo"><span class="material-icons-outlined">storefront</span>Store Detail</a></li>
                           <li><a class="active1" href="/PickupAddress"><span class="material-icons-outlined">place</span>Pickup Address</a></li>
                           <li><a class="active" href="/BusinessTiming"><span class="material-icons-outlined">pending_actions</span>Business Timings</a></li>
                           <li><a href="/DeliveryMethods"><span class="material-icons-outlined">local_shipping</span>Delivery Methods</a></li>
                           <li><a href="/DiscountDetails"><span class="material-icons-outlined">discount</span>Discount Details</a></li>
                           <li><a href="/BankDetail"><span class="material-icons-outlined">account_balance</span>Bank Detail</a></li>
                           <li><a href="/OtherDetail"><span class="material-icons-outlined">description</span>Other Detail</a></li> 
                        </ul>
                     </div>
                     <div class="col-lg-12 col-sm-12 col-12 store-tab-title">
                        <h5>BusinessTiming</h5>
                     </div>
                     <div class="col-sm-12">
                           <div class="business_hours_head">
                                 <div class="row ps-10">
                                 <div class="col-sm-4">
                                    <input id="days" type="radio" name="user" value="days"/>
                                    <label for="days"> </label><span>Open 24 Hrs</span>
                                    </div>
                                    <div class="col-sm-4">
                                    <input id="days2" type="radio" name="user" value="days2"/>

                                    <label for="days2"></label><span>All Days</span>
                                    </div>
                                    <div class="col-sm-4">
                                    <input id="days1" type="radio" name="user" value="days1"/>
                                    <label for="days1"></label><span>Select specific dayss</span>
                                    </div>
                                 </div>                       
                        </div>
                     </div>
                     <div class="col-sm-8">
                     <div class="select_days">
                        <h5>Select Specific Days</h5>
                        <div class="business_hours">
                           <h6>Sunday</h6>
                           <div class="toggle-btn">
                              <input type="checkbox" id="toggle-btn-3"/>
                              <label for="toggle-btn-3"></label>
                              <span>Closed</span>
                           </div>
                           <div class="business_hours_lable">  
                              <input type="time" name="from"/> 
                           </div>
                           <div class="business_hours_lable"> <label>To</label> 
                              <input  type="time" name="to"/> 
                           </div>
                        </div>
                        <div class="business_hours">
                           <h6>Monday</h6>
                           <div class="toggle-btn">
                              <input type="checkbox" id="toggle-btn-4"/>
                              <label for="toggle-btn-4"></label>
                              <span>Closed</span>
                           </div>
                           <div class="business_hours_lable">  
                              <input type="time" name="from"/> 
                           </div>
                           <div class="business_hours_lable"> <label>To</label> 
                              <input  type="time" name="to"/> 
                           </div>
                        </div>
                        <div class="business_hours">
                           <h6>Tuesday</h6>
                           <div class="toggle-btn">
                              <input type="checkbox" id="toggle-btn-5"/>
                              <label for="toggle-btn-5"></label>
                              <span>Closed</span>
                           </div>
                           <div class="business_hours_lable">  
                              <input type="time" name="from"/> 
                           </div>
                           <div class="business_hours_lable"> <label>To</label> 
                              <input  type="time" name="to"/> 
                           </div>
                        </div>
                        <div class="business_hours">
                           <h6>Wednesday</h6>
                           <div class="toggle-btn">
                              <input type="checkbox" id="toggle-btn-6"/>
                              <label for="toggle-btn-6"></label>
                              <span>Closed</span>
                           </div>
                           <div class="business_hours_lable">  
                              <input type="time" name="from"/> 
                           </div>
                           <div class="business_hours_lable"> <label>To</label> 
                              <input  type="time" name="to"/> 
                           </div>
                        </div>
                        <div class="business_hours">
                           <h6>Thursday</h6>
                           <div class="toggle-btn">
                              <input type="checkbox" id="toggle-btn-7"/>
                              <label for="toggle-btn-7"></label>
                              <span>Closed</span>
                           </div>
                           <div class="business_hours_lable">  
                              <input type="time" name="from"/> 
                           </div>
                           <div class="business_hours_lable"> <label>To</label> 
                              <input  type="time" name="to"/> 
                           </div>
                        </div>
                        <div class="business_hours">
                           <h6>Friday</h6>
                           <div class="toggle-btn">
                              <input type="checkbox" id="toggle-btn-8"/>
                              <label for="toggle-btn-8"></label>
                              <span>Closed</span>
                           </div>
                           <div class="business_hours_lable">  
                              <input type="time" name="from"/> 
                           </div>
                           <div class="business_hours_lable"> <label>To</label> 
                              <input  type="time" name="to"/> 
                           </div>
                        </div>
                        <div class="business_hours">
                           <h6>Saturday</h6>
                           <div class="toggle-btn">
                              <input type="checkbox" id="toggle-btn-9"/>
                              <label for="toggle-btn-9"></label>
                              <span>Closed</span>
                           </div>
                           <div class="business_hours_lable">  
                              <input type="time" name="from"/> 
                           </div>
                           <div class="business_hours_lable"> <label>To</label> 
                              <input  type="time" name="to"/> 
                           </div>
                        </div>
                     </div>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-lg-4 col-sm-4 col-5">
                        <a href="/PickupAddress" class="btn btn-back"><span class="material-icons-outlined">keyboard_double_arrow_left</span> Previous</a>
                     </div>
                     <div class="col-lg-8 col-sm-12 col-7 cb-txet-right">
                        <a href="#" class="btn btn-submit">Save</a>
                        <a href="/DeliveryMethods" class="btn btn-next cb-ml-4">Next</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      
      </div>
   </div>
</div>

 </>
  ); 
} 
export default BusinessTiming;