import React from 'react'; 
import Header from '../Header';
import SideBar from '../../../components/SideBar'; 

function StoreInfoEdit() 
{ 
return(
 <>
   <Header />     
   <SideBar />

   <div class="page-wrapper">
      <div class="content">
         <div class="row">
            <div class="col-lg-9 col-sm-12 col-12">
               <div class="card card-main-profile-process cb-none">
                  <div class="card-main-profile-process-detail">
                     <div class="main-profile-process-detail">
                        <img src="./assets/images/dashboard/profile-process.svg" alt="img"/>
                        <div class="main-profile-process-detail-title">
                        <h6>Complete Your Profile</h6>
                        <p>Profile needs to be at least 80% completed.</p>
                        </div>
                     </div>
                     <div class="main-profile-process-bar">
                     <div class="circle-wrap">
                        <div class="circle">
                           <div class="mask full">
                              <div class="fill"></div>
                           </div>
                           <div class="mask half">
                              <div class="fill"></div>
                           </div>
                           <div class="inside-circle"> <h6>70% </h6><span>Completed</span></div>
                        </div>
                        </div>
                     </div>
                  </div>
               </div>
            
               <div class="card">
                  <div class="page-title-process">
                     <div class="card-header cb-display-flex">                     
                        <img src="./assets/images/dashboard/store-info.svg" alt="img"/>
                        <h4>Maxwax Multiproducts LLP</h4>
                     </div>
                     <div class="profile-progress">
                        <div class="profile-progress-data">
                           <h6>Store Detail Completed :<span> 65% </span></h6>
                           <div class="profile-progress-bar">
                              <div class="profile-progress-bar-process"></div>
                           </div>
                        </div>
                        <a href='#'>View More</a>
                     </div>
                  </div>
                  <div class="card-body">
                     <div class="row">
                        <div class="col-lg-3 col-sm-6 col-12">
                           <div class="form-group dp-block">
                              <label>Business Logo</label>
                              <div class="image-upload-img image-upload-img-logo">
                                 <span class="material-icons-outlined">clear</span>
                                 <img src="https://storage.googleapis.com/bo3151920215/business/a2908c3dd2d74dcd95463059a7f094f6/logo.jpeg" alt="img"/>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-9 col-sm-6 col-12">
                           <div class="form-group dp-block">
                              <label>Upload Banner Images</label>
                              <div class="image-upload-img">
                                 <span class="material-icons-outlined">clear</span>
                                 <img src="https://storage.googleapis.com/bo3151920215/business/a2908c3dd2d74dcd95463059a7f094f6/bpic1-795354.jpeg" alt="img"/>
                              </div>
                              <div class="image-upload-img ps-l-40">
                                 <span class="material-icons-outlined">clear</span>
                                 <img src="https://storage.googleapis.com/bo3151920215/business/a2908c3dd2d74dcd95463059a7f094f6/bpic3-uiCkRQ.jpeg" alt="img"/>
                              </div>
                              <div class="image-upload-img ps-l-50">
                                 <span class="material-icons-outlined">clear</span>
                                 <img src="https://storage.googleapis.com/bo3151920215/business/a2908c3dd2d74dcd95463059a7f094f6/bpic4-rBMXqV.jpeg" alt="img"/>
                              </div>
                              <div class="image-upload-img ps-l-60">
                                 <span class="material-icons-outlined">clear</span>
                                 <img src="https://storage.googleapis.com/bo3151920215/business/a2908c3dd2d74dcd95463059a7f094f6/bpic2-RLgmul.jpeg" alt="img"/>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-12">
                           <div class="form-group">
                           <label>Business Description</label>
                           <textarea class="form-control" value='Shoe care, Air freshners, Liquid Detergents'></textarea>
                           </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-12">
                           <div class="form-group">
                              <label>Business Category</label>
                              <input type="text" value='Manufacturers/Industries'/>
                           </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-12">
                           <div class="form-group">
                              <label>FSSAI Number</label>
                              <input type="text" value='U9603R1ZM'/>
                           </div>
                        </div>
                        <div class="col-lg-4 col-sm-11 col-12 cb-col-mb-9">
                           <div class="form-group">
                              <label>Main Products</label>
                              <input type="text" value='Cotton Saree'/>
                           </div>
                        </div>
                        <div class="col-lg-4 col-sm-11 col-12 cb-col-mb-9 mt-29">
                           <div class="form-group">
                              <input type="text" value='Semi Soft Silk'/>
                           </div>
                        </div>
                        
                        <div class="col-lg-1 col-sm-1 col-12 cb-col-mb-3">
                           <div class="page-btn"><a href="#" class="btn btn-added">
                              <img src="../assets/images/dashboard/plus.svg" alt="img"/></a>
                           </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-12">
                           <div class="form-group">
                              <label>Shipping Area</label>                              
                              <input type="text" value='All India Shipment'/>
                           </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-12">
                           <div class="form-group">
                              <label>B2B Shipments <p>(Product weight should range from 10kg to 2000kg)</p></label>
                              <div>
                              <input id="Yes" type="radio" name="user" value="Yes"/>
                              <label for="Yes" class="mr-2"><span></span>Yes</label>

                              <input id="No" type="radio" name="user" value="No"/>
                              <label for="No"><span></span>No</label>
                              </div> 
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-lg-4 col-sm-4 col-4">
                           {/* <a href="#" class="btn btn-back">Go Back</a> */}
                        </div>
                        <div class="col-lg-8 col-sm-12 col-12 cb-txet-right">
                        <a href="#" class="btn btn-submit">Save</a>
                        <a href="/StorePreview" class="btn btn-next cb-ml-4">Preview</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-lg-3 col-sm-12 col-12 ">
               <div class="card">
                  <div class="card-header card-header-second">
                     <h5>GST Detail</h5>
                     <a href='/GSTDetail' class="cb-pos-rgt">Edit</a>
                     <span class="material-icons-outlined">expand_more</span>
                  </div>
                  <div class="card-detail cb-onboarding-form  cb-onboard-mb">
                     <div class="form-group">                        
                        <label for="input" class="input-label">GST Number</label>
                        <input type="text" name="" value="AZHG62721HGJN"/>
                     </div>
                     <div class="form-group">                        
                        <label for="input" class="input-label">Registered Business Name</label>
                        <input type="text" name="" value="Dheeksha Sai Sri"/>
                     </div>
                     <div class="form-group">                        
                        <label for="input" class="input-label">Registered Business Address</label>
                        <input type="text" name="" value="5th Main Rd, HSR Layout, Bengaluru, Karnataka"/>
                     </div>
                     <div class="form-group">                        
                        <label for="input" class="input-label">PAN Number</label>
                        <input type="text" name="" value="RG6512862HKS"/>
                     </div>
                     <div class="form-group">                        
                        <label for="input" class="input-label">Brand Name</label>
                        <input type="text" name="" value="Sarees"/>
                     </div>
                     <div class="form-group">                        
                        <label for="input" class="input-label">Business Type</label>
                        <input type="text" name="" value="Manufacturers/Industries"/>
                     </div>
                     <div class="form-group">                        
                        <label for="input" class="input-label">Registered Business Name</label>
                        <input type="text" name="" value="Dheeksha Sai Sri"/>
                     </div>
                  </div>
               </div>
               <div class="card">
                  <div class="card-header card-header-second">
                     <h5>Address Detail</h5> 
                     <a href='AddressDetail' class="cb-pos-rgt-1">Edit</a>
                     <span class="material-icons-outlined">expand_less</span>
                  </div>
                  <div class="card-detail cb-onboarding-form">
                     <div class="form-group">                        
                        <label for="input" class="input-label">Pincode</label>
                        <input type="text" name="" value="560102"/>
                     </div>
                     <div class="form-group">                        
                        <label for="input" class="input-label">State</label>
                        <input type="text" name="" value="Karnatakai"/>
                     </div>
                     <div class="form-group">                        
                        <label for="input" class="input-label">City</label>
                        <input type="text" name="" value="Bengaluru"/>
                     </div>
                     <div class="form-group">                        
                        <label for="input" class="input-label">PAN Number</label>
                        <input type="text" name="" value="RG6512862HKS"/>
                     </div>
                     <div class="form-group">                        
                        <label for="input" class="input-label">Address</label>
                        <input type="text" name="" value="HSR Layout"/>
                     </div>
                     <div class="form-group">                        
                        <label for="input" class="input-label">Business Type</label>
                        <input type="text" name="" value="Manufacturers/Industries"/>
                     </div>
                     <div class="form-group">                        
                        <label for="input" class="input-label">Landmark</label>
                        <input type="text" name="" value="76 Workspace, 5th Main Rd, Sector 6"/>
                     </div>
                  </div>
               </div>
            
            </div>
         </div>
      </div>
   </div>

 </>
  ); 
} 
export default StoreInfoEdit;