import React, { Component, useEffect } from 'react';
function GuestUser () {
return( 
<>

<section class="section-login">
    <div class="login-user">
      <div class="login-modal-dialog">
        <a class="cb-login-close">
          <span class="material-icons-round">close</span>
        </a>
        <div class="cb-login-register">
          <div class="cb-login-register-header">
            <img src="./assets/images/logo.png" alt="Login" />
            <h3>Welcome to CostBo</h3>
            <p>Future ready, All in One D2C E-Commerce Infra</p>
          </div>
          <div class="cb-login-register-body">
            <h5>Enter the OTP sent to your mobile number</h5>
            <h6>+91 9025042838 <a href=''>Change</a></h6>
            <form>
              <div class="form-group form-group-otp">
                <input type="text" class="form-control form-control-otp" maxlength="1" autocomplete="off" />
                <input type="text" class="form-control form-control-otp" maxlength="1" autocomplete="off" />
                <input type="text" class="form-control form-control-otp" maxlength="1" autocomplete="off" />
                <input type="text" class="form-control form-control-otp" maxlength="1" autocomplete="off" />
                <input type="text" class="form-control form-control-otp" maxlength="1" autocomplete="off" />
                <input type="text" class="form-control form-control-otp" maxlength="1" autocomplete="off" />
              </div>
              <p>By continuing, you agree to our <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a></p>
              <div class="cb-login-btn">
                <a href="/RegisterDetail" class="login-btn">Create an account</a>
              </div>
              {/* <div class="login_use_guest"><span>OR</span></div>
              <div class="cb-new-account"><a href='/RegisterDetail'>Create an account</a></div> */}
            </form>
          </div>
          <div class="cb-login-register-footer">
            <h5>Trusted by 1000s of Brands</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
  
</>
);
}
export default GuestUser;