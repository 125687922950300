import React from 'react';

function DashboardSideBar () { 
return (
   <>
        <div class="sidebar sidebar-mb" id="sidebar">
            <div class="sidebar-inner slimscroll">
                <div id="sidebar-menu" class="sidebar-menu">
                    <a href="/" class="logo"><img src="./assets/images/logo.png" alt=""/></a>
                    <ul>
                        <li class="active">
                            <a href="/BusinessListing"><img src="./assets/images/dashboard/store.png" alt="img"/><span>Business Listing</span> </a>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <a href="/Dashboard"><img src="./assets/images/dashboard/dashboard.png" alt="img"/><span>Dashboard</span> </a>
                        </li>
                        <li>
                            <a href="/ProductActive"><img src="./assets/images/dashboard/catalog.png" alt="img"/><span>Catalog</span> </a>
                        </li>
                        <li class="menu-item has-submenu">
                            <a href="/Inventory"><img src="./assets/images/dashboard/inventory.png" alt="img"/><span>Inventory</span> 
                            <span class="material-icons-outlined sidebar-menu-arrow">expand_more</span> </a>
                            <div class="submenu">
                                <a href="/Inventory" class="active">Active Inventory</a>
                                <a href="/PausedStock">Paused Inventory</a>
                            </div>
                        </li>
                        <li>
                            <a href="/Pending"><img src="./assets/images/dashboard/order.png" alt="img"/><span>Orders</span> </a>
                        </li>
                    </ul>
                    <ul>  
                        <li class="menu-item has-submenu">
                            <a href="/CouponsDiscount"><img src="./assets/images/dashboard/discount.png" alt="img"/><span>Coupons & Discount</span> 
                            <span class="material-icons-outlined sidebar-menu-arrow">expand_more</span> </a>
                            <div class="submenu">
                                <a href="/CouponsDiscount" class="active">Coupons & Cashback</a>
                                <a href="/CouponsDiscountEmpty" class="active">Coupons Empty</a>
                                <a href="/PreferredDiscounts">Preferred Discounts</a>
                                <a href="/PreferredDiscountsEmpty">Preferred Empty</a>
                            </div>
                        </li>
                        <li>
                            <a href="/Paycart"><img src="./assets/images/dashboard/inventory.png" alt="img"/><span>Paycart Orders</span> </a>
                        </li>  
                        <li>
                            <a href="#"><img src="./assets/images/dashboard/affiliate.png" alt="img"/><span>Affiliate Marketing</span> </a>
                        </li>
                        <li>
                            <a href="#"><img src="./assets/images/dashboard/fintech.png" alt="img"/><span>Fintech Program</span> </a>
                        </li>
                        <li>
                            <a href="#"><img src="./assets/images/dashboard/crm.png" alt="img"/><span>CRM/ Marketing</span> </a>
                        </li>
                        <li>
                            <a href="#"><img src="./assets/images/dashboard/subscription.png" alt="img"/><span>Subscription</span> </a>
                        </li>
                        <li>
                            <a href="#"><img src="./assets/images/dashboard/report.png" alt="img"/><span>Report</span> </a>
                        </li>
                    </ul>
                    <ul>      
                        <li>
                            <a href="#"><img src="./assets/images/dashboard/settings.png" alt="img"/><span>Setting</span> </a>
                        </li>
                        <li>
                            <a href="#"><img src="./assets/images/dashboard/support.png" alt="img"/><span>Support</span> </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
	
        <a href="#" class="header-sidebar-menu-overlay"></a>

  </>
);
}

export default DashboardSideBar;