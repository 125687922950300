import React, { Component, useEffect } from 'react';
function ExistingUser () {
return( 
<>

<section class="section-login">
    <div class="login-user">
      <div class="login-modal-dialog">
        <a class="cb-login-close">
          <span class="material-icons-round">close</span>
        </a>
        <div class="cb-login-register">
          <div class="cb-login-register-header">
            <img src="./assets/images/logo.png" alt="Login" />
            <h3>Welcome to CostBo</h3>
            <p>Future ready, All in One D2C E-Commerce Infra</p>
          </div>
          <div class="cb-login-register-body">
            <h5>Your Mobile Number</h5>
            <h6>+91 9025042838 <a href='/Login'>Change</a></h6>
            <form>
              <div class="form-group">
                <input type="password" class="form-control" placeholder="Enter Password" />
                <span class="material-icons-round">visibility</span>
              </div>
              <div class="cb-remeber-row">
                <div class="form-check">
                  <input id="remember-me" name="remember" class="form-check-input" type="checkbox" />
                  <label class="rm-label" for="remember-me">Remember Me</label>
                </div>
                <div class="cb-login-fp">
                  <a href="/ResetPassword">Forgot Password ?</a>
                </div>
              </div>
              <p>By continuing, you agree to our <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a></p>
              <div class="cb-login-btn">
                <a href="#" class="login-btn">Login</a>
              </div>
            </form>
          </div>
          <div class="cb-login-register-footer">
            <h5>Trusted by 1000s of Brands</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
  
</>
);
}
export default ExistingUser;