
import React from 'react';
import CategorySlider from '../../components/CategorySlider';

function SellVarietyProducts () {    
   return( 
   <>
      <div class="cb-sell-products cb_pd_20">
        <div class="container">
            <div class="sec-title text-center mb-20 mt-20 wow bounceIn animated" data-wow-duration="1s" data-wow-delay="0.2s">   
               <span>Segments</span>                     
                <h4>Market segments we serve</h4>                           
            </div>
            <div class="wow bounceIn animated" data-wow-duration="1s" data-wow-delay="0.2s">
               <CategorySlider />
            </div>            
        </div>
      </div>
      
   </>
);
}

export default SellVarietyProducts;