import React from 'react'; 
import Header from '../Dashboard/Header';
import SideBarBusiness from '../../components/SideBarBusiness'; 

function RejectedEmpty() 
{ 
return(
 <>
   <Header />     
   <SideBarBusiness />

   <div class="page-wrapper">
      <div class="content">
         <div class="row">
            <div class="col-lg-12 col-sm-12 col-12">            
               <div class="card">
                  <div class="page-title-process">
                     <div class="card-header cb-display-flex">                     
                        <h4>Business Dashboard</h4>
                     </div>
                  </div>                           
                  <div class="card-body">
                     <div class="cb-inventory-tab-head">
                        <ul class="cb-inventory-nav-tabs cb-inventory-nav-tabs-business">
                           <li><a href="/ActiveEmpty">Active (7)</a></li>
                           <li><a href="/PendingEmpty">Pending Approval (4)</a></li>
                           <li><a href="/DraftEmpty">Draft (3)</a></li>
                           <li><a class="active" href="/RejectedEmpty">Rejected (0)</a></li>
                        </ul>
                     </div>
                     
                     <div class="cb-choose-business-empty">
                        <img src="../assets/images/business-empty.svg" alt="No business status"/>
                        <h6>No business in rejected status.</h6>
                     </div>                   
                  </div>
               </div>
            </div>
      
         </div>
      </div>
   </div>

 </>
  ); 
} 
export default RejectedEmpty;