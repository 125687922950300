import React from 'react'; 
import Header from './Header';
import SideBar from '../../components/SideBar'; 

function OtherDetail() 
{ 
return(
 <>
   <Header />     
   <SideBar />

   <div class="page-wrapper">
      <div class="content">
         <div class="row">
            <div class="col-lg-12 col-sm-12 col-12">
               {/* <div class="card card-main-profile-process cb-none">
                  <div class="card-main-profile-process-detail">
                     <div class="main-profile-process-detail">
                        <img src="./assets/images/dashboard/profile-process.svg" alt="img"/>
                        <div class="main-profile-process-detail-title">
                        <h6>Complete Your Profile</h6>
                        <p>Profile needs to be at least 80% completed.</p>
                        </div>
                     </div>
                     <div class="main-profile-process-bar">
                     <div class="circle-wrap">
                        <div class="circle">
                           <div class="mask full">
                              <div class="fill"></div>
                           </div>
                           <div class="mask half">
                              <div class="fill"></div>
                           </div>
                           <div class="inside-circle"> <h6>70% </h6><span>Completed</span></div>
                        </div>
                        </div>
                     </div>
                  </div>
               </div> */}
            
               <div class="card">
                  <div class="page-title-process">
                     <div class="card-header cb-display-flex">                     
                        <img src="./assets/images/dashboard/store-info.svg" alt="img"/>
                        <h4>Sai Sri Vaishnavi Pvt Limited</h4>
                     </div>
                     <div class="profile-progress">
                        <div class="profile-progress-data">
                           <h6>Store Detail Completed :<span> 65% </span></h6>
                           <div class="profile-progress-bar">
                              <div class="profile-progress-bar-process"></div>
                           </div>
                        </div>
                        <a href='#'>View More</a>
                     </div>
                  </div>           
                  <div class="card-body">
                     <div class="row">
                        <div class="col-lg-12 col-sm-12 col-12">
                           <ul class="nav nav-tabs cb-nav-tabs">
                              <li><a class="active1" href="/StoreInfo"><span class="material-icons-outlined">storefront</span>Store Detail</a></li>
                              <li><a class="active1" href="/PickupAddress"><span class="material-icons-outlined">place</span>Pickup Address</a></li>
                              <li><a class="active1" href="/BusinessTiming"><span class="material-icons-outlined">pending_actions</span>Business Timings</a></li>
                              <li><a class="active1" href="/DeliveryMethods"><span class="material-icons-outlined">local_shipping</span>Delivery Methods</a></li>
                              <li><a class="active1" href="/DiscountDetails"><span class="material-icons-outlined">discount</span>Discount Details</a></li>
                              <li><a class="active1" href="/BankDetail"><span class="material-icons-outlined">account_balance</span>Bank Detail</a></li>
                              <li><a class="active" href="/OtherDetail"><span class="material-icons-outlined">description</span>Other Detail</a></li>
                           </ul>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12 store-tab-title">
                           <h5>Other Detail</h5>
                        </div> 
                        <div class="col-lg-6 col-sm-6 col-12">
                           <div class="form-group">
                              <label>Preferred Payment Method</label>
                              <input type="text" placeholder='Enter Preferred Payment Method'/>
                           </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-12">
                           <div class="form-group">
                              <label>Minimum Order Amount</label>
                              <input type="text" placeholder='Enter Min Order Amount'/>
                           </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-12">
                           <div class="form-group">
                              <label>Return Policy</label>
                              <select class="select">
                                 <option>Select Return Policy</option>
                                 <option>Brand</option>
                                 <option>Retailers</option>
                                 <option>Wholesalers/Distributors</option>
                                 <option>Others</option>
                              </select>
                           </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-12">
                           <div class="form-group">
                              <label>Special Notes</label>
                              <input type="text" placeholder='Enter Special Notes'/>
                           </div>
                        </div>
                        
                     </div>
                     <div class="row">
                        <div class="col-lg-4 col-sm-4 col-5">
                           <a href="/BankDetail" class="btn btn-back"><span class="material-icons-outlined">keyboard_double_arrow_left</span> Previous</a>
                        </div>
                        <div class="col-lg-8 col-sm-12 col-7 cb-txet-right">
                           <a href="#" class="btn btn-submit">Save</a>
                           <a href="/StoreAllDataPreview" class="btn btn-next cb-ml-4">Preview</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </div>
   </div>

 </>
  ); 
} 
export default OtherDetail;