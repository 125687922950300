import React from 'react'; 
import Header from '../../Dashboard/Header';
import SideBar from '../../../components/SideBar'; 

function RejectedBusiness() 
{ 
return(
 <>
   <Header />     
   <SideBar />

   <div class="page-wrapper">
      <div class="content">
         <section className="banner-part testimonial-part">        
               <div class="row">
                  <div class="col-lg-9 col-sm-12 col-12">
                     <div class="card card-main-profile-process card-main-profile-process-bg card-main-profile-business-add-bg card-main-profile-business-add-bg-1">
                        <div class="card-main-profile-process-detail card-main-profile-business-add-detail">
                           <div class="main-profile-process-detail card-main-profile-process-detail-product">
                              <div class="main-profile-process-detail-title mt-bb-6 cl-ch">
                                 <h6>Rejected</h6>
                                 <p>This business is rejected by our agents. Please review the rejection reason. Update the required details and re-submit for approval. Once submitted our agents will review and approve the business within 48 hours.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>                  
                  <div class="col-lg-3 col-sm-6 col-12 bs-tc">
                     <a class="cb-dash-business-add" href="/StoreInfo"><span class="material-icons-outlined">add_circle</span>Add a new Business</a>
                  </div>
               </div>
               <div className="row">
                  <div className="col-lg-4">
                     <div className="banner_t_30 text-center">
                        <div className="navbar-content header_company">
                           <div class="banner_logo cd-business-logo_1">
                              <a href="#"><img src="https://storage.googleapis.com/bo3151920215/business/13cab95b30c44d6383c0fb08fedb8289/logo.jpeg" class="cd-business-edit-logo" alt="Verification Email"/></a>
                           </div>
                           <h1>Lucasta Online Sarees Shopping</h1>
                           <span> Traditional Sarees, Handloom Sarees, Tant Sarees, Silk Sarees and many more.</span>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-5 lt-1">
                     <div className="home-grid-slider">
                        <a href="#"><img src="./assets/images/Store_Detail.png" alt=""/></a>
                     </div>
                     
                  </div>
                  <div className="col-lg-3 lt-1">
                     <div class="banner-img">
                        <img src="./assets/images/Store_Detail1.png" alt="" />                              
                     </div>
                     <div class="banner-img banner-img-1">
                        <img src="./assets/images/Store_Detail2.png" alt="" />                              
                     </div>
                  </div>
               </div>
            </section>
            <section className="overviewcontent">
               <div class="row">
                  <div class="col-lg-8">
                     <div class="overview-content">
                        <div class="overview-Description mb-10">
                           <h5>Business Description</h5>
                           <p>We are south Indian group run this online store to motivate and help rural and urban area weavers by selling their domestic products, who makes Indian traditional sarees and sustainability products.
                              Now explore Indian Traditional Sarees, Handloom Sarees, Tant Sarees, Silk Sarees, Fancy Sarees and many more.nces; and developing high-technology maps the guides to materials that 
                              serve the mobility industry. 
                           </p>
                        </div>
                        <h5>Business & Product Type</h5>
                        <div class="overview-product mb-10 overview_card_product">                           
                           <h5>Business Category</h5>
                           <ul>
                              <li><span></span>Agriculture & Farming</li>
                              <li><span></span>Pets & Supplies</li>
                              <li><span></span>Clothing & Accessories</li>
                              <li><span></span>Crafts & Collectibles</li>
                              <li><span></span>Automobiles & Accessories</li>
                              <li><span></span>Beauty & Personal Care</li>
                              <li><span></span>Arts, Crafts & Collectibles</li>
                              <li><span></span>Computers & Networking</li>
                           </ul>
                           <h5>Main Products</h5>
                           <ul>
                              <li><span></span>Cotton Saree</li>
                              <li><span></span>Soft Silk Type Saree</li>
                              <li><span></span>Semi Soft Silk</li>
                              <li><span></span>Fancy Mercerized Kotta Fancy</li>
                              <li><span></span>Mesmerized Silk Cotton Saree</li>
                              <li><span></span>Kotta Kodinji Saree</li>
                           </ul>                           
                        </div>
                        <div class="overview-location ">
                           <h5>Other Details</h5>
                           <div class="row">
                              <div class="col-md-4">
                                 <div class="form-group">
                                    <label for="inputPhone">Shipping Area</label>
                                    <input type="text" Value="All India" class="form-control" id="inputPhone"/>
                                 </div>
                              </div>
                              <div class="col-md-4">
                                 <div class="form-group">
                                    <label for="inputPhone">B2B Shipments</label>
                                    <input type="text" Value="Yes" class="form-control" id="inputPhone"/>
                                 </div>
                              </div>
                              <div class="col-md-4">
                                 <div class="form-group">
                                    <label for="inputPhone">FSSAI Number</label>
                                    <input type="text" Value="U9603R1ZM" class="form-control" id="inputPhone"/>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     {/* <div class="row">
                        <div class="col-lg-12 col-sm-12 col-12 cb-txet-right">
                           <a href="/PickupAddress" class="btn btn-submit">Edit Business</a>
                        </div>
                     </div> */}
                  </div>
                  <div class="col-lg-4 p-0">
                     <div class="product-details">
                        <div class="col-sm-12 col-md-12 col-lg-12 cb-pr-0">
                           <div class="intro-part_1">
                              <div class="intro-wrap">
                                 <div class="intro-icon">
                                    <i class="fa fa-user" aria-hidden="true"></i>
                                 </div>
                                 <div class="intro-content1">
                                    <h5>Contact Detail</h5>
                                    <p>Sri Vaishnavi</p>
                                    <p>+91 8098081991</p>
                                    <p>lucastasarees@gmail.com</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 cb-pr-0">
                           <div class="intro-part_1">
                              <div class="intro-wrap">
                                 <div class="intro-icon">
                                    <i class="fas fa-map" aria-hidden="true"></i>
                                 </div>
                                 <div class="intro-content1">
                                    <h5>Address</h5>
                                    <p>No 56, 1st Cross Vinayaka Nagar, Rama temple, Bengaluru, Karnataka.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 cb-pr-0">
                           <div class="intro-part_1">
                              <div class="intro-wrap">
                                 <div class="intro-icon">
                                    <i class="fa fa-location-arrow" aria-hidden="true"></i>
                                 </div>
                                 <div class="intro-content1">
                                    <h5>Landmark</h5>
                                    <p>Sri Rama Temple (Nearest landmark)</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 cb-pr-0">
                           <div class="intro-part_1">
                              <div class="intro-wrap">
                                 <div class="intro-icon">
                                 <span class="material-icons-outlined">receipt_long</span>
                                 </div>
                                 <div class="intro-content1">
                                    <h5>GST Detail</h5>
                                    <div class="card-detail cb-onboarding-form cb-onboarding-form-pre">
                                       <div class="form-group">                        
                                          <label for="input" class="input-label">GST Number</label>
                                          <input type="text" name="" value="AZHG62721HGJN"/>
                                       </div>
                                       <div class="form-group">                        
                                          <label for="input" class="input-label">Registered Business Name</label>
                                          <input type="text" name="" value="Lucasta Online Sarees Shopping"/>
                                       </div>
                                       <div class="form-group">                        
                                          <label for="input" class="input-label">Registered Business Address</label>
                                          <input type="text" name="" value="No 56, 1st Cross Vinayaka Nagar, Rama temple, Bengaluru, Karnataka"/>
                                       </div>
                                       <div class="form-group">                        
                                          <label for="input" class="input-label">PAN Number</label>
                                          <input type="text" name="" value="RG6512862HKS"/>
                                       </div>
                                       <div class="form-group">                        
                                          <label for="input" class="input-label">Brand Name</label>
                                          <input type="text" name="" value="Sarees"/>
                                       </div>
                                       <div class="form-group">                        
                                          <label for="input" class="input-label">Business Type</label>
                                          <input type="text" name="" value="Manufacturers/Industries"/>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>                        
                     </div>
                  </div>
                  
               </div>
         </section>
      </div>
   </div>

 </>
  ); 
} 
export default RejectedBusiness;