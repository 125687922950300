import { Box } from "@mui/material";
import React, { Component } from "react";
import Slider from "react-slick";

export default class CenterMode extends Component {
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      centerPadding: "0px",
      slidesToShow:4,
      slidesToScroll: 1,
      dots: true,
      autoplay: true,
      speed: 500,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Box>
        <Slider {...settings}>
          <Box>
            <img src="https://storage.googleapis.com/bodefaults/businessweb/poweredby_itree.jpg" alt="Itree" />
            <div class="business-caption">
              <div class="business-caption-text">
                <h3>iTree</h3>
                <p>ELECTRONICS</p>
                </div>
                <a href="#" class="go-link-icon"></a>
              </div>
          </Box>
          <Box>
            <img src="https://storage.googleapis.com/bodefaults/businessweb/poweredby_safe_harvest.jpg"  alt="Safe Harvest" />
            <div class="business-caption">
              <div class="business-caption-text">
                <h3>Safe Harvest Private Limited</h3>
                <p>GROCERIES</p>
                </div>
                <a href="#" class="go-link-icon"></a>
              </div>
          </Box>
          <Box>
            <img src="https://storage.googleapis.com/bodefaults/businessweb/poweredby_cupid.jpg"  alt="Cupid" />
            <div class="business-caption">
              <div class="business-caption-text">
                <h3>Cupid Greens</h3>
                <p>FRUITS & VEGETABLES</p>
                </div>
                <a href="#" class="go-link-icon"></a>
              </div>
          </Box>
          <Box>
            <img src="https://storage.googleapis.com/bodefaults/businessweb/poweredby_pranik.jpg"  alt="Pranik" />
            <div class="business-caption">
              <div class="business-caption-text">
              <h3>Pranik Health Solutions Private Limited</h3>
                <p>HEALTH SUPPLEMENTS</p>
                </div>
                <a href="#" class="go-link-icon"></a>
              </div>
          </Box>
          <Box>
            <img src="https://storage.googleapis.com/bodefaults/businessweb/poweredby_farmley.jpg"  alt="Farmley" />
            <div class="business-caption">
              <div class="business-caption-text">
                <h3>Farmley</h3>
                <p>DRY FRUITS & NUTS</p>
                </div>
                <a href="#" class="go-link-icon"></a>
              </div>
          </Box>
         
        </Slider>
      </Box>
    );
  }
}