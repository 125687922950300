import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SellIcon from '@mui/icons-material/Sell';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import EmailIcon from '@mui/icons-material/Email';

function SideBarMobile () { 
return (
   <>
        <div class="header-sidebar-menu">
            <div class="header-sidebar-menu-inner">                        
                <div class="simplebar-content">
                    <ul class="side_links">							
                        <li><a href='#'>Hassle Free B2B Sales</a></li>
                        <li><a href='#'>Business Dashboard</a></li>
                        <li><a href='#'>Pricing</a></li>
                        <li><a href='#'>Get CostBo App</a></li>
                       
                    </ul>                              
                    
                </div>
			</div>
		</div>
	
        <a href="#" class="header-sidebar-menu-overlay"></a>

  </>
);
}

export default SideBarMobile;