import React from 'react'; 
import Header from './Header';
import SideBar from '../../components/SideBar'; 

function StorePreviewPending() 
{ 
return(
 <>
   <Header />     
   <SideBar />

   <div class="page-wrapper">
      <div class="content">
         <section className="banner-part testimonial-part">
               <div className="row">
                  <div className="col-md-12">
                     <div class="card-pending">
                        <h6>This business is Pending approval. Our CMS agents will review and approve the business within 24 hrs of submission.</h6>
                        <span>Please contact support@costbo.com for any queries</span>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-lg-4">
                     <div className="banner_t_30 text-center">
                        <div className="navbar-content header_company">
                           <div class="banner_logo cd-business-logo_1">
                              <a href="#"> <img src="https://storage.googleapis.com/bo3151920215/business/c147c9d959f74365bbce4d2633e99e97/logo.jpeg" alt="img"/>
                             </a>
                           </div>
                           <h1>Tavastha Ayurveda</h1>
                           <span>Wood Pressed Edible Oils, Ayurveda Hair Care, A2 Bilona Ghees, Wild Honey, Organic Sugar & Jaggery</span>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-5">
                     <div className="home-grid-slider">
                        <a href="#"><img src="https://storage.googleapis.com/bo3151920215/business/c147c9d959f74365bbce4d2633e99e97/bpic1-939555.jpeg" alt=""/></a>
                     </div>
                  </div>
                  <div className="col-lg-3 cb-none">
                     <div class="banner-img">
                        <img src="https://storage.googleapis.com/bo3151920215/business/c147c9d959f74365bbce4d2633e99e97/bpic3-433279.jpeg" alt="" />                              
                     </div>
                     <div class="banner-img banner-img-1">
                        <img src="https://storage.googleapis.com/bo3151920215/business/c147c9d959f74365bbce4d2633e99e97/bpic2-822201.jpeg" alt="" />                              
                     </div>
                  </div>
               </div>
            </section>
            <section className="overviewcontent">
               <div class="row">
                  <div class="col-lg-8">
                     <div class="overview-content">
                        <div class="overview-Description mb-10">
                           <h5>Business Description</h5>
                           <p>Wood Pressed Edible Oils, Ayurveda Hair Care, A2 Bilona Ghees, Wild Honey, Organic Sugar & Jaggery, Forest Grown Herbs & Flowers, Handmade Body</p>
                        </div>
                        <h5>Business Detail</h5>
                        <div class="overview_card_intro_box">                           
                           <div class="row intro-content-hd">
                              <div class="col-md-6 intro-content-hd-list">
                                 <p>GST Number</p>
                                 <h5>AZHG62721HGJN</h5>
                              </div> 
                              <div class="col-md-6 intro-content-hd-list">
                                 <p>PAN Number</p>
                                 <h5>RG6512862HKS</h5>
                              </div>
                              <div class="col-md-6 intro-content-hd-list">
                                 <p>Registered Business Name</p>
                                 <h5>Dheeksha Sai Sri</h5>
                              </div>    
                              <div class="col-md-6 intro-content-hd-list">
                                 <p>Business Type</p>
                                 <h5>Retailers</h5>
                              </div>                     
                           </div>                     
                        </div>
                        <h5>Category & Product Line</h5>
                        <div class="overview-product mb-20 overview_card_product">                           
                           <h5>Business Category</h5>
                           <ul>
                              <li><span></span>Agriculture & Farming</li>
                              <li><span></span>Pets & Supplies</li>
                              <li><span></span>Clothing & Accessories</li>
                              <li><span></span>Crafts & Collectibles</li>
                              <li><span></span>Automobiles & Accessories</li>
                           </ul>
                           <h5>Main Products</h5>
                           <ul>
                              <li><span></span>Cotton Saree</li>
                              <li><span></span>Soft Silk Type Saree</li>
                              <li><span></span>Semi Soft Silk</li>
                              <li><span></span>Fancy Mercerized Kotta Fancy</li>
                           </ul> 
                           <h5>FSSAI Number</h5>
                           <ul>
                              <li><span></span>RYT5615HJU</li>
                           </ul>                            
                        </div>
                     </div>
                     <div class="row cb-none">
                        <div class="col-lg-4 col-sm-4 col-5">
                           <a href="/BusinessListing" class="btn btn-back-ft"><span class="material-icons">skip_previous</span><div class="text-edit">Back to Business Listing</div></a>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4 p-0">
                        <div class="col-sm-12 col-md-12 col-lg-12 cb-pr-0">
                           <div class="intro-part_1">
                              <div class="intro-wrap">
                                 <div class="intro-icon">
                                    <i class="fa fa-user" aria-hidden="true"></i>
                                 </div>
                                 <div class="intro-content1">
                                    <h5>Contact Detail</h5>
                                    <p>Sri Vaishnavi</p>
                                    <p>+91 8098081991</p>
                                    <p>lucastasarees@gmail.com</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 cb-pr-0">
                           <div class="intro-part_1">
                              <div class="intro-wrap">
                                 <div class="intro-icon">
                                    <i class="fas fa-map" aria-hidden="true"></i>
                                 </div>
                                 <div class="intro-content1">
                                    <h5>Address</h5>
                                    <p>No 56, 1st Cross Vinayaka Nagar, Rama temple, Bengaluru, Karnataka.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 cb-pr-0">
                           <div class="intro-part_1">
                              <div class="intro-wrap">
                                 <div class="intro-icon">
                                    <i class="fa fa-location-arrow" aria-hidden="true"></i>
                                 </div>
                                 <div class="intro-content1">
                                    <h5>Landmark</h5>
                                    <p>Sri Rama Temple (Nearest landmark)</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="row cb-dt-none-1">
                           <div class="col-lg-4 col-sm-4 col-12">
                           <a href="/BusinessListing" class="btn btn-back-ft"><span class="material-icons">skip_previous</span>Back to Business Listing</a>
                              {/* <a href="/StoreInfo" class="btn btn-back"><span class="material-icons-outlined">keyboard_double_arrow_left</span>Edit Business Detail</a> */}
                           </div>
                           {/* <div class="col-lg-8 col-sm-12 col-7 cb-txet-right">
                              <a href="/BusinessListing" title='Once submitted, our CMS agents will review and approve your business within 24 hours' class="btn btn-submit btn-submit-1">submit for approval</a>
                           </div> */}
                        </div>
                  </div>
                  
               </div>
         </section>
      </div>
   </div>

 </>
  ); 
} 
export default StorePreviewPending;