
import React from 'react';

function OurVision() {    
   return( 
   <>
      
      <div  class="about-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="sec-title mb-20 mt-20 ab-1 text-center">
                        <span>Grow 10X</span>
                        <h5>Empower Your Brand with Next generation</h5>
                        <h4>Direct Commerce infra for wider market reach B2B & B2C sales, at best cost</h4>
                    </div>
                </div>
            </div>
            
              <div class="row s-ptb-20">
                <div class="col-md-12">
                  <div class="sales-tab-heading">
                    <div class="col-md-3 sales-tab-bdr-rh">
                        <img src="../assets/images/zero-maintance.svg" alt=""/>
                    </div>
                    <div class="col-md-8">
                        <span>B2B SALES</span>                 
                        <span>B2C SALES</span>  
                        <h6>Grow your B2B/B2C Business by 10X, Zero Maintenance online Tech Infra</h6>      
                    </div>
                  </div>
                </div>
              </div>
                <div class="row s-ptb-20">
                  <div class="col-lg-4">                        
                        <div class="vission-content">
                            <div class="vission-inner">
                                <h6>B2B/B2C focused Websites in days/Hrs</h6>
                                {/* <p>(Through dealers, retailers & Affiliates)</p> */}
                            </div> 
                            <div class="vission-icon">
                                <img src="../assets/images/v5.svg" alt=""/>
                            </div>                       
                        </div>                        
                    </div>
                    <div class="col-lg-4">                        
                        <div class="vission-content">
                            <div class="vission-inner">
                                <h6>Fintech integration for Dealer credits </h6>
                                {/* <p>(Through websites, Social & walk-ins)</p> */}
                            </div> 
                            <div class="vission-icon">
                                <img src="../assets/images/v1.svg" alt=""/>
                            </div>                       
                        </div>                        
                    </div>
                    <div class="col-lg-4">                        
                        <div class="vission-content">
                            <div class="vission-inner">
                                <h6> Affiliate / Influncers at your websites</h6>
                                {/* <p>(Through dealers, retailers & Affiliates)</p> */}
                            </div> 
                            <div class="vission-icon">
                                <img src="../assets/images/v6.svg" alt=""/>
                            </div>                       
                        </div>                        
                    </div> 
                </div>

                <div class="row">
                
                <div class="col-lg-7 services-info">
                <div class="row">
                        <div class="col-lg-6 col-md-6 single-item">
                            <div class="item">
                                <img src="../assets/images/home/1.svg" alt=""/>
                                <div>
                                    <h4>Hosting, SEO Friendly & Back office</h4>     
                                </div>                   
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 single-item">
                            <div class="item">
                                <img src="../assets/images/home/13.svg" alt=""/>
                                <div>
                                    <h4>PAN India Logistics & Multi warehouse</h4>          
                                </div>                           
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 single-item">
                            <div class="item">
                                <img src="../assets/images/home/20.svg" alt=""/>
                                <div>
                                    <h4>Preferred Pricing control at dealer level</h4>         
                                </div>                             
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 single-item">
                            <div class="item">
                                <img src="../assets/images/home/18.svg" alt=""/>
                                <div>
                                    <h4>Mobile optimized - Operate any where</h4>     
                                </div>                             
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 single-item">
                            <div class="item">
                                <img src="../assets/images/home/21.svg" alt=""/>
                                <div>
                                    <h4>99% Uptime + No Need for tech team</h4>          
                                </div>                         
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 single-item">
                            <div class="item">
                                <img src="../assets/images/home/19.svg" alt=""/>
                                <div>
                                    <h4>Loyalty wallet - repeat orders</h4>              
                                </div>                     
                            </div>
                        </div>
                    </div>
                    </div>
                
                <div class="col-lg-5 services-info">
                <img src="../assets/images/b2b-vs-b2c-sales.png" class="our-version-img" alt="Auto &amp; Spare"/>
                </div>
                </div> 


        </div>
    </div>
         
   </>
);
}

export default OurVision;