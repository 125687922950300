import { Box } from "@mui/system";
import React from "react";
import PoweredCostBoSlider from "../../components/PoweredCostBoSlider";
function PoweredByCostBo() {
  return (
    <Box>
      <div class="cb-poweredbycostbo cb_pd_20">
        <div class="container">
          <div class="sec-title text-center mb-20 mt-20 wow bounceIn animated" data-wow-duration="1s" data-wow-delay="0.2s">
            <span>Webstores</span>
            <h4>Webstores powered by CostBo</h4>
          </div>
          <div class="wow bounceIn animated" data-wow-duration="1s" data-wow-delay="0.2s">
            <PoweredCostBoSlider />
          </div>
        </div>
      </div>
    </Box>
  );
}

export default PoweredByCostBo;
