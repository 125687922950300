import React from 'react'; 
import Header from './Header';
import SideBar from '../../components/SideBar'; 

function StoreInfo() 
{ 
return(
 <>
   <Header />     
   <SideBar />

   <div class="page-wrapper">
      <div class="content">
         <div class="row">
            <div class="col-lg-6 col-sm-12 col-12 ">
               <div class="card">
                  <div class="card-header card-header-second">
                     <h5>Business Detail</h5>
                     <a href='/GSTDetail' class="cb-pos-rgt">Edit Detail</a>
                     {/* <span class="material-icons-outlined">expand_more</span> */}
                  </div>
                  <div class="card-detail cb-onboarding-form  cb-onboard-mb">
                     <div class="row">                        
                        <div class="col-md-6">                        
                           <div class="form-group">                        
                              <label for="input" class="input-label">GST Number<span>*</span></label>
                              <input type="text" name="" value="AZHG62721HGJN"/>
                           </div>
                        </div>
                        <div class="col-md-6"> 
                           <div class="form-group">                        
                              <label for="input" class="input-label">PAN Number<span>*</span></label>
                              <input type="text" name="" value="RG6512862HKS"/>
                           </div>
                        </div>
                     </div>
                     <div class="row">                        
                        <div class="col-md-8">   
                           <div class="form-group">                        
                              <label for="input" class="input-label">Registered Business Name<span>*</span></label>
                              <input type="text" name="" value="Dheeksha Sai Sri"/>
                           </div>    
                        </div>
                        <div class="col-md-4"> 
                           <div class="form-group">                        
                              <label for="input" class="input-label">Brand Name<span>*</span></label>
                              <input type="text" name="" value="Sarees"/>
                           </div>
                        </div>
                     </div>
                    
                     <div class="row">                        
                        <div class="col-md-12">  
                           <div class="form-group">                        
                              <label for="input" class="input-label">Business Type<span>*</span></label>
                              <input type="text" name="" value="Dealers, Manufacturers, Industries"/>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12 ">
               <div class="card">
                  <div class="card-header card-header-second">
                     <h5>Address Detail</h5>
                     <a href='/GSTDetail' class="cb-pos-rgt">Edit Detail</a>
                  </div>
                  <div class="card-detail cb-onboarding-form  cb-onboard-mb">
                     <div class="row">                        
                        <div class="col-md-4">                        
                           <div class="form-group">                        
                              <label for="input" class="input-label">Pincode<span>*</span></label>
                              <input type="text" name="" value="560102"/>
                           </div>
                        </div>
                        <div class="col-md-4"> 
                           <div class="form-group">                        
                              <label for="input" class="input-label">State<span>*</span></label>
                              <input type="text" name="" value="Karnataka"/>
                           </div>
                        </div>
                        <div class="col-md-4"> 
                           <div class="form-group">                        
                              <label for="input" class="input-label">City<span>*</span></label>
                              <input type="text" name="" value="Bangalore"/>
                           </div>
                        </div>
                     </div>
                     <div class="row">                        
                        <div class="col-md-12">   
                           <div class="form-group">                        
                              <label for="input" class="input-label">Address<span>*</span></label>
                              <input type="text" name="" value="Bhive Workspace, 148, 5th Main Rd, Sector 6, HSR Layout"/>
                           </div>    
                        </div>
                     </div>
                    
                     <div class="row">                        
                        <div class="col-md-12">  
                           <div class="form-group">                        
                              <label for="input" class="input-label">Landmark</label>
                              <input type="text" name="" value="7th Main Rd, HSR Layout"/>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         
            <div class="col-lg-12 col-sm-12 col-12">
               <div class="card">
                  <div class="page-title-process">
                     <div class="card-header cb-display-flex">                     
                        <img src="./assets/images/dashboard/store-info.svg" alt="img"/>
                        <h4>Plant Science</h4>
                     </div>
                     {/* <div class="profile-progress">
                        <div class="profile-progress-data">
                           <h6>Store Detail Completed :<span> 65% </span></h6>
                           <div class="profile-progress-bar">
                              <div class="profile-progress-bar-process"></div>
                           </div>
                        </div>
                        <a href='#'>View More</a>
                     </div> */}
                  </div>
                  <div class="card-body">
                     <div class="row">
                        <div class="col-lg-3 col-sm-6 col-12">
                           <div class="form-group">
                              <label>Business Logo</label>
                              <div class="image-upload">
                                 <input type="file" />
                                 <div class="image-uploads">
                                    <img src="./assets/images/dashboard/upload.svg" alt="img"/>
                                    <h4>Upload Picture</h4>
                                 </div>
                              </div>
                              
                              <p class="image-size-info">75 x 75 pixels (Square 1:1, size : 20 - 100 Kb)</p>
                           </div>
                        </div>
                        <div class="col-lg-9 col-sm-6 col-12">
                           <div class="form-group info">
                              <label>Banner Images <span>( Min 1 & Max 4 Images)</span></label>
                              <div class="image-upload-product">
                                 <div class="image-upload image-upload-1">                                    
                                    <span class="material-icons-outlined">remove_circle</span>
                                    <div class="image-upload-image">
                                       <img src="./assets/images/dashboard/upload-image.png" alt="img"/>
                                    </div>
                                 </div>
                                 <div class="image-upload image-upload-1">                                    
                                    <span class="material-icons-outlined">remove_circle</span>
                                    <div class="image-upload-image">
                                       <img src="./assets/images/dashboard/upload-image-1.png" alt="img"/>
                                    </div>
                                 </div>
                                 <div class="image-upload">
                                    <input type="file" />
                                    <div class="image-uploads">
                                       <img src="./assets/images/dashboard/upload.svg" alt="img"/>
                                       <h4>Upload Picture</h4>
                                       {/* <span>Can you upload 4 images at the maximum<br/>560 x 420 pixels (Square 4:3, size: 100 - 200 Kb)</span> */}
                                    </div>
                                 </div>
                                 <div class="image-upload">
                                    <input type="file" />
                                    <div class="image-uploads">
                                       <img src="./assets/images/dashboard/upload.svg" alt="img"/>
                                       <h4>Upload Picture</h4>
                                       {/* <span>Can you upload 4 images at the maximum<br/>560 x 420 pixels (Square 4:3, size: 100 - 200 Kb)</span> */}
                                    </div>
                                 </div>                                 
                              </div>
                              <p class="image-size-info">560 x 420 pixels (Square 4:3, size: 100 - 200 Kb)</p>
                           </div>
                        </div>
                        <div class="col-lg-12">
                           <div class="form-group">
                           <label>Business Description</label>
                           <textarea class="form-control" placeholder='Enter Your Business Description'></textarea>
                           </div>
                        </div>
                        <div class="col-lg-8 col-sm-6 col-12">
                           <div class="form-group">
                              <label>Business Category</label>
                              <select class="select">
                                 <option>Manufacturers/Industries</option>
                                 <option>Brand</option>
                                 <option>Retailers</option>
                                 <option>Wholesalers/Distributors</option>
                                 <option>Others</option>
                              </select>
                           </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                           <div class="form-group">
                              <label>FSSAI Number</label>
                              <input type="text" placeholder='Enter FSSAI Number'/>
                           </div>
                        </div>
                        <div class="col-lg-11 col-sm-11 col-12 cb-col-mb-9">
                           <div class="form-group">
                              <label>Main Products</label>
                              <input type="text" placeholder='Enter Main Products'/>
                           </div>
                        </div>
                        <div class="col-lg-1 col-sm-1 col-12 cb-col-mb-3">
                           <div class="page-btn"><a href="#" class="btn btn-added">
                              <img src="../assets/images/dashboard/plus.svg" alt="img"/></a>
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-lg-4 col-sm-4 col-4">
                           {/* <a href="#" class="btn btn-back">Go Back</a> */}
                        </div>
                        <div class="col-lg-8 col-sm-12 col-12 cb-txet-right">
                           <a href="/BusinessListing" title='If you do not have the above details handy, please save and exit. You may login back and complete the registration. Details entered will be stored as Draft' class="btn btn-submit">Save & Exit</a>
                           <a href="/StorePreview" class="btn btn-next cb-ml-4">Preview & Submit</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            
         </div>
      </div>
   </div>

 </>
  ); 
} 
export default StoreInfo;