import React from 'react';
function Loader() {
return( 
<>


<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div id="mask">
                    <div id="wave1"></div>
                    <div id="laoding_text">
                        <span>Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


</>
);
}
export default Loader;