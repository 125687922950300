
import React from 'react';

function Banner4 () {    
   return( 
   <>
      
    <div class="demo-banner-bg1">      
      <div class="container">
        <div class="row banner-wrap">
          <div class="col-md-8 banner-content wow fadeInLeft animated">            
            <h2>Don’t sweat about Cash Flow</h2> 
            <h1><strong>Automated Fintech</strong> <br/>for your buyers</h1>
            <div class="br-offer-cnt">
              <div class="br-offer-cnt-inner">
                <img src="../../assets/images/home/15.svg" class="img-responsive" alt="ONDC Logo" />
                <div class="banner-inner-cnt">
                  <span>Sales</span>
                  <p>Increased B2B + B2C Sales</p>
                </div>
              </div>
              <div class="br-offer-cnt-inner">
                <img src="../../assets/images/home/16.svg" class="img-responsive" alt="ONDC Logo" />
                <div class="banner-inner-cnt">
                  <span>Online Infra</span>
                  <p>Faster to market, Integrated Fintech</p>
                </div>
              </div>
            </div>                 
          </div>
          <div class="banner-shape">
              <img src="../../assets/images/8.png" alt="image"/>
          </div>
      </div>
     
    </div>
    <div class="banner-slider-bottom">   
      <div class="container">
        <div class="col-md-12">
            <div class="banner-tab-info">
              <ul>
                  <li><a href='/'>Managed Online  <br/>Infrastructure </a></li>
                  <li><a href='/HomeSlideOne'>Direct Reach to Customers & Dealers</a></li>
                  <li><a href='/HomeSlideTwo'>Working Capital <br/>for your Dealers</a></li>                  
                  <li><a href='/HomeSlideThree'>Integrated <br/>Affiliates Network</a></li>                  
                  <li><a href='/HomeSlideFour' class="active">Integrated Pan-India <br/>Logistics & Tracking</a></li>                  
                  <li><a href='/HomeSlideFive'>ONDC Ready Technology</a></li>            
              </ul>
            </div>
        </div>
      </div>
    </div>
  </div>
      
   </>
);
}

export default Banner4;