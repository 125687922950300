import { Box } from '@mui/system';
import React from 'react';
function IframeDemo() {
   return( 

    <section class="cb-download-app cb-download-app_bg" id="cb-download-app">
        <div class="container">
            <div class="row">
           
                <div class="col-lg-6 cb-p-r-30 align-items-center">
                    <div class="sec-title sec-title-fees wow bounceIn animated" data-wow-duration="1s" data-wow-delay="0.2s">                        

                        <h4>Iframe Demo</h4>                         
                    </div>
                    <div class="iframe-code">
                            <a class="cb-shop-now-btn" href="#shop-now">Shop Now</a>
                            <div id="shop-now" class="costbo-modal-popup-container">
                            <div class="costbo-iframe">
                            <iframe id="costbo-modalpopup-iframe" allow="clipboard-read; clipboard-write" src="https://shop.costbo.com/atrimed/catalog"></iframe>
                                </div>
                            </div>
                        <a href="https://fintech-demo.costbo.com/" target="_blank" class="sell_btn_1">Affiliate</a>
                        <a href="https://shop.costbo.com/vishfulcreations" target="_blank" class="sell_btn_2">Shop CostBo</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

);
}

export default IframeDemo;